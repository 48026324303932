import reduce from 'async/reduce'
import md5 from 'md5'

import {
  IColumnMeta,
  ICsvData,
  IDefaultColumnMeta,
  IDictionary,
  IKeyNameMap
} from '@interfaces/general.interface'
import {
  IFieldInternal,
  IValidatorInternal
} from '@interfaces/internal.settings.interface'
import { IFieldOption } from '@interfaces/settings.interface'
import {
  getDefaultMatches,
  hasUnmatchedSelectOption,
  processData,
  storageAvailable
} from '@lib/functions'
import Validate from '@lib/validate'

export default class Matcher {
  public setState: (param: object) => void
  public configHash: string
  public keyNames: IKeyNameMap
  public defaultValidators: IDictionary<IValidatorInternal[]>
  public defaultDescriptions: IDictionary
  public defaultColumns: IDefaultColumnMeta[]
  public defaultTypes: IDictionary<IFieldInternal['type']>
  public defaultOptions: IDictionary<IFieldOption[]>
  public csvData: ICsvData
  public header: boolean
  public customColumns: string[]
  public columnOptions: string[]
  public validationLog: object
  public allowCustom: boolean
  public _columnMeta: IColumnMeta
  public columnsInvalid: boolean
  public parentHasValidator: boolean
  public handshake: Promise<any>

  constructor(props) {
    this.setState = props.setState
    this.configHash = md5(JSON.stringify(props.settings.fields))
    this.keyNames = props.keyNames
    this.defaultValidators = props.defaultValidators
    this.defaultDescriptions = props.defaultDescriptions
    this.defaultColumns = props.defaultColumns
    this.defaultTypes = props.defaultTypes
    this.defaultOptions = props.defaultOptions
    this.csvData = props.csvData
    this.header = props.header
    this.handshake = props.handshake
    this.parentHasValidator = props.parentHasValidator
    this.customColumns = []
    this.columnOptions = []
    this.validationLog = {}
    this.allowCustom = props.settings.allowCustom

    for (let i = 0; i < props.settings.fields.length; i++) {
      this.columnOptions.push(props.settings.fields[i].key)
    }

    this.setColumnMeta(props.columnMeta)
  }

  public async setColumnMeta(columnMeta) {
    this._columnMeta = columnMeta.reduce((acc, col, i) => {
      const md5key = this.configHash + '-' + md5(col.oldName)
      if (storageAvailable('localStorage') && window.localStorage[md5key]) {
        col = this.rehydrateColumn(col, md5key)
      }
      acc.push(col)
      return acc
    }, [])

    await this.updateStates()

    this.setState({
      matcher: this,
      columnMeta: this.columnMeta,
      customColumns: this.customColumns,
      columnOptions: this.columnOptions,
      keyNames: this.keyNames,
      matcherIsLoading: false
    })
  }

  get columnMeta() {
    return this._columnMeta
  }

  public rehydrateColumn(col, md5key) {
    const rememberedData = JSON.parse(window.localStorage[md5key]) || {}
    const memName = rememberedData.newName || ''
    const memMatchState = rememberedData.matchState || ''
    const optionsMap = rememberedData.optionsMap || {}
    if (
      this.columnOptions.indexOf(memName) === -1 &&
      this.allowCustom &&
      memName !== 'none'
    ) {
      this.addCustomColumn(memName)
      col.newName = memName
      col.matchState = memMatchState
      col.validators = []
      col.description = ''
    } else if (
      this.columnOptions.indexOf(memName) === -1 &&
      !this.allowCustom &&
      memName !== 'none'
    ) {
      return col
    } else {
      col.newName = memName
      col.matchState = memMatchState
      col.validators = this.defaultValidators[col.newName] || []
      col.description = this.defaultDescriptions[col.newName] || ''
      col.type = this.defaultTypes[col.newName]
      col.options = this.defaultOptions[col.newName]
      col.optionsMap = optionsMap

      if (col.matchState === 'confirmed' && col.type === 'select') {
        if (
          hasUnmatchedSelectOption(this.csvData, col.oldName, col.optionsMap)
        ) {
          col.matchState = 'matched'
        }
      }
    }
    return col
  }

  public addCustomColumn(name) {
    this.columnOptions.push(name)
    this.customColumns.push(name)
    this.keyNames[name] = name
  }

  public async confirmMatch(index) {
    this._columnMeta[index].matchState = 'confirmed'
    await this.updateStates()
    this.setState({ ...this._columnMeta })
  }

  public async undoConfirm(index) {
    this._columnMeta[index].matchState = 'matched'
    await this.updateStates()
    this.setState({ ...this._columnMeta })
  }

  public async ignoreColumn(index) {
    this._columnMeta[index].matchState = 'ignored'
    this.updateStates()
    this.setState({ ...this._columnMeta })
  }

  public async undoMatch(index) {
    this._columnMeta[index].matchState = 'unmatched'
    this._columnMeta[index].newName = 'none'
    this._columnMeta[index].validators = []
    this._columnMeta[index].description = ''
    await this.updateStates()
    this.setState({ ...this._columnMeta })
  }

  public async updateStates() {
    await this.lockColumns()
    await this.markMatchedDuplicates()
    await this.updateValidation()
  }

  public async changeColumnMatch(index, value) {
    if (this.columnOptions.indexOf(value) === -1) {
      this.addCustomColumn(value)
    }
    if (this._columnMeta[index].newName !== value) {
      this._columnMeta[index].newName = value
      this._columnMeta[index].matchState = 'matched'
      this._columnMeta[index].validators = this.defaultValidators[value] || []
      this._columnMeta[index].description =
        this.defaultDescriptions[value] || ''
      this._columnMeta[index].type = this.defaultTypes[value]
      this._columnMeta[index].options = this.defaultOptions[value]
      this._columnMeta[index].optionsMap =
        this.defaultTypes[value] === 'select'
          ? getDefaultMatches(
              this.csvData,
              this._columnMeta[index].oldName,
              this.defaultOptions[value]
            )
          : {}
      await this.updateStates()
      this.setState({
        ...this._columnMeta,
        ...this.columnOptions,
        ...this.customColumns,
        ...this.keyNames
      })
    }
  }

  public async changeOptionsMatch(index, value, newValue) {
    this._columnMeta[index].optionsMap = {
      ...this._columnMeta[index].optionsMap,
      [value]: newValue
    }
    await this.updateStates()
    this.setState({ ...this._columnMeta })
  }

  public lockColumns() {
    this._columnMeta = this._columnMeta.reduce((acc, col) => {
      if (col.matchState === 'locked') {
        col.matchState = 'matched'
      }
      acc.push(col)
      return acc
    }, []) // unlock columns
    this._columnMeta = this._columnMeta.reduce((acc, col) => {
      if (
        col.matchState === 'matched' &&
        this._columnMeta.some(
          c => c.matchState === 'confirmed' && c.newName === col.newName
        )
      ) {
        col.matchState = 'locked'
      }
      acc.push(col)
      return acc
    }, []) // lock only columns that need to be
  }

  public markMatchedDuplicates() {
    const duplicateColumns = this.duplicateStates([
      'matched',
      'confirmed',
      'locked'
    ])
    this._columnMeta = this._columnMeta.reduce((acc, col) => {
      if (duplicateColumns.indexOf(col.newName) > -1) {
        col.duplicate = true
      } else {
        col.duplicate = false
      }
      acc.push(col)
      return acc
    }, [])
  }

  public async updateValidation() {
    const reducedColumnMeta = await reduce(
      this._columnMeta,
      '',
      (acc, col, cb) => {
        // reduce to configuration that tracks only what will change a validation
        if (
          !col.validators.length ||
          !['matched', 'confirmed'].includes(col.matchState)
        ) {
          acc += 'null'
        } else {
          acc += col.newName
        }

        cb(null, acc)
      }
    )

    const matchStateKey = await md5(reducedColumnMeta)

    if (!(matchStateKey in this.validationLog)) {
      // only validate and create new map of errors in columns if it hasn't been done for this configuration yet
      await processData(
        this.header,
        this._columnMeta,
        this.csvData,
        this.keyNames,
        this.defaultTypes,
        this.defaultOptions
      ).then(async ({ newData }) => {
        if (newData.columns.length && newData.rows.length) {
          // if no columns are matched, processData will return empty arrays
          const validate = new Validate(
            newData.rows,
            newData.columns,
            this.parentHasValidator ? this.handshake : undefined
          )
          await validate.validateTable()
          this.validationLog[matchStateKey] = validate.columnsInvalid
        } else {
          this.validationLog[matchStateKey] = []
        }
      })
    }
    this.columnsInvalid = await this.validationLog[matchStateKey]
  }

  public countState(checkedStates) {
    const names = this._columnMeta.reduce((acc, col) => {
      if (checkedStates.indexOf(col.matchState) > -1) {
        acc.push(col.newName)
      }
      return acc
    }, [])
    return names.reduce(
      (a, b) => Object.assign(a, { [b]: (a[b] || 0) + 1 }),
      {}
    )
  }

  public duplicateStates(checkedStates) {
    const countedStates = this.countState(checkedStates)
    return Object.keys(countedStates).filter(v => countedStates[v] > 1)
  }
}
