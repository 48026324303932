import React from 'react'
import styled from 'styled-components'
import { Translation } from 'react-i18next'

const LockIcon = props => (
  <svg fill='none' {...props}>
    <path
      d='M8.5 4.75h-.375V3.375a3.375 3.375 0 10-6.75 0V4.75H1a1 1 0 00-1 1V11a1 1 0 001 1h7.5a1 1 0 001-1V5.75a1 1 0 00-1-1zm-3.75 4.5a1 1 0 110-2 1 1 0 010 2zM6.875 4.5a.25.25 0 01-.25.25h-3.75a.25.25 0 01-.25-.25V3.375a2.125 2.125 0 114.25 0V4.5z'
      fill='#fff'
    />
  </svg>
)

export default LockIcon

export const StyledBanner = styled.div`
  background-color: #fe5783;
  height: 30px;
  font-size: 12px;
  font-weight: 425;
  vertical-align: bottom;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 51;
  width: 100%;
  text-align: center;
  padding-top: 5px;
  margin-top: 5px;

  span {
    vertical-align: sub;
  }

  svg {
    height: 12px;
    width: 12px;
    margin-right: 7px;
    margin-bottom: 2px;
  }
`

export const DevBanner = () => {
  return (
    <Translation ns='translation'>
      {t => (
        <StyledBanner>
          <span>
            <LockIcon />
          </span>
          {t('devMode')}
        </StyledBanner>
      )}
    </Translation>
  )
}
