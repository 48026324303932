import React, { Component } from 'react'
import { Creatable } from 'react-select'
import VirtualizedSelect from 'react-virtualized-select'

import 'react-select/dist/react-select.css'
import 'react-virtualized/styles.css'
import 'react-virtualized-select/styles.css'
import { IColumnMeta, IKeyNameMap } from '@interfaces/general.interface'
import { Translation } from 'react-i18next'

export type MatcherType = {
  changeColumnMatch: (idx: number, selectValue: string) => void
  columnMeta: IColumnMeta
  customColumns: string[]
  columnOptions: string[]
  keyNames: IKeyNameMap
  undoMatch: (idx: number, value?: string) => void
  changeOptionsMatch: (index: number, v: string, selectValue: object) => void
  columnsInvalid: boolean
  confirmMatch: (idx: number) => void
  ignoreColumn: (idx: number) => void
  undoConfirm: (idx: number) => void
}

type FieldOptionsProps = {
  columnName: string
  originalName: string
  header: boolean
  index: number
  nameIndex: string
  defaultName: string
  columnOptions: string[]
  keyNames: IKeyNameMap
  allowCustom: boolean
  matcher: MatcherType
}

type FieldOptionState = {
  columnName: string
  originalName: string
  header: boolean
  index: number
  nameIndex: string
  defaultName: string
  columnOptions: string[]
  keyNames: IKeyNameMap
  allowCustom: boolean
  selectValue: string
}

export default class extends Component<FieldOptionsProps, FieldOptionState> {
  public state: FieldOptionState = {
    columnName: this.props.columnName,
    originalName: this.props.originalName,
    defaultName: this.props.defaultName,
    header: this.props.header,
    index: this.props.index,
    nameIndex: this.props.nameIndex,
    selectValue: this.props.defaultName,
    columnOptions: this.props.columnOptions,
    keyNames: this.props.keyNames,
    allowCustom: this.props.allowCustom
  }
  constructor(props: FieldOptionsProps) {
    super(props)
  }

  public componentWillReceiveProps(nextProps: FieldOptionsProps) {
    if (nextProps.defaultName !== this.state.defaultName) {
      this.setState({
        defaultName: nextProps.defaultName,
        selectValue: nextProps.defaultName
      })
    }

    if (nextProps.columnOptions !== this.state.columnOptions) {
      this.setState({ columnOptions: nextProps.columnOptions })
    }

    if (nextProps.keyNames !== this.state.keyNames) {
      this.setState({ keyNames: nextProps.keyNames })
    }
  }

  public render() {
    const options: Array<{}> =
      this.state.columnOptions &&
      this.state.columnOptions.map((key, index) => {
        return {
          label: this.state.keyNames[this.state.columnOptions[index] as string],
          value: this.state.columnOptions[index]
        }
      })
    if (this.state.allowCustom) {
      return (
        <Translation ns='translation'>
          {t => (
            <VirtualizedSelect
              options={options}
              clearable={false}
              tabIndex={((this.state.index + 1) * 3).toString()}
              selectComponent={Creatable}
              placeholder={t('lookupMatchingFields')}
              onChange={(selectValue: { value: string }) => {
                selectValue &&
                  this.props.matcher.changeColumnMatch(
                    this.state.index,
                    selectValue.value
                  )
              }}
              value={this.state.selectValue}
            />
          )}
        </Translation>
      )
    } else {
      return (
        <Translation ns='translation'>
          {t => (
            <VirtualizedSelect
              options={options}
              clearable={false}
              tabIndex={((this.state.index + 1) * 3).toString()}
              placeholder={t('lookupMatchingFields')}
              onChange={(selectValue: { value: string }) => {
                selectValue &&
                  this.props.matcher.changeColumnMatch(
                    this.state.index,
                    selectValue.value
                  )
              }}
              value={this.state.selectValue}
            />
          )}
        </Translation>
      )
    }
  }
}
