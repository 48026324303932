import { IStyleOverrides } from '@interfaces/settings.interface'
import React, { Component } from 'react'
import { Style } from 'react-style-tag'
import tinycolor from 'tinycolor2'

interface ICustomStylesProps {
  styles: IStyleOverrides
}

export default class CustomStyles extends Component<ICustomStylesProps> {
  public render() {
    const styleDefaults = {
      borderRadius: '4px',
      buttonHeight: '44px',
      primaryButtonColor: '#4a90e2', // $blue
      secondaryButtonColor: '#e8e8e8', // $snowman
      primaryButtonBorderColor: null,
      invertedButtonColor: null,
      linkColor: '#c6d1dd', // $cloudy-blue
      linkAltColor: '#4a90e2', // $blue
      primaryTextColor: '#50535b', // $gunmetal
      secondaryTextColor: '#9daab6', // $mid-gray
      errorColor: '#d0011b', // $scarlet
      successColor: '#60b700', // $kermit-green
      warningColor: '#f6a623', // $orange
      borderColor: '#e8e8e8', // $snowman
      fontFamily: '"aktiv-grotesk", sans-serif'
    }
    const styles = { ...styleDefaults, ...this.props.styles }
    return (
      <Style>{`
        body {
          font-family: ${styles.fontFamily};
        }
        .loading-line {
          background-color: ${styles.primaryButtonColor} !important;
          box-shadow: 0px 1px 2px #000;
          transition: all 100ms ease;
          -moz-transition: all 100ms ease;
          -webkit-transition: all 100ms ease;
          -o-transition: all 100ms ease;
        }

        .borderRadius, .button, .flatfile-root, .flatfile-modal {
          border-radius: ${styles.borderRadius};
        }

        .button {
          height: ${styles.buttonHeight};
        }

        .button.primary {
          background-color: ${styles.primaryButtonColor};
          ${
            styles.primaryButtonBorderColor
              ? `border: 1px solid ${styles.primaryButtonBorderColor};`
              : ''
          }
        }

        .button.primary:focus, .button.primary:hover {
          background-color: ${tinycolor(styles.primaryButtonColor)
            .darken(20)
            .toString()};
          ${
            styles.primaryButtonBorderColor
              ? `border: 1px solid ${tinycolor(styles.primaryButtonBorderColor)
                  .darken(20)
                  .toString()};`
              : ''
          }
        }

        .button.primary:active {
          background-color: ${tinycolor(styles.primaryButtonColor)
            .darken(30)
            .toString()};
            ${
              styles.primaryButtonBorderColor
                ? `border: 1px solid ${tinycolor(
                    styles.primaryButtonBorderColor
                  )
                    .darken(30)
                    .toString()};`
                : ''
            }
        }

        .button.secondary {
          background-color: ${styles.secondaryButtonColor};
        }

        .button.secondary:focus, .button.secondary:hover {
          background-color: ${tinycolor(styles.secondaryButtonColor)
            .darken(20)
            .toString()};
        }

        .button.secondary:active {
          background-color: ${tinycolor(styles.secondaryButtonColor)
            .darken(30)
            .toString()};
        }

        ${
          styles.invertedButtonColor
            ? `
        .button.invert {
          color: ${styles.invertedButtonColor};
          border: 1px solid ${styles.invertedButtonColor};
        }

        .button.invert:focus, .button.invert:hover {
          color: ${tinycolor(styles.invertedButtonColor)
            .darken(20)
            .toString()};
          border: 1px solid ${tinycolor(styles.invertedButtonColor)
            .darken(20)
            .toString()};
        }

        .button.invert:active {
          color: ${tinycolor(styles.invertedButtonColor)
            .darken(30)
            .toString()};
          border: 1px solid ${tinycolor(styles.invertedButtonColor)
            .darken(30)
            .toString()};
        }

        `
            : ''
        }

        .button.dark {
          background-color: ${styles.primaryTextColor};
          color: white;
        }

        .button.dark:focus, .button.dark:hover {
          background-color: ${tinycolor(styles.primaryTextColor)
            .darken(20)
            .toString()};
        }

        .button.dark:active {
          background-color: ${tinycolor(styles.primaryTextColor)
            .darken(30)
            .toString()};
        }

        .button.success {
          background-color: ${styles.successColor};
          color: white;
        }

        .button.success:focus, .button.success:hover {
          background-color: ${tinycolor(styles.successColor)
            .darken(10)
            .toString()};
        }

        .button.success:active {
          background-color: ${tinycolor(styles.successColor)
            .darken(20)
            .toString()};
        }

        .button.gray {
          background-color: ${styles.borderColor};
          color: ${styles.primaryTextColor};
        }

        .button.gray:focus, .button.gray:hover, .button.gray:active {
          background-color: ${tinycolor(styles.borderColor)
            .darken(10)
            .toString()};
        }

        .linkColor {
          color: ${styles.linkColor};
        }

        .linkColor:hover, .linkColor:active, .linkColor:focus {
          color: ${styles.linkAltColor};
        }

        .primaryTextColor {
          color: ${styles.primaryTextColor};
        }
        .handsontable thead th, .Select .Select-value-label {
          color: ${styles.primaryTextColor} !important;
        }

        .secondaryTextColor {
          color: ${styles.secondaryTextColor};
        }
        .handsontable .htDimmed {
          color: ${styles.secondaryTextColor} !important;
        }

        .errorColor {
          color: ${styles.errorColor};
        }

        .successColor {
          color: ${styles.successColor};
        }

        .warningColor {
          color: ${styles.warningColor};
        }

        .borderColor {
          border-color: ${styles.borderColor};
        }

        .fontFamily {
          font-family: ${styles.fontFamily};
        }

        .col-required:after {
          background-color: ${styles.successColor};
        }

        .check-toggle[data-line="true"] {
          border-color: ${styles.successColor};
          background-color: ${styles.successColor};
        }
        .check-toggle[data-line="true"]:after {
          border-color: ${styles.successColor};
        }
        .check-toggle[data-line="true"]:before {
          background-color: ${styles.successColor};
        }

        .fa-check-square:hover{
          color: ${styles.successColor};
        }

        .col-body .column-unmatched .fa, .fa-warning {
          color: ${styles.warningColor};
        }

        .progress-current, .primary-header, .secondary-header {
          color: ${styles.primaryTextColor};
        }

        .handsontable td.htInvalid {
          background-color: ${tinycolor
            .mix(styles.errorColor, 'white', 90)
            .toString()} !important;
          border-color: ${tinycolor
            .mix(styles.errorColor, 'white', 80)
            .toString()} !important;
          color: ${styles.errorColor} !important;
        }
        .col-body aside.duplicate {
          border-color: ${styles.errorColor};
        }
        .col-body .duplicate-warning, .col-body .column-ignored .fa, .col-body .column-locked .fa, .parse-error-tables .fa-trash:hover {
          color: ${styles.errorColor};
        }
    `}</Style>
    )
  }
}
