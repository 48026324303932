import { DevBanner } from '@components/DevBanner'

import {
  IFeatures,
  ISettingsInternal
} from '@interfaces/internal.settings.interface'
import { IBatchConfig, ISettings } from '@interfaces/settings.interface'
import { CloseButton, GenericButton } from '@lib/elements'
import prettyBytes from 'pretty-bytes'
import React, { Component, RefObject } from 'react'
import Dropzone, { DropzoneOptions } from 'react-dropzone'
import { Translation } from 'react-i18next'
import Modal from 'react-modal'
import App from '../../index'
import StagingManager from '../StagingManager'
import { ActionSequence } from './ActionSequence'
import { DownloadManager } from './DownloadManager'

export type UploadLog = {
  clientID?: string
  source: string
  status: string
  files: File[]
}

interface IUploadWrapperProps {
  // tslint:disable-next-line:no-any
  handshake: any
  close: App['close']
  settings: ISettingsInternal
  loadingMessage: string
  successMessage: string
  modalLoaderIsOpen: boolean
  modalErrorIsOpen: boolean
  modalSuccessIsOpen: boolean
  parentHasValidator: boolean
  modalErrorContent: string
  modalErrorDismiss: App['dismissError']
  registerChunkCallback: App['registerChunkCallback']
  returnUUID: App['returnUUID']
  asyncSetState: App['asyncSetState']
  updateMeta: App['updateMeta']
  batchConfig: IBatchConfig
  plan?: IFeatures
}

interface IUploadWrapperState {
  clientID?: string
  accept: string
  files: File[]
  uploadLog: UploadLog[]
  settings?: ISettings
  modalRewindVerifyIsOpen: boolean
  modalIsOpen: boolean
  modalContent: JSX.Element | string
  csvLoaded: boolean
  dropzoneActive: boolean
  loadingMessage: string
  successMessage: string
  modalLoaderIsOpen: boolean
  modalErrorIsOpen: boolean
  modalSuccessIsOpen: boolean
  modalErrorContent?: string
  modalCloseIsOpen: boolean
  userConfirmationModal: {
    open: boolean
    content?: JSX.Element
    confirmLabel?: string
    working?: boolean
    secondPhaseConfirmLabel?: string
  }
}

export default class UploadWrapper extends Component<
  IUploadWrapperProps,
  IUploadWrapperState
> {
  public readonly state: IUploadWrapperState = {
    clientID: undefined,
    accept: '',
    files: [],
    uploadLog: [],
    settings: undefined,
    modalRewindVerifyIsOpen: false,
    modalIsOpen: false,
    modalContent: null,
    csvLoaded: false,
    dropzoneActive: false,
    loadingMessage: '',
    successMessage: '',
    modalLoaderIsOpen: false,
    modalErrorIsOpen: false,
    modalSuccessIsOpen: false,
    modalErrorContent: undefined,
    modalCloseIsOpen: false,
    userConfirmationModal: {
      open: false,
      content: undefined,
      confirmLabel: undefined,
      working: undefined,
      secondPhaseConfirmLabel: undefined
    }
  }
  public readonly stagerRef: RefObject<StagingManager>
  private readonly close: () => void
  private resolveUserConfirmationModal?: (
    isConfirmed: boolean
  ) => Promise<void> = undefined

  constructor(props: IUploadWrapperProps) {
    super(props)
    this.stagerRef = React.createRef()
    this.close = props.close
    this.state = {
      ...this.state,
      accept:
        props.settings.features &&
        props.settings.features.xls &&
        props.settings.managed
          ? '.csv,.tsv,.xls,.xlsx'
          : '.csv,.tsv',
      settings: props.settings,
      loadingMessage: props.loadingMessage,
      successMessage: props.successMessage,
      modalLoaderIsOpen: props.modalLoaderIsOpen,
      modalErrorIsOpen: props.modalErrorIsOpen,
      modalSuccessIsOpen: props.modalSuccessIsOpen,
      modalErrorContent: props.modalErrorContent
    }
  }

  public onDrop: DropzoneOptions['onDrop'] = async (
    acceptedFiles: File[],
    rejectedFiles: File[]
  ) => {
    if (acceptedFiles.length) {
      const { maxSize, maxPartitionSize } = this.props.settings
      const fileSize = acceptedFiles[0].size

      if (maxSize) {
        if (fileSize > maxSize) {
          this.uploadLogUpdate(
            this.state.clientID,
            'File Uploader Button or Drop Zone',
            'Rejected due to file size',
            acceptedFiles
          )
          this.openModal(
            `The file you uploaded is ${prettyBytes(
              fileSize
            )}. The maximum file size we accept is ${prettyBytes(
              maxSize
            )}. Consider breaking your file into multiple parts.`
          )

          return
        }
      }

      if (maxPartitionSize) {
        if (
          fileSize >
          maxPartitionSize *
            1.5 /* 50% buffer room because the split is never perfect */
        ) {
          const partitionCount = Math.ceil(fileSize / maxPartitionSize)

          this.uploadLogUpdate(
            this.state.clientID,
            'File Uploader Button or Drop Zone',
            `Will partition into ${partitionCount} partition(s) due to file size ${fileSize}
            (max partition size is ${maxPartitionSize})`,
            acceptedFiles
          )

          // @ts-ignore
          // tslint:disable-next-line:no-any
          const runDownloadProcess: any = new ActionSequence()

          const downloadAllFiles = await this.userConfirmationModal(
            <div
              style={{
                padding: '20px 30px 0',
                textAlign: 'left'
              }}
            >
              <h4 style={{ paddingBottom: '10px' }}>File size warning</h4>
              <p className='message' style={{ paddingBottom: '10px' }}>
                The file you are attempting to upload is too large. If you would
                like to continue, please select &ldquo;Download&rdquo; to have
                the importer automatically separate your file so that you can
                upload the data.
              </p>
              <DownloadManager
                file={acceptedFiles[0]}
                partitionCount={partitionCount}
                actionSequence={runDownloadProcess}
              />
            </div>,
            'Download',
            runDownloadProcess.start,
            'Return to upload'
          )

          if (downloadAllFiles) {
            // @todo show prompt to re-upload files
          }

          return
        }
      }

      this.uploadLogUpdate(
        this.state.clientID,
        'File Uploader Button or Drop Zone',
        'Successfully uploaded',
        acceptedFiles
      )

      this.setState({
        files: acceptedFiles,
        dropzoneActive: false
      })
    } else {
      this.uploadLogUpdate(
        this.state.clientID,
        'File Uploader Button or Drop Zone',
        'Rejected due to wrong filetype',
        rejectedFiles
      )
      this.openModal(
        `The filetype was not accepted! Please upload a file with one of the following extensions: ${this.state.accept.replace(
          /,/g,
          ', '
        )}`
      )
    }
  }

  public componentWillReceiveProps(nextProps: IUploadWrapperProps) {
    if (nextProps.modalLoaderIsOpen !== this.state.modalLoaderIsOpen) {
      this.setState({
        modalLoaderIsOpen: nextProps.modalLoaderIsOpen
      })
    }
    if (nextProps.modalSuccessIsOpen !== this.state.modalSuccessIsOpen) {
      this.setState({
        modalSuccessIsOpen: nextProps.modalSuccessIsOpen
      })
    }
    if (nextProps.modalErrorIsOpen !== this.state.modalErrorIsOpen) {
      this.setState({
        modalErrorIsOpen: nextProps.modalErrorIsOpen
      })
    }
    if (nextProps.modalErrorContent !== this.state.modalErrorContent) {
      this.setState({
        modalErrorContent: nextProps.modalErrorContent
      })
    }
    if (nextProps.successMessage !== this.state.successMessage) {
      this.setState({ successMessage: nextProps.successMessage })
    }
    if (nextProps.loadingMessage !== this.state.loadingMessage) {
      this.setState({ loadingMessage: nextProps.loadingMessage })
    }
  }

  public uploadLogUpdate = (
    clientID: string | undefined,
    source: string,
    status: string,
    files: File[] = []
  ) => {
    const uploadLog = this.state.uploadLog.slice()
    uploadLog.push({ clientID, source, status, files })
    this.setState({ uploadLog })
  }

  public csvLoad = () => {
    this.setState({ files: [], csvLoaded: true })
  }

  public csvUnload = () => {
    this.setState({ csvLoaded: false })
  }

  public onDragEnter = () => {
    if (!this.state.csvLoaded) {
      this.setState({
        dropzoneActive: true
      })
    }
  }

  public onDragLeave = () => {
    this.setState({
      dropzoneActive: false
    })
  }

  public openModal = (content: JSX.Element | string) => {
    this.setState({ modalIsOpen: true, modalContent: content })
  }

  /**
   * @todo refactor: usage of refs here is unjustified
   */
  public openVerifyClose = () => {
    if (!this.stagerRef.current) {
      return
    }
    const stager = this.stagerRef.current
    if (
      stager.state.activeStage === 1 &&
      stager.loaderRef.current &&
      stager.loaderRef.current.isInitialTableEmpty()
    ) {
      this.stagerRef.current.clearData()
      this.close()
    } else {
      this.setState({ modalCloseIsOpen: true })
    }
  }

  public closeModal = (modal?: string) => {
    switch (modal) {
      case 'loader':
        // do nothing
        break
      case 'reset':
        this.state.files.splice(0, this.state.files.length)
        this.setState({ modalIsOpen: false })
        break
      case 'success':
        if (this.stagerRef.current) {
          this.stagerRef.current.clearData()
        }
        this.setState({ modalSuccessIsOpen: false })
        this.close()
        break
      case 'error':
        this.setState({
          modalErrorIsOpen: false,
          modalErrorContent: undefined
        })
        if (this.props.modalErrorDismiss) {
          this.props.modalErrorDismiss()
        }
        break
      case 'close-cancel':
        this.setState({ modalCloseIsOpen: false })
        break
      case 'close-confirm':
        if (this.stagerRef.current) {
          this.stagerRef.current.clearData()
        }
        this.setState({ modalCloseIsOpen: false })
        this.close()
        break
      default:
        this.setState({ modalIsOpen: false })
    }
  }

  public openModalRewindVerify = () => {
    this.setState({ modalRewindVerifyIsOpen: true })
  }

  public closeModalRewindVerify = (verify: boolean) => {
    if (verify) {
      this.setState({ modalRewindVerifyIsOpen: false })
      if (this.stagerRef.current) {
        this.stagerRef.current.rewindStage(true)
      }
    } else {
      this.setState({ modalRewindVerifyIsOpen: false })
    }
  }

  public render() {
    const { accept, files, dropzoneActive, userConfirmationModal } = this.state
    const { plan, registerChunkCallback } = this.props
    return (
      <Translation ns='translation'>
        {t => (
          <div>
            <CloseButton onClick={this.openVerifyClose} />
            <Dropzone
              accept={accept}
              multiple={false}
              onDrop={this.onDrop}
              onDragEnter={this.onDragEnter}
              onDragLeave={this.onDragLeave}
            >
              {({ getRootProps }) => (
                <div {...getRootProps()}>
                  {dropzoneActive && (
                    <div className='flatfile-dropzone'>
                      <h1>Drop files...</h1>
                    </div>
                  )}
                  <StagingManager
                    ref={this.stagerRef}
                    settings={this.props.settings}
                    returnUUID={this.props.returnUUID}
                    registerChunkCallback={registerChunkCallback}
                    asyncSetState={this.props.asyncSetState}
                    updateMeta={this.props.updateMeta}
                    parentHasValidator={this.props.parentHasValidator}
                    handshake={this.props.handshake}
                    batchConfig={this.props.batchConfig}
                    files={files}
                    accept={accept}
                    csvUnload={this.csvUnload}
                    csvLoad={this.csvLoad}
                    uploadLog={this.state.uploadLog}
                    uploadLogUpdate={this.uploadLogUpdate}
                    openModalRewindVerify={this.openModalRewindVerify}
                    openModal={this.openModal}
                    onDrop={this.onDrop}
                    hasDevBanner={plan && plan.developmentMode}
                  />
                  {plan && plan.developmentMode ? <DevBanner /> : null}
                </div>
              )}
            </Dropzone>
            {userConfirmationModal.open ? (
              <Modal
                isOpen={true}
                className='flatfile-modal confirm borderColor'
                overlayClassName='flatfile-modal-overlay'
                contentLabel='Confirm'
              >
                {userConfirmationModal.content}
                <div className='controlbar' style={{ textAlign: 'right' }}>
                  {userConfirmationModal.confirmLabel !==
                  userConfirmationModal.secondPhaseConfirmLabel ? (
                    <GenericButton
                      id='deny-large-file-partition-upload'
                      title={t('buttons.cancel')}
                      disabled={userConfirmationModal.working}
                      classes={['invert']}
                      onClick={() => {
                        if (this.resolveUserConfirmationModal) {
                          this.resolveUserConfirmationModal(false).then(_r => {
                            // do nothing
                          })
                        }
                      }}
                    />
                  ) : (
                    <span />
                  )}
                  <GenericButton
                    id='confirm-large-file-partition-upload'
                    title={
                      userConfirmationModal.confirmLabel ||
                      t('buttons.continue')
                    }
                    disabled={userConfirmationModal.working}
                    classes={['primary']}
                    onClick={() => {
                      if (this.resolveUserConfirmationModal) {
                        this.resolveUserConfirmationModal(true).then(_r => {
                          // do nothing
                        })
                      }
                    }}
                  />
                </div>
              </Modal>
            ) : null}
            <Modal
              isOpen={this.state.modalRewindVerifyIsOpen}
              onRequestClose={() => this.closeModalRewindVerify(true)}
              className='flatfile-modal confirm borderColor'
              overlayClassName='flatfile-modal-overlay'
              contentLabel='Confirm'
            >
              <h4 className='primaryTextColor'>{t('clearAllChanges')}</h4>
              <div className='controlbar'>
                <GenericButton
                  id='deny-rewind'
                  title={t('buttons.no')}
                  classes={['invert']}
                  onClick={() => {
                    this.closeModalRewindVerify(false)
                  }}
                />
                <GenericButton
                  id='confirm-rewind'
                  title={t('buttons.yes')}
                  classes={['primary']}
                  onClick={() => {
                    this.closeModalRewindVerify(true)
                  }}
                />
              </div>
            </Modal>
            <Modal
              isOpen={this.state.modalCloseIsOpen}
              onRequestClose={() => this.closeModal('close-cancel')}
              className='flatfile-modal confirm borderColor'
              overlayClassName='flatfile-modal-overlay'
              contentLabel='Confirm Close'
            >
              <h4 className='primaryTextColor'>{t('clearAndClose')}</h4>
              <div className='controlbar'>
                <GenericButton
                  id='cancel-close'
                  title={t('buttons.no')}
                  classes={['invert']}
                  onClick={() => this.closeModal('close-cancel')}
                />
                <GenericButton
                  id='confirm-close'
                  title={t('buttons.yes')}
                  classes={['primary']}
                  onClick={() => this.closeModal('close-confirm')}
                />
              </div>
            </Modal>
            <Modal
              isOpen={this.state.modalIsOpen}
              onRequestClose={() => this.closeModal()}
              className='flatfile-modal error borderColor'
              overlayClassName='flatfile-modal-overlay'
              contentLabel='Error'
            >
              <h4 className='primaryTextColor'>{this.state.modalContent}</h4>
              <div className='controlbar single'>
                <GenericButton
                  id='error-acknowledge'
                  title={t('buttons.ok')}
                  classes={['primary']}
                  onClick={() => this.closeModal('reset')}
                />
              </div>
            </Modal>
            <Modal
              isOpen={this.state.modalLoaderIsOpen}
              onRequestClose={() => this.closeModal('loader')}
              className='flatfile-modal upload-progress borderColor'
              overlayClassName='flatfile-modal-overlay'
              shouldCloseOnOverlayClick={false}
              contentLabel='Uploading'
            >
              <h4 className='primaryTextColor'>
                {this.state.loadingMessage || t('uploading')}
              </h4>
            </Modal>
            <Modal
              isOpen={this.state.modalErrorIsOpen}
              onRequestClose={() => this.closeModal('error')}
              className='flatfile-modal upload-error borderColor'
              overlayClassName='flatfile-modal-overlay'
              contentLabel='Upload Error'
            >
              <h4 className='primaryTextColor'>
                {t('errors.error', { errors: this.state.modalErrorContent })}
              </h4>
              <div className='controlbar single'>
                <GenericButton
                  id='upload-error-modal-acknowledge'
                  title={t('buttons.ok')}
                  classes={['primary']}
                  onClick={() => this.closeModal('error')}
                />
              </div>
            </Modal>
            <Modal
              isOpen={this.state.modalSuccessIsOpen}
              onRequestClose={() => this.closeModal('success')}
              className='flatfile-modal upload-success borderColor'
              overlayClassName='flatfile-modal-overlay'
              contentLabel='Upload Success'
            >
              <h4 className='primaryTextColor'>
                {this.state.successMessage || t('success')}
              </h4>
              <div className='controlbar single'>
                <GenericButton
                  id='upload-success-modal-acknowledge'
                  title={t('buttons.ok')}
                  classes={['primary']}
                  onClick={() => this.closeModal('success')}
                />
              </div>
            </Modal>
          </div>
        )}
      </Translation>
    )
  }

  private userConfirmationModal(
    content: JSX.Element,
    confirmLabel: string,
    onConfirm: () => boolean,
    secondPhaseConfirmLabel: string
  ) {
    return new Promise(resolve => {
      this.resolveUserConfirmationModal = async (isConfirmed: boolean) => {
        if (isConfirmed) {
          if (
            onConfirm &&
            this.state.userConfirmationModal.confirmLabel !==
              secondPhaseConfirmLabel
          ) {
            this.setState({
              userConfirmationModal: {
                ...this.state.userConfirmationModal,
                working: true
              }
            })

            const stillResolve = await onConfirm()

            if (!stillResolve) {
              this.setState({
                userConfirmationModal: {
                  ...this.state.userConfirmationModal,
                  working: false
                }
              })

              return
            }
          }

          if (
            secondPhaseConfirmLabel &&
            this.state.userConfirmationModal.confirmLabel !==
              secondPhaseConfirmLabel
          ) {
            this.setState({
              userConfirmationModal: {
                ...this.state.userConfirmationModal,
                confirmLabel: secondPhaseConfirmLabel,
                working: false
              }
            })

            return
          }
        }

        this.setState({
          userConfirmationModal: {
            ...this.state.userConfirmationModal,
            open: false,
            content: undefined
          }
        })

        resolve(isConfirmed)
      }

      this.setState({
        dropzoneActive: false,
        userConfirmationModal: {
          ...this.state.userConfirmationModal,
          open: true,
          content,
          confirmLabel,
          secondPhaseConfirmLabel
        }
      })
    })
  }
}
