import { ITERATOR_FPS } from '@config'

let currentTick = 0
let lastTick = 0
let lastTickAt = performance.now()
let lastGapAt = performance.now()
let iterationCount = 0
let lastIterationCount = 0
let tickGap: false | Promise<void> = false

const MAX_TIME_PER_FRAME = 1000 / ITERATOR_FPS

setInterval(() => {
  currentTick++
  // prevent currentTick from incrementing too far
  if (currentTick > 1e6) {
    currentTick = 0
  }
  lastTickAt = performance.now()
}, 0)

/**
 * Ensure that any iterative logic leaves room for animation frames. This can
 * be applied to any loop anywhere and will ensure that logic never lasts more
 * than one tick but also fills each frame fully
 *
 * @param callback
 * @param onTick
 * @param forceTick
 */
// tslint:disable-next-line:no-any
export async function tickWithFrame<C extends (...args: any[]) => Promise<any>>(
  callback: C,
  onTick?: () => void,
  forceTick?: boolean
): Promise<void> {
  if ((!tickGap && shouldTick()) || forceTick) {
    tickGap = new Promise(resolve => {
      setTimeout(() => {
        tickGap = false
        lastIterationCount = iterationCount
        lastGapAt = performance.now()
        resolve()
      }, Math.ceil(MAX_TIME_PER_FRAME / 2))
    })
  }
  if (tickGap) {
    await tickGap
    if (onTick) {
      onTick()
    }
  } else if (currentTick !== lastTick && onTick) {
    onTick()
  }
  iterationCount++
  lastTick = currentTick
  await callback()
}

function shouldTick(): boolean {
  return (
    (currentTick === lastTick || currentTick === lastTick + 1) &&
    performance.now() - lastGapAt >= MAX_TIME_PER_FRAME
  )
}
