import { ROOT_API_URL } from '@config'
import {
  IColumnMeta,
  IColumnMetaDictionary,
  IDictionary,
  IProcessedColumnMeta,
  IProcessedData,
  IProcessedRow
} from '@interfaces/general.interface'
import {
  IFieldInternal,
  ISettingsInternal
} from '@interfaces/internal.settings.interface'
import {
  IBatchConfig,
  IFieldOptionDictionary
} from '@interfaces/settings.interface'
import { FooterBrand, GenericButton, ProgressHeader } from '@lib/elements'
import { checkStatus, storageAvailable, updateBatchLog } from '@lib/functions'
import Validate from '@lib/validate'
import { asyncMap } from '@utils/iterators'
import eachOfSeries from 'async/eachOfSeries'
import mapSeries from 'async/mapSeries'
import axios from 'axios'
import 'font-awesome/css/font-awesome.css'
import 'handsontable/dist/handsontable.full.css'
import pluralize from 'pluralize'
import React, { Component } from 'react'
import { Translation } from 'react-i18next'
import Modal from 'react-modal'
import App from '../../index'
import StagingManager from '../StagingManager'
import Spinner from '../upload/Spinner'
import UploadWrapper from '../upload/UploadWrapper'
import HoT from './table'

// tslint:disable-next-line:no-var-requires
const warningIcon = require('./warning.svg')

type ReviewProps = {
  registerChunkCallback: App['registerChunkCallback']
  newData: IProcessedData['newData']
  columnMeta: IColumnMeta
  header: boolean
  handshake: Promise<any> // tslint:disable-line:no-any
  updateMeta: App['updateMeta']
  openModal: UploadWrapper['openModal']
  toggleProgressOverlay: StagingManager['toggleProgressOverlay']
  rewindStage: StagingManager['rewindStage']
  nextStage: StagingManager['nextStage']
  parentHasValidator: boolean
  returnUUID: App['returnUUID']
  batchConfig: IBatchConfig
  settings: ISettingsInternal
  activeStage: number
  usedCustomColumns: string[]
  dataType: ISettingsInternal['type']
  defaultTypes: IDictionary<IFieldInternal['type']>
  defaultOptions: IDictionary<IFieldOptionDictionary[]>
  columns: IProcessedColumnMeta[]
  defaultShortDescriptions: IDictionary<string | undefined>
}

type ReviewState = {
  batchConfig: IBatchConfig
  settings: ISettingsInternal
  activeStage: number
  usedCustomColumns: string[]
  dataType: ISettingsInternal['type']
  reloading: boolean
  rows: IProcessedRow[]
  hideValidatedRows: boolean
  filteredRows: IProcessedRow[]
  rememberEdits: boolean
  modalFinalVerifyIsOpen: boolean
  columnMeta: IColumnMeta
  columns: IProcessedColumnMeta[]
  hasBeenEdited: boolean
  t0: DOMHighResTimeStamp
  validated: boolean
  tableHeight: number
}

export default class Review extends Component<ReviewProps, ReviewState> {
  public HoTRef: React.MutableRefObject<HoT>
  public validate: Validate
  public validatedIn: number
  public pointer: number

  constructor(props: ReviewProps) {
    super(props)

    props.registerChunkCallback(() => this.nextChunk())
    this.HoTRef = React.createRef()
    this.pointer = 0
    this.validate = null
    this.state = {
      columns: null,
      rows: null,
      filteredRows: null,
      columnMeta: null,
      t0: window.performance.now(),
      modalFinalVerifyIsOpen: false,
      batchConfig: props.batchConfig,
      reloading: false,
      hasBeenEdited: false,
      settings: props.settings,
      validated: false,
      hideValidatedRows: false,
      activeStage: props.activeStage,
      usedCustomColumns: props.usedCustomColumns,
      dataType: props.dataType,
      tableHeight: 500,
      rememberEdits: null
    }
    this.initializeData()
  }

  public async initializeData() {
    const { props } = this
    const data = props.newData
    const rows = []

    await eachOfSeries(data.rows, (values, index, cb) => {
      rows.push({
        $meta: { deleted: false, index: this.props.header ? index : index },
        ...values
      })
      cb()
    })

    const columnMeta = [
      {
        newName: '$meta',
        validators: [],
        description: '',
        matchState: 'ignored'
      } as IColumnMetaDictionary,
      ...props.columnMeta
    ]

    const columns = [
      {
        key: '$meta',
        name: '',
        validators: []
      } as IProcessedColumnMeta,
      ...data.columns
    ]

    this.validate = new Validate(
      rows,
      columns,
      this.props.parentHasValidator ? this.props.handshake : undefined
    )
    this.validatedIn = await this.validate.validateTable()
    this.setState({
      columns,
      rows,
      filteredRows: rows,
      columnMeta
    })
  }

  public clearAllRefs() {
    this.HoTRef.current = null
  }

  public componentDidMount() {
    this.props.toggleProgressOverlay(false)
  }

  public componentWillUnmount() {
    this.clearAllRefs()
  }

  public async reportErrorRowCount() {
    const errorRowCount =
      this.validate && this.validate.errorRows.filter(v => !v).length
    updateBatchLog(this.props.returnUUID, { count_rows_invalid: errorRowCount })
  }

  public isReloading = async reloading => {
    this.setState({ reloading })
  }

  public toggleRemember = () => {
    const rememberEdits = !this.state.rememberEdits
    this.setState({ rememberEdits })
  }

  public getFilteredRows(hideValidatedRows) {
    return !hideValidatedRows
      ? this.state.rows
      : this.state.rows.filter(
          (r, i) => this.validate && !this.validate.errorRows[i]
        )
  }

  public setRowState({ hideValidatedRows }, extraState = {}) {
    this.setState({
      hideValidatedRows,
      filteredRows: this.getFilteredRows(hideValidatedRows),
      reloading: true,
      ...extraState
    })
  }

  public toggleValidatedRows = () => {
    const hideValidatedRows = !this.state.hideValidatedRows
    this.setRowState({ hideValidatedRows }, { t0: window.performance.now() })
  }

  public convertRows = async callback => {
    const convertedRows = await mapSeries(this.state.rows, (row, cb) => {
      Object.keys(row).forEach(column => {
        const data = row[column]
        if (this.props.defaultTypes[column] === 'checkbox') {
          if (/^(0|no|false|off|disabled)$/i.test(data)) {
            row[column] = false
          } else if (/^(1|yes|true|on|enabled)$/i.test(data)) {
            row[column] = true
          } else {
            delete row[column]
          }
        } else if (this.props.defaultTypes[column] === 'select') {
          const options = this.props.defaultOptions[column]
          if (typeof options[0] === 'object') {
            const found = options.find(o => o.label === data)
            row[column] = found ? found.value : data
          }
        }
      })

      cb(null, row)
    })

    this.setState({ rows: convertedRows as IProcessedRow[] }, () => {
      callback()
    })
  }

  public closeModalFinalVerify = async choice => {
    switch (choice) {
      case 'ignore':
        this.setState({ modalFinalVerifyIsOpen: false })
        await this.convertRows(() => {
          this.finalSubmit(false)
        })
        break
      case 'include':
        this.setState({ modalFinalVerifyIsOpen: false })
        await this.convertRows(() => {
          this.finalSubmit(true)
        })
        break
      case 'cancel':
      default:
        if (this.validate && this.validate.errorRows.filter(v => !v).length) {
          this.setRowState(
            { hideValidatedRows: true },
            { modalFinalVerifyIsOpen: false }
          )
        } else {
          this.setState({ modalFinalVerifyIsOpen: false })
        }
    }
  }

  public openModalFinalVerify = () => {
    this.setState({
      modalFinalVerifyIsOpen: true,
      t0: window.performance.now()
    })
  }

  public finalSubmit = async includeErrors => {
    const inChunks = this.state.batchConfig.inChunks

    const richData = inChunks ? [] : await this.managedRowsMap()
    if (this.state.settings.managed) {
      this.postRows(richData)
    }
    const rows = inChunks ? [] : this.processRows(includeErrors)
    if (storageAvailable('localStorage')) {
      window.localStorage.setItem(
        'flatfile_columns',
        JSON.stringify(this.state.columns)
      )
      window.localStorage.setItem(
        'flatfile_custom_columns',
        JSON.stringify(this.state.usedCustomColumns)
      )
    }
    const submittedAt = new Date().toISOString()
    const countRowsAccepted = this.state.rows.length
    let importMeta = this.props.updateMeta({
      columns: this.state.columns,
      custom_columns: this.state.usedCustomColumns,
      count_rows_accepted: countRowsAccepted,
      submitted_at: submittedAt,
      validated_in: this.validatedIn
    })
    if (!importMeta.batchID) {
      const batchID = await this.props.returnUUID(true) // bypass server-generated uuid if needed
      importMeta = this.props.updateMeta({ batchID })
    }
    const data = { rows, meta: importMeta }
    this.props.handshake.then(parent => {
      updateBatchLog(this.props.returnUUID, {
        count_rows_accepted: countRowsAccepted,
        submitted_at: submittedAt
      })
      if (this.state.batchConfig.expectsExpandedResults) {
        if (inChunks) {
          importMeta = { ...importMeta, inChunks }
        }
        parent.results({ results: richData, meta: importMeta })
      } else {
        parent.complete(data)
      }
    })
  }

  public async nextChunk() {
    const inChunks = this.state.batchConfig.inChunks
    const richData = await this.managedRowsMap(this.pointer, inChunks)
    if (this.state.settings.managed) {
      this.postRows(richData)
    }
    const importMeta = this.props.updateMeta({
      inChunks,
      pointer: this.pointer,
      hasMore: this.pointer + inChunks < this.state.rows.length
    })
    this.pointer += inChunks
    return { results: richData, meta: importMeta }
  }

  public async postRows(data) {
    const uuid = await this.props.returnUUID()
    axios({
      method: 'post',
      url: `${ROOT_API_URL}/public-api/batches/${uuid}/import-rows`,
      headers: { 'License-Key': window.FF_LICENSE_KEY },
      data: { data }
    })
      .then(checkStatus)
      /* eslint-disable-next-line handle-callback-err */
      .catch(error => {
        /* TODO: add error handling */
      })
  }

  public async managedRowsMap(start?, length?) {
    const rows =
      typeof start !== 'undefined'
        ? this.state.rows.slice(start, start + length)
        : this.state.rows

    // tslint:disable-next-line:no-any
    const managedRows = await asyncMap(rows, async (r: any) => {
      const data = { ...r }
      const { index } = data.$meta
      const oi = typeof start !== 'undefined' ? start + index : index
      delete data.$meta

      return {
        sequence: index + 1,
        deleted: r.$meta.deleted,
        valid: this.validate && this.validate.errorRows[oi],
        data
      }
    })

    return managedRows
  }

  public processRows(includeErrors) {
    return this.state.rows.reduce((acc, row) => {
      if (
        Object.keys(row).some(v => row[v]) &&
        !row.$meta.deleted &&
        ((this.validate && this.validate.errorRows[row.$meta.index]) ||
          (this.validate &&
            !this.validate.errorRows[row.$meta.index] === includeErrors))
      ) {
        const _custom = {}
        let newRow = Object.keys(row).reduce((rowAcc, key) => {
          const meta = this.state.columnMeta.find(
            col =>
              col.newName === key &&
              (col.matchState === 'confirmed' || col.matchState === 'matched')
          )
          if (!meta || row.$meta.deleted) {
            return rowAcc
          } else if (
            (meta.matchState === 'confirmed' ||
              meta.matchState === 'matched') &&
            this.state.usedCustomColumns.indexOf(key) > -1
          ) {
            _custom[key] = row[key]
          } else if (
            meta.matchState === 'confirmed' ||
            meta.matchState === 'matched'
          ) {
            rowAcc[key] = row[key]
          }
          return rowAcc
        }, {})
        if (this.state.usedCustomColumns.length) {
          newRow = Object.assign(newRow, { _custom })
        }
        acc.push(newRow)
      }
      return acc
    }, [])
  }

  public onEdit = () => {
    this.setState({ hasBeenEdited: true })
  }

  public render() {
    const validationHandler = (
      <Translation ns='translation'>
        {t => (
          <div className='flex-start'>
            <label
              htmlFor='toggle-problems'
              data-line={this.state.hideValidatedRows}
              className='check-toggle'
            />
            <span className='primaryTextColor'>{t('onlyShow')}</span>
            <input
              id='toggle-problems'
              type='checkbox'
              name='toggle-problems'
              value='toggleProblems'
              checked={this.state.hideValidatedRows}
              onChange={this.toggleValidatedRows}
            />
          </div>
        )}
      </Translation>
    )
    const rowCount = this.state.rows ? this.state.rows.length : 0
    return (
      <Translation ns='translation'>
        {t => (
          <div className='validation-edit-stage'>
            {this.state.reloading || !this.state.rows ? <Spinner /> : null}
            <div className='scroll-block'>
              <div className='controlbar top'>
                <h1 className='primary-header'>
                  {this.props.settings.title ||
                    t('header', {
                      number: rowCount,
                      thing: pluralize(this.state.dataType, rowCount)
                    })}
                </h1>
                <ProgressHeader stage={this.state.activeStage} />
              </div>
              <div className='controlbar top short flex-between'>
                {validationHandler}
              </div>
              {this.state.rows && (
                <HoT
                  defaultTypes={this.props.defaultTypes}
                  defaultOptions={this.props.defaultOptions}
                  defaultShortDescriptions={this.props.defaultShortDescriptions}
                  columns={this.state.columns}
                  rows={this.state.rows}
                  filteredRows={this.state.filteredRows}
                  columnMeta={this.state.columnMeta}
                  validate={this.validate && this.validate}
                  ref={this.HoTRef}
                  t0={this.state.t0}
                  isReloading={this.isReloading}
                  onEdit={this.onEdit}
                  header={this.props.header}
                />
              )}
            </div>
            <div className='controlbar bottom'>
              <GenericButton
                id='cancel'
                classes={['invert']}
                title={t('buttons.back')}
                onClick={() =>
                  this.props.rewindStage(!this.state.hasBeenEdited)
                }
              />
              <FooterBrand />
              <GenericButton
                id='continue'
                title={t('buttons.complete')}
                classes={['primary']}
                onClick={this.openModalFinalVerify}
              />
            </div>
            <Modal
              isOpen={this.state.modalFinalVerifyIsOpen}
              onRequestClose={() => this.closeModalFinalVerify(false)}
              className='flatfile-modal confirm borderColor'
              overlayClassName='flatfile-modal-overlay'
              contentLabel='Confirm Close'
            >
              {
                <SubmitOptions
                  errorCount={
                    this.validate &&
                    this.validate.errorRows.filter(v => !v).length
                  }
                  allowInvalidSubmit={this.state.settings.allowInvalidSubmit}
                  warningIcon={warningIcon.default}
                  onClick={this.closeModalFinalVerify}
                />
              }
            </Modal>
          </div>
        )}
      </Translation>
    )
  }
}

const SubmitOptions = props => {
  if (props.errorCount > 0 && props.allowInvalidSubmit) {
    return (
      <AllowErrorsOptions
        errorCount={props.errorCount}
        onClick={props.onClick}
        warningIcon={props.warningIcon}
      />
    )
  } else if (props.errorCount > 0) {
    return (
      <ErrorsPresentOptions
        errorCount={props.errorCount}
        onClick={props.onClick}
        warningIcon={props.warningIcon}
      />
    )
  } else {
    return (
      <ValidSubmitOptions
        onClick={props.onClick}
        warningIcon={props.warningIcon}
      />
    )
  }
}

const AllowErrorsOptions = props => (
  <Translation ns='translation'>
    {t => (
      <div className='primaryTextColor'>
        <div className='modal-header'>
          <img src={props.warningIcon} />
          <h4>
            You have {props.errorCount} rows with unresolved format issues.
          </h4>
        </div>
        <ul className='secondaryTextColor'>
          <li>
            <p>{t('reviewFix')}</p>
            <GenericButton
              id='final-close-cancel'
              title={t('buttons.back')}
              classes={['invert']}
              onClick={() => props.onClick('cancel')}
            />
          </li>
          <li>
            <p>{t('submitAnyway')}</p>
            <GenericButton
              id='final-close-ignore'
              title={t('buttons.continue')}
              classes={['primary']}
              onClick={() => props.onClick('ignore')}
            />
          </li>
        </ul>
      </div>
    )}
  </Translation>
)

const ErrorsPresentOptions = props => (
  <Translation ns='translation'>
    {t => (
      <div className='primaryTextColor'>
        <div className='modal-header'>
          <img src={props.warningIcon} />
          <h4>
            {t('errors.unresolvedFormat', { errorcount: props.errorCount })}
          </h4>
        </div>
        <ul className='secondaryTextColor'>
          <li>
            <p>{t('reviewFix')}</p>
            <GenericButton
              id='final-close-cancel'
              title={t('buttons.back')}
              classes={['invert']}
              onClick={() => props.onClick('cancel')}
            />
          </li>
          <li>
            <p>
              <u>Discard</u> {props.errorCount} rows with issues. Submit the
              rest.
            </p>
            <GenericButton
              id='final-close-ignore'
              title={t('buttons.continue')}
              classes={['primary']}
              onClick={() => props.onClick('ignore')}
            />
          </li>
        </ul>
      </div>
    )}
  </Translation>
)

const ValidSubmitOptions = props => (
  <Translation ns='translation'>
    {t => (
      <div className='primaryTextColor'>
        <div className='modal-header'>
          <img src={props.warningIcon} alt='warning icon' />
          <h4>{t('readySubmit')}</h4>
        </div>
        <ul>
          <li>
            <GenericButton
              id='final-close-cancel'
              title={t('buttons.no')}
              classes={['invert']}
              onClick={() => props.onClick('cancel')}
            />
          </li>
          <li>
            <GenericButton
              id='final-close-include'
              title={t('buttons.yes')}
              classes={['primary']}
              onClick={() => props.onClick('include')}
            />
          </li>
        </ul>
      </div>
    )}
  </Translation>
)
