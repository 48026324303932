import { ITERATOR_MAX_BLOCK_TIME } from '@config'
import { tickWithFrame } from '@utils/frame.safety'
import eachOfSeries from 'async/eachOfSeries'
import mapSeries from 'async/mapSeries'

/**
 * Establish a global variable for indexing iterators
 */
let iteratorCount = 0

/**
 * Iterate through an array and do something
 * @param collection
 * @param callback
 */
export async function asyncForEach<T>(
  collection: T[],
  callback: (value: T, index: number) => Promise<void>
): Promise<void> {
  const trackedCallback = trackCallback(collection.length, callback)
  return eachOfSeries(collection, async (value, index, cb) => {
    await trackedCallback(value, index as number)
    cb(null)
  })
}

/**
 * Iterate through an array and return a new array with mapped values
 * @param collection
 * @param callback
 */
export async function asyncMap<T, R>(
  collection: T[],
  callback: (value: T) => Promise<R>
): Promise<R[]> {
  const trackedCallback = trackCallback(collection.length, callback)
  return mapSeries(collection, async (value, cb) => {
    const result = await trackedCallback(value)
    // tslint:disable-next-line:no-any
    cb(null, result as any)
  })
}

/**
 * Wrapper that will track the results of any promise callback part of an
 * iterator properly
 *
 * @param length
 * @param callback
 */
// tslint:disable-next-line:no-any
export function trackCallback<C extends (...args: any[]) => Promise<any>>(
  length: number,
  callback: C
): C {
  const t0 = performance.now()
  const iteratorId = ++iteratorCount
  let progress = 0
  let isDisplayingBar = false
  let idx = 0
  // tslint:disable-next-line:no-any
  const returnable = async (...args: any): Promise<any> => {
    return new Promise(async resolve => {
      progress = (idx + 1) / length

      if (
        !isDisplayingBar &&
        performance.now() - t0 > ITERATOR_MAX_BLOCK_TIME / 10 &&
        progress <= 0.1
      ) {
        isDisplayingBar = true
      }

      idx++

      tickWithFrame(
        async () => {
          const result = await callback(...args)
          resolve(result)
        },
        isDisplayingBar
          ? () => {
              triggerProgressEvent(iteratorId, progress)
            }
          : undefined,
        progress === 1
      )
    })
  }
  return returnable as C
}

/**
 * Trigger a native window event to tell the React app about the progress
 *
 * @param iteratorId
 * @param progress
 */
async function triggerProgressEvent(iteratorId: number, progress: number) {
  const event = new CustomEvent<IIteratorPayload>('progress-tick', {
    detail: {
      iteratorId,
      progress
    }
  })
  dispatchEvent(event)
}

export interface IIteratorPayload {
  iteratorId: number
  progress: number
}
