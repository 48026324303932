import {
  IColumnMetaDictionary,
  IDefaultColumnMeta,
  IDictionary,
  IImportMeta
} from '@interfaces/general.interface'
import { asyncMap } from '@utils/iterators'
import axios from 'axios'
import FormData from 'form-data'
import jschardet from 'jschardet'
import Papa from 'papaparse'
import pluralize from 'pluralize'
import React, { Component } from 'react'
import Dropzone, { DropzoneOptions, DropzoneRef } from 'react-dropzone'
import { Trans, Translation } from 'react-i18next'

import { ROOT_API_URL } from '@config'
import { IHoTInstance } from '@interfaces/hot.interface'
import {
  IFieldInternal,
  ISettingsInternal,
  IValidatorInternal
} from '@interfaces/internal.settings.interface'
import { IBatchConfig, IFieldOption } from '@interfaces/settings.interface'
import { FooterBrand, GenericButton } from '@lib/elements'
import { checkStatus, updateBatchLog } from '@lib/functions'
import 'font-awesome/css/font-awesome.css'
import App from '../../index'
import StagingManager from '../StagingManager'
import ManualInputTable from './ManualInputTable'
import Spinner from './Spinner'
import UploadWrapper from './UploadWrapper'

type UploaderProps = {
  csvLoad: UploadWrapper['csvLoad']
  updateMeta: App['updateMeta']
  returnUUID: App['returnUUID']
  uploadLogUpdate: UploadWrapper['uploadLogUpdate']
  toggleProgressOverlay: StagingManager['toggleProgressOverlay']
  nextStage: StagingManager['nextStage']
  openModal: UploadWrapper['openModal']
  rewindStage: StagingManager['rewindStage']
  asyncSetState: App['asyncSetState']
  files: File[]
  batchConfig: IBatchConfig
  settings: ISettingsInternal
  accept: string
  defaultColumns: IDefaultColumnMeta[]
  defaultRows: Array<IDictionary<string | boolean>>
  defaultDescriptions: IDictionary
  defaultShortDescriptions: IDictionary<string | undefined>
  defaultValidators: IDictionary<IValidatorInternal[]>
  defaultTypes: IDictionary<IFieldInternal['type']>
  defaultOptions: IDictionary<IFieldOption[]>
  clientID: string
  dataType: ISettingsInternal['type']
  onDrop: DropzoneOptions['onDrop']
}

type UploaderState = {
  settings: ISettingsInternal
  files: File[]
  clientID: string
  batchConfig: IBatchConfig
  accept: string
  initialColumns: IDefaultColumnMeta[]
  initialRows: Array<IDictionary<string | boolean>>
  defaultColumns: IDefaultColumnMeta[]
  defaultDescriptions: IDictionary
  defaultShortDescriptions: IDictionary<string | undefined>
  defaultValidators: IDictionary<IValidatorInternal[]>
  defaultTypes: IDictionary<IFieldInternal['type']>
  defaultOptions: IDictionary<IFieldOption[]>
  dataType: ISettingsInternal['type']
  tableHeight: number
}

export default class Uploader extends Component<UploaderProps, UploaderState> {
  public initialTableRef: React.MutableRefObject<IHoTInstance>
  public dropZoneRef: React.MutableRefObject<DropzoneRef>

  constructor(props: UploaderProps) {
    super(props)
    this.initialTableRef = React.createRef()
    this.dropZoneRef = React.createRef()
    this.isInitialTableEmpty = this.isInitialTableEmpty.bind(this)
    this.submitPastedData = this.submitPastedData.bind(this)
    this.checkRecordsCount = this.checkRecordsCount.bind(this)
    this.state = {
      files: props.files,
      batchConfig: props.batchConfig,
      settings: props.settings,
      accept: props.accept,
      initialColumns: props.defaultColumns.slice(),
      initialRows: props.defaultRows.slice(),
      defaultColumns: props.defaultColumns,
      defaultDescriptions: props.defaultDescriptions,
      defaultShortDescriptions: props.defaultShortDescriptions,
      defaultValidators: props.defaultValidators,
      defaultTypes: props.defaultTypes,
      defaultOptions: props.defaultOptions,
      clientID: props.clientID,
      dataType: props.dataType,
      tableHeight: null
    }

    if (!props.onDrop) {
      throw new Error('<Uploader> props.onDrop must be set')
    }
  }

  public componentWillReceiveProps(nextProps) {
    if (nextProps.files.length > this.state.files.length) {
      this.setState({ files: nextProps.files })
      this.handleFiles(nextProps.files)
    }
  }

  public componentDidMount() {
    this.props.toggleProgressOverlay(false)
  }

  public checkRecordsCount(file) {
    const { maxRecords } = this.state.settings
    if (!maxRecords) {
      this.convertToTextFromCSV(file)
      return
    }

    let numRecords = 0
    const that = this
    Papa.parse(file, {
      step(record, parser) {
        numRecords++
        if (numRecords > maxRecords) {
          parser.abort()
        }
      },
      complete() {
        if (numRecords > maxRecords) {
          that.props.uploadLogUpdate(
            that.state.clientID,
            'File Uploader Button',
            'Rejected due to the number of records',
            file
          )
          that.props.openModal(
            <Translation ns='translation'>
              {t => (
                <span>
                  {t('errors.maxRecords', {
                    maxRecords: maxRecords.toString()
                  })}
                </span>
              )}
            </Translation>
          )
        } else {
          that.convertToTextFromCSV(file)
        }
      }
    })
  }

  public findDuplicatesFromArray(dataArr) {
    const resultArr = []
    dataArr.forEach((element, index) => {
      if (dataArr.indexOf(element, index + 1) > -1) {
        // Find if there is a duplicate or not
        if (resultArr.indexOf(element) === -1) {
          // Find if the element is already in the result array or not
          resultArr.push(element)
        }
      }
    })
    return resultArr
  }

  public checkDuplicatedHeaders(file) {
    let headers = []
    const that = this
    Papa.parse(file, {
      step(record, parser) {
        headers = record.data[0] // first row
        parser.abort()
      },
      complete() {
        const duplicatedHeaders = that.findDuplicatesFromArray(headers)
        if (duplicatedHeaders.length > 0) {
          let headerString
          if (duplicatedHeaders.length === 1) {
            if (duplicatedHeaders[0] === '') {
              duplicatedHeaders.splice(
                0,
                1,
                "Couldn't detect any headers in file"
              )
              headerString = duplicatedHeaders[0]
            } else {
              headerString = duplicatedHeaders[0]
            }
          } else {
            headerString = duplicatedHeaders.join(', ')
          }
          that.props.uploadLogUpdate(
            that.state.clientID,
            'File Uploader Button',
            'Rejected due to the duplicated headers',
            file
          )
          that.props.openModal(
            <Translation ns='translation'>
              {t => (
                <span>{t('errors.dupHeaders', { headers: headerString })}</span>
              )}
            </Translation>
          )
        } else {
          that.checkRecordsCount(file)
        }
      }
    })
  }

  public handleFiles(files) {
    const file = files[0]
    if (window.FileReader) {
      this.postLog(file.name, false, null, null, file)
      if (file.name.toLowerCase().match(/(\.csv)|(\.tsv)$/)) {
        this.checkDuplicatedHeaders(file)
      } else if (file.name.toLowerCase().match(/(\.xls)|(\.xlsx)$/)) {
        this.getCSVFromXLS(file)
      } else {
        this.props.openModal(
          <Translation ns='translation'>
            {t => <span>{t('errors.notSupported')}</span>}
          </Translation>
        )
      }
    } else {
      this.props.uploadLogUpdate(
        this.state.clientID,
        'File Handler',
        'Cannot read file as text due to lack of FileReader in the browser',
        files
      )
      this.props.openModal(
        <Translation ns='translation'>
          {t => <span>{t('errors.fileReader')}</span>}
        </Translation>
      )
    }
  }

  public async saveFileToS3(file, url) {
    axios
      .put(url, file, {
        headers: { 'Content-Type': 'text/csv' },
        responseType: 'text'
      })
      .then(checkStatus)
      .then(async () => {
        const uuid = await this.props.returnUUID()
        axios
          .post(
            `${ROOT_API_URL}/public-api/batches/${uuid}/link-csv`,
            {},
            { headers: { 'License-Key': window.FF_LICENSE_KEY } }
          )
          .then(checkStatus)
          .then(res => {
            this.props.updateMeta({ originalFile: res.data })
          })
          .catch(error => {
            // TODO: add error handling
          })
      })
      .catch(error => {
        // TODO: add error handling
      })
  }

  public async getCSVFromXLS(file) {
    const uuid = await this.props.returnUUID()
    const data = new FormData()
    data.append('attachment', file)
    axios({
      method: 'post',
      url: `${ROOT_API_URL}/public-api/batches/${uuid}/transform-xls`,
      headers: { ...data.getHeaders, 'License-Key': window.FF_LICENSE_KEY },
      data
    })
      .then(checkStatus)
      .then(async response => {
        const csvData = response.data.data
        // const errors = this.validateCSV({data: '', headers: {'content-type': 'something else'}}) // for testing
        const csv = await axios.get(csvData.csv.url)
        const errors = this.validateCSV(csv)
        if (errors.length) {
          throw new Error(errors.join(', '))
        } else {
          this.props.updateMeta({
            originalFile: csvData.original,
            csvFile: csvData.csv
          })
          this.previewCSV(csv.data)
        }
      })
      .catch(error => {
        // TODO: add error handling
        this.props.openModal(
          <Translation ns='translation'>
            {t => (
              <span>
                {t('inconvenience')}
                <br />
                <br />
                <a
                  className='linkColor'
                  target='_blank'
                  href='https://www.ablebits.com/office-addins-blog/2014/04/24/convert-excel-csv/'
                >
                  {t('inconvenienceDocs')} &rarr;
                </a>
              </span>
            )}
          </Translation>
        )
      })
  }

  public validateCSV(response) {
    const errors = []
    if (!response.data.length) {
      errors.push('csv is empty')
    }
    if (
      !/(csv)|(x-comma-separated-values)/.test(response.headers['content-type'])
    ) {
      errors.push('unexpected content-type')
    }
    return errors
  }

  public async convertToTextFromCSV(file) {
    const { defaultFileEncoding } = this.props.settings

    window.URL.revokeObjectURL(file.preview)
    const reader = new window.FileReader()
    reader.onerror = (...args) => this.errorHandler(...args)
    reader.onload = event => {
      this.previewCSV(event.target.result)
    }

    const encodingDetectionResult: {
      confidence: number
      encoding: string
    } = await new Promise(resolve => {
      const encodingReader = new window.FileReader()
      const section = file.slice(0, 10000)

      encodingReader.onload = (event: ProgressEvent<FileReader>) => {
        const array = new Uint8Array(event.target.result as Uint8Array)
        let string = ''
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < array.length; ++i) {
          string += String.fromCharCode(array[i])
        }
        resolve(jschardet.detect(string))
      }

      encodingReader.readAsArrayBuffer(section)
    })

    const detectedEncoding =
      encodingDetectionResult && encodingDetectionResult.confidence > 0.5
        ? encodingDetectionResult.encoding
        : null

    const finalEncoding =
      detectedEncoding || defaultFileEncoding || 'ISO-8859-1'

    this.props.uploadLogUpdate(
      this.state.clientID,
      'Encoding Detection',
      'Detected the following: ' +
        JSON.stringify(encodingDetectionResult) +
        ' and finally used: ' +
        finalEncoding
    )

    // tslint:disable-next-line:no-console
    console.log(
      'Detected the following: ' +
        JSON.stringify(encodingDetectionResult) +
        ' and finally used: ' +
        finalEncoding
    )
    reader.readAsText(file, finalEncoding)
  }

  public errorHandler(evt) {
    this.props.uploadLogUpdate(
      this.state.clientID,
      'FileReader',
      'FileReader cannot read the file: ' + evt.target.error
    )
    if (evt.target.error.name === 'NotReadableError') {
      this.props.openModal(
        <Translation ns='translation'>
          {t => <span>{t('errors.unreadable')}</span>}
        </Translation>
      )
    }
  }

  public previewCSV(rawCSV) {
    Papa.parse(rawCSV, {
      header: false,
      preview: 5,
      skipEmptyLines: true,
      complete: (results, file) => {
        this.submitFile(rawCSV, results)
      }
    })
  }

  public postLog(filename, manual, countRows, countColumns, file = null) {
    const createdAt = new Date().toISOString()
    let data: {
      filename: string
      manual: string
      managed: boolean
      created_at: string
      imported_from_url: string
      count_rows?: number
      count_columns?: number
    } = {
      filename,
      manual,
      managed: this.state.settings.managed,
      created_at: createdAt,
      imported_from_url: document.referrer
    }

    if (countRows && countColumns) {
      data = {
        ...data,
        count_rows: countRows,
        count_columns: countColumns
      }
    }

    this.props.updateMeta(data)
    axios({
      method: 'post',
      url: `${ROOT_API_URL}/public-api/batches`,
      headers: { 'License-Key': window.FF_LICENSE_KEY },
      data
    })
      .then(checkStatus)
      .then(response => {
        if (
          filename.match(/(\.csv)|(\.tsv)$/) &&
          this.state.settings.managed &&
          response.headers['x-signed-upload-url']
        ) {
          this.saveFileToS3(file, response.headers['x-signed-upload-url'])
        }
        return response.data
      })
      .then(uploadData => {
        if (uploadData.errors) {
          // TODO: add error handling
        } else {
          this.props.asyncSetState({ uuid: uploadData.id })
          return uploadData
        }
      })
      .catch(() => {
        // TODO: add error handling
      })
  }

  public submitFile(rawCSV, results) {
    this.props.csvLoad()
    const metaData = {
      count_rows: rawCSV.split(/\r\n|\r|\n/).length,
      count_columns: results.data[0].length
    }
    updateBatchLog(this.props.returnUUID, metaData)
    this.props.updateMeta(metaData as IImportMeta)
    this.props.nextStage({
      rawCSV,
      previewData: results,
      activeStage: 2
    })
  }

  public async submitPastedData() {
    const data = this.initialTableRef.current.hotInstance.getData()
    const { defaultColumns, defaultDescriptions, defaultTypes } = this.state
    const rows = data.reduce((acc, row) => {
      if (Object.keys(row).some(key => row[key])) {
        acc.push(
          defaultColumns.reduce((a, c, i) => {
            a[c.key] = typeof row[i] === 'string' ? row[i] : ''
            return a
          }, {})
        )
      }
      return acc
    }, [])
    const columnMeta: IColumnMetaDictionary[] = await asyncMap(
      defaultColumns,
      async col => {
        return {
          newName: col.key,
          matchState: 'confirmed',
          description: defaultDescriptions[col.key]
        } as IColumnMetaDictionary
      }
    )

    if (rows.length && columnMeta) {
      this.postLog('', true, rows.length, defaultColumns.length)
      this.props.nextStage({
        newData: { rows, columns: defaultColumns },
        activeStage: 4,
        columnMeta
      })
    }
  }

  public isInitialTableEmpty() {
    if (this.initialTableRef.current) {
      return (
        this.initialTableRef.current.hotInstance.countEmptyCols() ===
        this.state.defaultColumns.length
      )
    }
  }

  public setTableHeight = tableHeight => {
    this.setState({ tableHeight })
  }

  public render() {
    const that = this
    const stateAccept = this.state.accept
    return (
      <Translation ns='translation'>
        {t => (
          <div className='uploader-stage'>
            <div className='scroll-block'>
              <h1 className='primary-header'>
                {this.props.settings.title ||
                  t('header2', {
                    thing: pluralize.plural(this.state.dataType)
                  })}
              </h1>

              <Dropzone
                ref={this.dropZoneRef as any}
                onDrop={this.props.onDrop}
                multiple={false}
                accept={this.props.accept}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                  </div>
                )}
              </Dropzone>
              <div className='notice secondaryTextColor'>
                <FileUploader
                  accept={this.state.accept.replace(/,/g, ', ')}
                  onClick={() => {
                    this.dropZoneRef.current.open()
                  }}
                  filesLength={this.state.files.length}
                  settings={this.state.settings}
                />
                <p className='notice-right paragraph secondaryTextColor'>
                  {this.props.settings.instructions ||
                    t('fileTypes', {
                      fileTypes: stateAccept.replace(/,/g, ', ')
                    })}
                </p>
              </div>
              <ManualInputTable
                tableHeight={null}
                {...this.state}
                setTableHeight={this.setTableHeight}
                tableRef={that.initialTableRef}
              />
            </div>
            <div className='controlbar bottom'>
              <GenericButton
                id='cancel'
                classes={['invert']}
                title={t('buttons.clear')}
                onClick={() => {
                  if (this.isInitialTableEmpty() === false) {
                    this.props.rewindStage()
                  }
                }}
              />
              <FooterBrand />
              <GenericButton
                id='continue'
                classes={['primary']}
                title={t('buttons.continue')}
                onClick={() => {
                  if (this.isInitialTableEmpty()) {
                    this.props.openModal(<span>{t('nothingPasted')}</span>)
                  } else {
                    this.submitPastedData()
                  }
                }}
              />
            </div>
          </div>
        )}
      </Translation>
    )
  }
}

export const FileUploader = props => {
  return (
    <div className='file-uploader'>
      {props.filesLength ? (
        <Spinner />
      ) : (
        <>
          <button
            onClick={props.onClick}
            className='button primary icon-upload'
          >
            <Trans i18nKey='dropzone.button' defaults='Upload data from file' />
          </button>
          <Translation ns='translation'>
            {t => (
              <p className='secondaryTextColor'>
                {t('dropzone.accepted', { types: props.accept })}
              </p>
            )}
          </Translation>
        </>
      )}
    </div>
  )
}
