import { DEPLOY_URL } from '@config'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    preload: ['en'],
    nsSeparator: ':',
    whitelist: [
      'en',
      'de',
      'en-CA',
      'en-GB',
      'en-US',
      'es',
      'es-ES',
      'fr',
      'fr-FR',
      'it',
      'ja',
      'ko',
      'nl',
      'pl',
      'pt-BR',
      'sv',
      'th',
      'tr',
      'zh-Hans',
      'zh-Hant',
      'zh-Hant-TW'
    ],
    keySeparator: '.',
    backend: {
      crossDomain: true,
      // for all available options read the backend's repository readme file
      loadPath: `${DEPLOY_URL}/locales/{{lng}}/{{ns}}.json`
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

export default i18n
