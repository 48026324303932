import md5 from 'md5'
import pluralize from 'pluralize'
import React, { Component } from 'react'
import { Translation } from 'react-i18next'

import {
  IColumnMeta,
  ICsvData,
  IDefaultColumnMeta,
  IDictionary,
  IKeyNameMap,
  UUIDResolver
} from '@interfaces/general.interface'
import {
  IFieldInternal,
  ISettingsInternal,
  IValidatorInternal
} from '@interfaces/internal.settings.interface'
import { IFieldOption, ISettings } from '@interfaces/settings.interface'
import { FooterBrand, GenericButton, ProgressHeader } from '@lib/elements'
import {
  convertToLetters,
  processData,
  storageAvailable,
  updateBatchLog
} from '@lib/functions'
import App from '../../index'
import ParseErrors from '../parse/parseErrors'
import StagingManager from '../StagingManager'
import Spinner from '../upload/Spinner'
import UploadWrapper from '../upload/UploadWrapper'
import ColBody from './colBody'
import { MatcherType } from './colBody/fieldOptions'
import Matcher from './matcher'
import { DevBanner } from '@components/DevBanner'

type ColumnMatchProps = {
  csvData: ICsvData
  columnMeta: IColumnMeta
  keyNames: IKeyNameMap
  defaultValidators: IDictionary<IValidatorInternal[]>
  defaultDescriptions: IDictionary
  defaultColumns: IDefaultColumnMeta[]
  defaultOptions: IDictionary<IFieldOption[]>
  defaultTypes: IDictionary<IFieldInternal['type']>
  settings: ISettings
  header: boolean
  parentHasValidator: boolean
  rewindStage: () => void
  updateMeta: App['updateMeta']
  toggleProgressOverlay: StagingManager['toggleProgressOverlay']
  openModal: UploadWrapper['openModal']
  nextStage: StagingManager['nextStage']
  csvDataUpdate: StagingManager['csvDataUpdate']
  returnUUID: App['returnUUID']
  configHash?: () => void
  activeStage: number
  allowCustom: boolean
  handshake: Promise<any> // tslint:disable-line:no-any
  dataType: ISettingsInternal['type']
}

type ColumnMatchState = {
  columnMeta: IColumnMeta
  csvData: ICsvData
  header: boolean
  keyNames: IKeyNameMap
  customColumns: string[]
  dataType: ISettingsInternal['type']
  defaultColumns: IDefaultColumnMeta[]
  settings: ISettings
  columnFill: object
  activeStage: number
  columnOptions: string[]
  allowCustom: boolean
  defaultValidators: IDictionary<IValidatorInternal[]>
  defaultDescriptions: IDictionary
  matcher: MatcherType
  matcherIsLoading: boolean
}

export default class ColumnMatch extends Component<
  ColumnMatchProps,
  ColumnMatchState
> {
  public rewindStage: () => void
  public updateMeta: (param: object) => void
  public toggleProgressOverlay: (param: boolean) => void
  public openModal: UploadWrapper['openModal']
  public nextStage: (param: object) => void
  public csvDataUpdate: (param: ICsvData) => void
  public returnUUID: UUIDResolver
  public configHash: () => void

  constructor(props: ColumnMatchProps) {
    super(props)
    const columnFill = {}
    for (let i = 0, c = 0; i < props.csvData.meta.fields.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < props.csvData.data.length; j++) {
        if (props.csvData.data[j][props.csvData.meta.fields[i]] !== '') {
          c++
        }
      }
      columnFill[props.csvData.meta.fields[i]] =
        Math.round((c / props.csvData.data.length) * 100) + '%'
      c = 0
    }

    this.initializeMatcher()
    this.rewindStage = props.rewindStage.bind(this)
    this.updateMeta = props.updateMeta.bind(this)
    this.toggleProgressOverlay = props.toggleProgressOverlay.bind(this)
    this.openModal = props.openModal.bind(this)
    this.nextStage = props.nextStage.bind(this)
    this.csvDataUpdate = props.csvDataUpdate.bind(this)
    this.submitDataToValidation = this.submitDataToValidation.bind(this)
    this.returnUUID = props.returnUUID.bind(this)
    this.configHash = md5(JSON.stringify(props.settings.fields))

    this.state = {
      header: props.header,
      settings: props.settings,
      columnMeta: null,
      csvData: props.csvData,
      columnFill,
      activeStage: props.activeStage,
      defaultValidators: props.defaultValidators,
      defaultDescriptions: props.defaultDescriptions,
      defaultColumns: props.defaultColumns,
      allowCustom: props.allowCustom,
      customColumns: null,
      columnOptions: null,
      keyNames: null,
      dataType: props.dataType,
      matcher: null,
      matcherIsLoading: true
    }
  }

  public componentWillReceiveProps(nextProps) {
    if (nextProps.columnMeta !== this.state.columnMeta) {
      this.setState({ columnMeta: nextProps.columnMeta })
    }

    if (nextProps.csvData.errors.length !== this.state.csvData.errors.length) {
      this.toggleProgressOverlay(false)
      this.setState({ csvData: nextProps.csvData })
    }
  }

  public componentDidMount() {
    this.toggleProgressOverlay(false)
  }

  public async initializeMatcher() {
    const { props } = this

    await new Matcher({
      setState: state => this.setState(state),
      columnMeta: props.columnMeta,
      handshake: this.props.handshake,
      parentHasValidator: this.props.parentHasValidator,
      keyNames: props.keyNames,
      defaultValidators: props.defaultValidators,
      defaultDescriptions: props.defaultDescriptions,
      defaultColumns: props.defaultColumns,
      defaultTypes: props.defaultTypes,
      defaultOptions: props.defaultOptions,
      settings: props.settings,
      csvData: props.csvData,
      header: props.header
    })
  }

  public rememberMeta() {
    if (storageAvailable('localStorage')) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.state.columnMeta.length; i++) {
        const obj = {
          newName: this.state.columnMeta[i].newName,
          matchState: this.state.columnMeta[i].matchState,
          optionsMap: this.state.columnMeta[i].optionsMap
        }
        window.localStorage.setItem(
          this.configHash + '-' + md5(this.state.columnMeta[i].oldName),
          JSON.stringify(obj)
        )
      }
    }
  }

  public async submitDataToValidation() {
    const { newData, headersMatched } = await processData(
      this.state.header,
      this.state.columnMeta,
      this.state.csvData,
      this.state.keyNames,
      this.props.defaultTypes,
      this.props.defaultOptions
    )
    const usedCustomColumns = []
    if (this.state.customColumns) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.state.customColumns.length; i++) {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < newData.columns.length; j++) {
          if (newData.columns[j].key === this.state.customColumns[i]) {
            usedCustomColumns.push(this.state.customColumns[i])
          }
        }
      }
    }
    this.rememberMeta()
    updateBatchLog(this.returnUUID, {
      headers_matched: headersMatched,
      count_columns_matched: newData.columns.length,
      matched_at: new Date().toISOString()
    })
    this.updateMeta({
      headers_matched: headersMatched,
      count_columns_matched: newData.columns.length
    })
    this.nextStage({
      newData,
      columnMeta: this.state.columnMeta,
      keyNames: this.state.keyNames,
      usedCustomColumns,
      activeStage: 4
    })
  }

  public render() {
    if (this.state.matcherIsLoading) {
      return <Spinner />
    }

    if (this.state.csvData.errors.length && this.state.csvData.errors[0].type) {
      return (
        <ParseErrors
          csvData={this.state.csvData}
          columnMeta={this.state.columnMeta}
          openModal={this.openModal}
          rewindStage={this.rewindStage}
          dataType={this.state.dataType}
          header={this.state.header}
          csvDataUpdate={this.csvDataUpdate}
          settings={this.props.settings}
        />
      )
    } else {
      const duplicateColumns =
        this.state.columnMeta &&
        this.state.columnMeta.reduce((a, v, i) => {
          if (v.duplicate) {
            a.push(convertToLetters(i + 1))
          }
          return a
        }, [])
      const requiredKeysUnmatched =
        this.state.defaultColumns &&
        this.state.columnMeta &&
        this.state.defaultColumns
          .reduce((a, c) => {
            if (
              c.validators.find(v => v.validate === 'required') !== undefined
            ) {
              a.push(c.key)
            }
            return a
          }, [])
          .filter(
            key =>
              this.state.columnMeta.find(c => c.newName === key) === undefined
          )
      const reviewFunction =
        duplicateColumns && duplicateColumns.length
          ? () => {
              this.openModal(
                'Sorry, there are a couple of columns matched to duplicate fields:\n' +
                  duplicateColumns.join(', ')
              )
            }
          : requiredKeysUnmatched && requiredKeysUnmatched.length
          ? () => {
              this.openModal(
                'Sorry, there are a couple required fields unmatched: please check the keys:\n' +
                  requiredKeysUnmatched.join(', ')
              )
            }
          : this.submitDataToValidation

      const bodies = this.state.csvData.meta.fields.map((field, index) => (
        <ColBody
          key={index}
          index={index}
          matcher={this.state.matcher}
          columnMeta={this.state.columnMeta}
          column={this.state.csvData.meta.fields[index]}
          columnFill={this.state.columnFill}
          header={this.state.header}
          columnOptions={this.state.columnOptions}
          allowCustom={this.state.allowCustom}
          keyNames={this.state.keyNames}
          csvData={this.state.csvData}
        />
      ))
      return (
        <Translation ns='translation'>
          {t => (
            <div className='column-match-stage'>
              <div className='scroll-block'>
                <div className='controlbar top'>
                  <h1 className='primary-header'>
                    {this.props.settings.title ||
                      t('header', {
                        number: this.state.csvData.data.length,
                        thing: pluralize(
                          this.state.dataType,
                          this.state.csvData.data.length
                        )
                      })}
                  </h1>

                  <ProgressHeader stage={this.state.activeStage} />
                </div>
                <div className='column-edit-table'>{bodies}</div>
              </div>
              <div className='controlbar bottom'>
                <GenericButton
                  id='cancel'
                  title={t('buttons.back')}
                  classes={['invert']}
                  onClick={this.rewindStage}
                />
                <FooterBrand />
                <GenericButton
                  id='continue'
                  title={t('buttons.review')}
                  classes={['primary']}
                  onClick={reviewFunction}
                />
              </div>
            </div>
          )}
        </Translation>
      )
    }
  }
}
