import { ICsvData } from '@interfaces/general.interface'
import { IHoTColumn } from '@interfaces/hot.interface'
import { ParseError } from 'papaparse'
import { IParsingErrorAction } from './parseErrors'

export default class ErrorRowsParser {
  /**
   * List of parsing errors as returned from PapaParse
   */
  public errors: ParseError[]

  /**
   * List of columns to start with (additional columns will be appended)
   * @todo this shouldn't be part of this abstraction
   */
  public columns: IHoTColumn[]

  /**
   * The data object
   */
  public csvData: ICsvData

  constructor(errors: ParseError[], columns: IHoTColumn[], csvData: ICsvData) {
    this.errors = errors
    this.columns = columns
    this.csvData = csvData
  }

  /**
   * Get a list of parsing errors and messages related to the rows they
   */
  public getRows(): IParsingErrorAnalysis[] {
    return this.errors.reduce(
      (rowsBuild, currentError, currentIndex, errors) => {
        if (typeof currentError.row === 'undefined') {
          // if the error is non-row-specific, ignore it
          return rowsBuild
        }
        const csvRowWithError = currentError.row // get what row of the CSV the current error is referring to
        const lastIndex = rowsBuild.length - 1 // get the index of the last added row to rowsBuild
        if (
          lastIndex > -1 &&
          rowsBuild[lastIndex].csvRow === csvRowWithError + 1
        ) {
          // if it's not the first element and the current error refers to the same error as the previous one
          rowsBuild[lastIndex].error = this.updateRowErrorMessage(
            currentError.message,
            rowsBuild[lastIndex].error
          )
        } else {
          rowsBuild.push(this.newRow(csvRowWithError, currentIndex))
        }
        return rowsBuild
      },
      [] as IParsingErrorAnalysis[]
    )
  }

  public updateRowErrorMessage(newError: string, currentError: string) {
    const regex = new RegExp(newError + '(?: \\((\\d+)X times\\))?') // eslint-disable-line no-useless-escape
    const results = regex.exec(currentError) // check if this error has already been applied to this row
    if (results) {
      let [, duplicateCount]: any[] = results
      duplicateCount = duplicateCount ? parseInt(duplicateCount) + 1 : 2 // get the number of times this error has been applied to this row
      const replaceRegex = new RegExp(newError + '( \\((\\d+)X times\\))?') // eslint-disable-line no-useless-escape
      return currentError.replace(
        replaceRegex,
        newError + ' (' + duplicateCount + 'X times)'
      )
    } else {
      return currentError + ', ' + newError
    }
  }

  public newRow(
    csvRowWithError: number,
    currentErrorIndex: number
  ): IParsingErrorAnalysis {
    return this.columns.reduce((row, value, index) => {
      switch (value.key) {
        case 'action':
          return { ...row, action: 'INCLUDE' }
        case 'csvRow':
          return { ...row, csvRow: csvRowWithError + 1 }
        case 'error':
          return { ...row, error: this.errors[currentErrorIndex].message }
        default:
          return {
            ...row,
            [value.key]: this.csvData.data[csvRowWithError][value.key] || ''
          }
      }
    }, {} as IParsingErrorAnalysis)
  }
}

export interface IParsingErrorAnalysis {
  csvRow: number
  error: string
  action: IParsingErrorAction
  __parsed_extra: string
  [key: string]: any
}
