import React, { Component, createRef } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { render } from 'react-dom'
import HotTable from 'react-handsontable'
import { Translation } from 'react-i18next'

import { HOT_LICENSE_KEY } from '@config'
import { IDefaultColumnMeta, IDictionary } from '@interfaces/general.interface'
import { IHoTInstance } from '@interfaces/hot.interface'
import {
  IFieldInternal,
  ISettingsInternal,
  IValidatorInternal
} from '@interfaces/internal.settings.interface'
import {
  IFieldOption,
  IFieldOptionDictionary
} from '@interfaces/settings.interface'
import Uploader from './index'
import Handsontable from 'handsontable-pro'
import tippy from 'tippy.js'

type ManualInputTableProps = {
  settings: ISettingsInternal
  initialRows: Array<IDictionary<string | boolean>>
  initialColumns: IDefaultColumnMeta[]
  defaultValidators: IDictionary<IValidatorInternal[]>
  defaultTypes: IDictionary<IFieldInternal['type']>
  defaultOptions: IDictionary<IFieldOption[]>
  defaultDescriptions: IDictionary
  defaultShortDescriptions: IDictionary<string | undefined>
  tableHeight: number
  setTableHeight: Uploader['setTableHeight']
  tableRef: React.MutableRefObject<IHoTInstance>
}

export default class ManualInputTable extends Component<
  ManualInputTableProps,
  {}
> {
  public tableViewportRef: React.RefObject<HTMLInputElement>
  public columnWidths: number[]

  constructor(props: ManualInputTableProps) {
    super(props)
    this.tableViewportRef = createRef()
    Handsontable.renderers.registerRenderer(
      'ff.manualInput',
      this.customRenderer.bind(this)
    )
  }

  public customRenderer(
    hotInstance,
    td,
    row,
    column,
    prop,
    value,
    cellProperties
  ) {
    Handsontable.renderers.BaseRenderer.apply(this, arguments)
    const type = this.props.defaultTypes[prop]
    if (type === 'checkbox') {
      td.classList.remove('htInvalid')
      if (/^(1|yes|true|on|enabled)$/i.test(value)) {
        value = true
        arguments[5] = value
        Handsontable.renderers.CheckboxRenderer.apply(this, arguments)
      } else if (/^(0|no|false|off|disabled)$/i.test(value)) {
        value = false
        arguments[5] = value
        Handsontable.renderers.CheckboxRenderer.apply(this, arguments)
      } else if (value !== '' && value !== null) {
        td.classList.add('htInvalid')
        td.innerHTML = `<span class="htInvalid-value">${value}</span>`
        const tipContainer = document.createElement('div')
        tipContainer.innerText = 'must be boolean (true/false, yes/no, etc.)'
        tippy('.htInvalid-value', {
          content: tipContainer,
          placement: 'top',
          distance: -3
        })
      } else {
        td.innerHTML = ''
      }
    } else if (type === 'select') {
      Handsontable.renderers.DropdownRenderer.apply(this, arguments)
    } else {
      td.innerHTML = value
    }
    return td
  }

  public render() {
    const {
      settings,
      initialRows,
      initialColumns,
      defaultValidators,
      defaultTypes,
      defaultOptions,
      defaultDescriptions,
      defaultShortDescriptions,
      tableHeight,
      setTableHeight,
      tableRef
    } = this.props

    if (settings.disableManualInput === true) {
      return null
    }

    let initialColSize = 160

    if (this.tableViewportRef.current) {
      initialColSize = Math.max(
        120,
        Math.floor(
          this.tableViewportRef.current.clientWidth / initialColumns.length
        )
      )
    }

    const virtualSize = initialColumns.reduce(
      (totalSize, column) => totalSize + (column.sizeHint || 1),
      0
    )

    const scaleFactor = initialColumns.length / virtualSize // i.e. inverse of average sizeHint

    this.columnWidths = initialColumns
      // .slice(0, -1)
      .map(
        column =>
          Math.max(scaleFactor * initialColSize, 120) * (column.sizeHint || 1)
      )

    return (
      <Translation ns='translation'>
        {t => (
          <div className='manual-input-table'>
            <h2
              className='secondary-header'
              defaultValue='...or just manually enter your data here:'
            >
              {t('manual')}
            </h2>
            <div className='handsontable-container' ref={this.tableViewportRef}>
              <HotTable
                root='hot'
                ref={tableRef}
                settings={{
                  licenseKey: HOT_LICENSE_KEY,
                  data: initialRows,
                  renderer: 'ff.manualInput',
                  colHeaders: index => {
                    if (index < initialColumns.length) {
                      return '<div></div>'
                    }
                  },
                  colWidths: this.columnWidths,
                  afterGetColHeader: (index, TH) => {
                    if (index < initialColumns.length) {
                      const colKey = initialColumns[index].key
                      const isRequired =
                        defaultValidators[colKey].findIndex(
                          v => v.validate === 'required'
                        ) > -1

                      const description =
                        defaultShortDescriptions[colKey] ||
                        defaultDescriptions[colKey]

                      let tooltip
                      if (defaultShortDescriptions[colKey]) {
                        tooltip = defaultDescriptions[colKey]
                      } else {
                        tooltip = ''
                      }

                      const hotInstanceExists =
                        description &&
                        this.props.tableRef.current &&
                        this.props.tableRef.current.hotInstance

                      const colHeight = this.getColHeight(description, index)

                      const headerContent = this.getHeaderContent(
                        hotInstanceExists,
                        isRequired,
                        initialColumns,
                        index,
                        colHeight,
                        tooltip,
                        description
                      )

                      const headerNode = this.getHeaderNode(
                        tooltip,
                        headerContent
                      )
                      const newHeaderCell = document.createElement('div')

                      while (TH.firstChild.firstChild.firstChild) {
                        TH.firstChild.firstChild.removeChild(
                          TH.firstChild.firstChild.firstChild
                        )
                      }

                      TH.firstChild.firstChild.appendChild(newHeaderCell)

                      render(headerNode, newHeaderCell)
                    }
                  },
                  columns: index => {
                    if (index < initialColumns.length) {
                      const key = initialColumns[index].key
                      if (defaultTypes[key] === 'select') {
                        let options = defaultOptions[key]

                        if (typeof options[0] === 'object') {
                          options = options.map(
                            (o: IFieldOptionDictionary) => o.label
                          )
                        }
                        return {
                          data: key,
                          allowInvalid: true,
                          readOnly: false,
                          editor: 'select',
                          selectOptions: options
                        }
                      } else {
                        return {
                          data: key,
                          allowInvalid: true,
                          readOnly: false
                        }
                      }
                    }
                  },
                  afterChange: (changes, source) => {
                    if (
                      this.props.tableRef.current &&
                      this.props.tableRef.current &&
                      changes
                    ) {
                      const { hotInstance } = this.props.tableRef.current
                      const rowCount = hotInstance.countRows()
                      if (rowCount - 1 === changes[0][0]) {
                        hotInstance.alter('insert_row')
                        const updatedTableHeight = tableHeight + 40
                        setTableHeight(updatedTableHeight)
                      }
                    }
                  },
                  modifyColWidth: (width, col) => {
                    return width < 40 ? 40 : width
                  },
                  height: tableHeight,
                  copyPaste: true,
                  manualColumnResize: true,
                  manualRowResize: true,
                  allowRemoveRow: true,
                  observeChanges: true,
                  undo: true,
                  rowHeights: '15px',
                  wordWrap: false,
                  stretchH: 'last',
                  contextMenu: [
                    'row_above',
                    'row_below',
                    'remove_row',
                    'undo',
                    'redo'
                  ],
                  renderAllRows: true,
                  preventOverflow: 'horizontal'
                }}
              />
            </div>
          </div>
        )}
      </Translation>
    )
  }

  public getColHeight(description, index) {
    return `${13 *
      Math.floor((description.length * 13) / this.columnWidths[index])}px`
  }

  public getHeaderContent(
    hotInstanceExists,
    isRequired,
    initialColumns,
    index,
    colHeight,
    tooltip,
    description
  ) {
    if (!hotInstanceExists) {
      return (
        <Translation ns='translation'>
          {t => (
            <span
              className={isRequired ? 'col-required' : ''}
              data-i18n={t('required')}
            >
              {initialColumns[index].name}
            </span>
          )}
        </Translation>
      )
    }

    const maxWidth =
      Math.min(
        this.columnWidths[index],
        this.props.tableRef.current.hotInstance.getColWidth(index)
      ) + 'px'

    return (
      <Translation ns='translation'>
        {t => (
          <span className='col-desc-wrapper'>
            <div
              className={isRequired ? 'col-required' : ''}
              data-i18n={t('required')}
            >
              {initialColumns[index].name}
            </div>
            <div className='col-desc secondaryTextColor' title={description}>
              <span className={tooltip ? 'bottom-dotted' : ''}>
                {description}
              </span>
            </div>
          </span>
        )}
      </Translation>
    )
  }

  public getHeaderNode(tooltip, headerContent) {
    if (tooltip) {
      return (
        <div className='rendered'>
          <HeaderTooltip tooltip={tooltip}>{headerContent}</HeaderTooltip>
        </div>
      )
    } else {
      return (
        <div className='rendered'>
          <span className='htTooltip'>{headerContent}</span>
        </div>
      )
    }
  }
}

const HeaderTooltip = props => (
  <OverlayTrigger
    placement='top'
    overlay={<Tooltip id={`tooltip`}>{props.tooltip}</Tooltip>}
  >
    <span className='htTooltip'>{props.children}</span>
  </OverlayTrigger>
)
