import axios, { AxiosResponse } from 'axios'
import { isPlainObject, uniq } from 'lodash'

import { ROOT_API_URL } from '@config'
import {
  IColumnMeta,
  ICsvData,
  IKeyNameMap,
  IKeyOptionsMap,
  IKeyTypeMap,
  IOptionMatches,
  IProcessedData,
  IServerBatch,
  IStringDictionary,
  UUIDResolver
} from '@interfaces/general.interface'
import { IFieldOption } from '@interfaces/settings.interface'
import { asyncForEach } from '@utils/iterators'

/**
 * Insert a string into the dom and measure it's actual pixel length
 *
 * @param string
 */
export function getRealStringLength(string: string) {
  const element = document.createElement('span')
  element.appendChild(document.createTextNode(string))
  element.classList.add('char-ruler')
  document.getElementsByClassName('flatfile-root')[0].appendChild(element)
  const length = element.offsetWidth + 28
  const parentNode: Node = element.parentNode as Node
  parentNode.removeChild(element)
  return length
}

/**
 * Given a string estimate the height of the text wrapped at a given width
 *
 * @param string
 * @param width
 */
export function getRealElementHeight(string: string, width: number): number {
  const element = document.createElement('span')
  element.appendChild(document.createTextNode(string))
  element.classList.add('char-height-ruler')
  element.style.width = `${width}px`
  document.getElementsByClassName('flatfile-root')[0].appendChild(element)
  const height = element.offsetHeight
  const parentNode: Node = element.parentNode as Node
  parentNode.removeChild(element)
  return height
}

/**
 * Delete elements from
 * @param className
 */
export function removeElementsByClass(className: string): void {
  const elements = document.getElementsByClassName(className)
  while (elements.length > 0) {
    const parentNode: Node = elements[0].parentNode as Node
    parentNode.removeChild(elements[0])
  }
}

/**
 * Determine if local storage is available
 *
 * @todo replace this with an npm package?
 * @param type
 */
export function storageAvailable(type: 'localStorage'): boolean {
  const storage: Storage = window[type]
  try {
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return (
      e instanceof DOMException && // eslint-disable-line no-undef
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage.length !== 0
    )
  }
}

/**
 * Validate an email string with regex
 *
 * @param value
 * @param callback
 */
export function emailValidator(
  value: string,
  callback: (res: boolean) => void
): void {
  callback(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      // eslint-disable-line no-useless-escape
      value
    )
  )
}

/**
 * Convert a number into an XLS style column letter
 * eg. 27 => AA
 *
 * @param number
 */
export function convertToLetters(number: number): string {
  const baseChar = 'A'.charCodeAt(0)
  let letters = ''
  do {
    number -= 1
    letters = String.fromCharCode(baseChar + (number % 26)) + letters
    // tslint:disable-next-line:no-bitwise
    number = (number / 26) >> 0
  } while (number > 0)
  return letters
}

/**
 * Check the status of an http response and throw an error if it's messed up
 *
 * @param response
 */
export function checkStatus<T extends AxiosResponse>(response: T): T {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    const error = new Error(response.statusText)
      // tslint:disable-next-line:no-any
    ;(error as any).response = response
    throw error
  }
}

/**
 * Update the server with batch history
 *
 * @param returnUUID
 * @param updates
 */
export async function updateBatchLog(
  returnUUID: UUIDResolver,
  updates: Partial<IServerBatch>
): Promise<IServerBatch> {
  const uuid = await returnUUID()
  return (
    axios({
      method: 'patch',
      url: `${ROOT_API_URL}/public-api/batches/${uuid}`,
      headers: { 'License-Key': window.FF_LICENSE_KEY },
      data: updates
    })
      .then(checkStatus)
      .then(response => {
        return response.data
      })
      .then(data => {
        return data.data
      })
      /* eslint-disable-next-line handle-callback-err */
      .catch(_error => {
        // TODO: add error handling
      })
  )
}

/**
 * Get a list of potential option matches for a select type column
 *
 * @todo streaming problematic
 * @param csvData
 * @param name
 * @param options
 */
export function getDefaultMatches(
  csvData: ICsvData,
  name: string,
  options: IFieldOption[]
): IOptionMatches {
  const data = uniq(csvData.data.map(row => row[name]))
  if (data.length > options.length * 3) {
    return {}
  }
  return data.reduce((acc: IOptionMatches, realValue): IOptionMatches => {
    const v = (realValue || '').toLowerCase()
    const guess = options.find(opt => {
      if (typeof opt === 'string') {
        if (opt.toLowerCase() === v) {
          return true
        }
      } else if (typeof opt === 'object') {
        if (typeof opt.value === 'string' && opt.value.toLowerCase() === v) {
          return true
        }
        if (opt.value === v) {
          return true
        }
        if (opt.label.toLowerCase() === v) {
          return true
        }
      }
      return false
    })
    if (guess) {
      acc[realValue] = guess
    }
    return acc
  }, {})
}

/**
 * Determine if any of the options in a given row are not matched
 *
 * @todo streaming problematic
 * @param csvData
 * @param name
 * @param optionsMap
 */
export function hasUnmatchedSelectOption(
  csvData: ICsvData,
  name: string,
  optionsMap: IOptionMatches
): boolean {
  const data = uniq(csvData.data.map(row => row[name]))
  return data.some(d => !optionsMap[d])
}

/**
 * Crossbrowser compatible method to determine the height of the screen
 * @todo use utility dep
 */
export function getWindowHeight(): number {
  return (
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  )
}

/**
 * Crossbrowser compatible method to determine the width of the screen
 * @todo use utility dep
 */
export function getWindowWidth(): number {
  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  )
}

/**
 * Utility to round a number to a specific precision and still return a float
 * @param number
 * @param precision
 */
export function precisionRound(number: number, precision: number): number {
  const factor = Math.pow(10, precision)
  return Math.round(number * factor) / factor
}

/**
 *
 * @param header
 * @param columnMeta
 * @param csvData
 * @param keyNames
 * @param defaultTypes
 * @param defaultOptions
 */
export async function processData(
  header: boolean,
  columnMeta: IColumnMeta,
  csvData: ICsvData,
  keyNames: IKeyNameMap,
  defaultTypes: IKeyTypeMap,
  defaultOptions: IKeyOptionsMap
): Promise<IProcessedData> {
  const newData: IProcessedData['newData'] = {
    rows: [],
    columns: []
  }
  const headersMatched = []

  if (csvData.meta) {
    for (let i = 0; i < csvData.meta.fields.length; i++) {
      if (
        !columnMeta[i].duplicate &&
        (columnMeta[i].matchState === 'confirmed' ||
          columnMeta[i].matchState === 'matched')
      ) {
        const columnName = columnMeta[i].newName
        newData.columns.push({
          key: columnName,
          name: keyNames[columnName],
          type: defaultTypes[columnName],
          options: defaultOptions[columnName],
          validators: columnMeta[i].validators
        })
        headersMatched.push({
          index: i,
          value: header ? columnMeta[i].oldName : null,
          matched_key: columnName
        })
      }
    }
  }

  if (csvData.data) {
    await asyncForEach(csvData.data, async (values, idx) => {
      const row: IStringDictionary = {}
      for (const col of columnMeta) {
        if (
          !col.duplicate &&
          (col.matchState === 'confirmed' || col.matchState === 'matched')
        ) {
          row[col.newName] = header
            ? csvData.data[idx][col.oldName]
            : csvData.data[idx][col.suggestedName]
          if (col.optionsMap) {
            const mappedNewOption = col.optionsMap[row[col.newName]]
            if (mappedNewOption) {
              if (typeof mappedNewOption === 'object') {
                // TODO: investigate whether this should actually be returning "value" instead of "label"
                row[col.newName] = mappedNewOption.label
              } else {
                row[col.newName] = mappedNewOption
              }
            }
          }
        }
      }

      newData.rows.push(row)
    })
  }

  return { newData, headersMatched }
}

// tslint:disable-next-line:no-any
export function isObject<T extends object>(val: any): val is T {
  return isPlainObject(val)
}
