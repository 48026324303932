import { HOT_LICENSE_KEY } from '@config'
import {
  ICsvData,
  IDefaultColumnMeta,
  IDictionary,
  IImportMeta,
  IServerRawHeader
} from '@interfaces/general.interface'
import {
  IFieldInternal,
  ISettingsInternal,
  IValidatorInternal
} from '@interfaces/internal.settings.interface'
import { IFieldOption } from '@interfaces/settings.interface'
import { FooterBrand, GenericButton } from '@lib/elements'
import {
  convertToLetters,
  getDefaultMatches,
  storageAvailable,
  updateBatchLog
} from '@lib/functions'
import 'font-awesome/css/font-awesome.css'
import Fuse from 'fuse.js'
import md5 from 'md5'
import Papa, { ParseResult } from 'papaparse'
import pluralize from 'pluralize'
import React, { Component } from 'react'
import HotTable from 'react-handsontable'
import { Translation } from 'react-i18next'
import App from '../../index'
import StagingManager from '../StagingManager'

type HeaderMatchProps = {
  nextStage: StagingManager['nextStage']
  returnUUID: App['returnUUID']
  updateMeta: App['updateMeta']
  toggleProgressOverlay: StagingManager['toggleProgressOverlay']
  rewindStage: StagingManager['rewindStage']
  previewData: ParseResult
  rawCSV: string
  defaultColumns: IDefaultColumnMeta[]
  defaultValidators: IDictionary<IValidatorInternal[]>
  defaultDescriptions: IDictionary
  dataType: ISettingsInternal['type']
  shouldRememberHeader: boolean
  settings: ISettingsInternal
  defaultTypes: IDictionary<IFieldInternal['type']>
  defaultOptions: IDictionary<IFieldOption[]>
}

type HeaderMatchState = {
  previewData: ParseResult
  rawCSV: string
  fuzziness: number
  defaultColumns: IDefaultColumnMeta[]
  defaultValidators: IDictionary<IValidatorInternal[]>
  defaultDescriptions: IDictionary
  shouldRememberHeader: boolean
  settings: ISettingsInternal
  dataType: string
}

export default class HeaderMatch extends Component<
  HeaderMatchProps,
  HeaderMatchState
> {
  public previewTableRef: React.MutableRefObject<HTMLElement>

  constructor(props: HeaderMatchProps) {
    super(props)
    this.previewTableRef = React.createRef()
    this.fuzzyColumnData = this.fuzzyColumnData.bind(this)
    this.defaultColumnData = this.defaultColumnData.bind(this)
    this.state = {
      previewData: props.previewData,
      rawCSV: props.rawCSV,
      fuzziness: props.settings.fuzziness,
      settings: props.settings,
      defaultColumns: props.defaultColumns,
      defaultValidators: props.defaultValidators,
      defaultDescriptions: props.defaultDescriptions,
      dataType: props.dataType,
      shouldRememberHeader: props.shouldRememberHeader
    }
  }

  public clearAllRefs() {
    this.previewTableRef.current = null
  }

  public componentWillUnmount() {
    this.clearAllRefs()
  }

  public componentWillMount() {
    if (this.state.shouldRememberHeader) {
      const key = md5(JSON.stringify(this.state.previewData.data[0]))
      if (
        storageAvailable('localStorage') &&
        typeof window.localStorage[key] !== 'undefined'
      ) {
        if (window.localStorage[key] === 'true') {
          this.fuzzyColumnData()
        } else if (window.localStorage[key] === 'false') {
          this.defaultColumnData()
        }
      }
    } else {
      this.setState({ shouldRememberHeader: true })
    }
  }

  public componentDidMount() {
    this.props.toggleProgressOverlay(false)
  }

  public defaultColumnData() {
    Papa.parse(this.state.rawCSV, {
      header: false,
      skipEmptyLines: true,
      complete: results => {
        const columnMeta = []
        const fields = []
        for (let i = 0; i < results.data[0].length; i++) {
          const suggestion = this.state.defaultColumns[i]
            ? this.state.defaultColumns[i].key
            : convertToLetters(i + 1)
          const validators = this.state.defaultColumns[i]
            ? this.state.defaultValidators[this.state.defaultColumns[i].key]
            : []
          const description = this.state.defaultColumns[i]
            ? this.state.defaultDescriptions[this.state.defaultColumns[i].key]
            : ''
          const type = this.state.defaultColumns[i]
            ? this.props.defaultTypes[this.state.defaultColumns[i].key]
            : ''
          const options = this.state.defaultColumns[i]
            ? this.props.defaultOptions[this.state.defaultColumns[i].key]
            : ''
          columnMeta.push({
            oldName: convertToLetters(i + 1),
            newName: suggestion,
            suggestedName: suggestion,
            isGenerated: true,
            duplicate: false,
            validators,
            description,
            type,
            options,
            matchState:
              suggestion === convertToLetters(i + 1) ? 'unmatched' : 'matched'
          })
          fields.push(suggestion)
        }
        results.meta.fields = fields
        const csvData = { ...results }
        csvData.data = []
        for (let i = 0, row = {}; i < results.data.length; i++) {
          for (let j = 0; j < columnMeta.length; j++) {
            row[columnMeta[j].suggestedName] = results.data[i][j]
          }
          csvData.data.push(row)
          row = {}
        }
        this.rememberHeader(false)
        const data: IImportMeta = {
          imported_from_url: document.referrer,
          header_hash: md5(this.state.rawCSV.split(/\r\n|\r|\n/, 1)[0]),
          parsing_config: results.meta
        }
        this.props.updateMeta(data)
        updateBatchLog(this.props.returnUUID, data)
        this.props.nextStage({
          columnMeta,
          csvData,
          header: false,
          activeStage: 3
        })
      }
    })
  }

  public fuzzyColumnData() {
    Papa.parse(this.state.rawCSV, {
      header: true,
      skipEmptyLines: true,
      complete: (results: ICsvData) => {
        const columnMeta = []
        const headersRaw: IServerRawHeader[] = []
        const matchedFields = {}
        const searchOptions = {
          threshold: this.state.fuzziness,
          location: 0,
          distance: 100,
          maxPatternLength: 32,
          minMatchCharLength: 1,
          keys: ['label', 'key', 'alternates']
        }
        const fuse = new Fuse(this.state.settings.fields, searchOptions)
        for (let i = 0; i < results.meta.fields.length; i++) {
          const searchResults: IFieldInternal[] = fuse.search(
            results.meta.fields[i]
          )
          let suggestion = 'none'
          let validators = []
          let description = ''
          let type = ''
          let options = []
          let optionsMap = {}
          if (
            searchResults.length &&
            typeof matchedFields[searchResults[0].key] === 'undefined'
          ) {
            suggestion = searchResults[0].key
            validators = this.state.defaultValidators[searchResults[0].key]
            description = this.state.defaultDescriptions[searchResults[0].key]
            matchedFields[searchResults[0].key] = true
            type = searchResults[0].type
            options = searchResults[0].options
            optionsMap =
              type === 'select'
                ? getDefaultMatches(results, results.meta.fields[i], options)
                : {}
          }
          columnMeta.push({
            oldName: results.meta.fields[i],
            newName: suggestion,
            suggestedName: suggestion,
            duplicate: false,
            validators,
            description,
            type,
            options,
            optionsMap,
            matchState: suggestion === 'none' ? 'unmatched' : 'matched'
          })
          headersRaw.push({
            index: i,
            letter: convertToLetters(i + 1),
            value: results.meta.fields[i]
          })
        }
        this.rememberHeader(true)
        const data = {
          headers_raw: headersRaw,
          imported_from_url: document.referrer,
          header_hash: md5(this.state.rawCSV.split(/\r\n|\r|\n/, 1)[0]),
          parsing_config: results.meta
        }
        this.props.updateMeta(data)
        updateBatchLog(this.props.returnUUID, data)
        this.props.nextStage({
          columnMeta,
          csvData: results,
          header: true,
          activeStage: 3
        })
      }
    })
  }

  public rememberHeader(header) {
    if (storageAvailable('localStorage')) {
      const key = md5(JSON.stringify(this.state.previewData.data[0]))
      window.localStorage.setItem(key, header)
    }
  }

  public render() {
    return (
      <Translation ns='translation'>
        {t => (
          <div className='header-match-stage'>
            <div className='scroll-block'>
              <div className='controlbar top'>
                <h1 className='primary-header'>
                  {this.props.settings.title ||
                    t('header2', { thing: pluralize(this.state.dataType) })}
                </h1>
              </div>
              <div className='header-row-select'>
                <aside className='primaryTextColor'>
                  <h4>{t('columnHeader')}</h4>
                  <GenericButton
                    id='headerConfirm'
                    title={t('buttons.yes')}
                    classes={['success']}
                    onClick={() => {
                      this.fuzzyColumnData()
                    }}
                  />
                  <GenericButton
                    id='headerlessConfirm'
                    title={t('buttons.no')}
                    classes={['dark']}
                    onClick={() => {
                      this.defaultColumnData()
                    }}
                  />
                </aside>
                <HotTable
                  root='hot'
                  ref={this.previewTableRef}
                  settings={{
                    licenseKey: HOT_LICENSE_KEY,
                    colHeaders: true,
                    rowHeaders: true,
                    columns: () => ({ readOnly: true }),
                    modifyColWidth: width => (width < 40 ? 40 : width),
                    data: this.state.previewData.data,
                    stretchH: 'all',
                    rowHeights: '15px',
                    wordWrap: false,
                    preventOverflow: 'horizontal'
                  }}
                />
              </div>
            </div>
            <div className='controlbar bottom'>
              <GenericButton
                id='cancel'
                title={t('buttons.back')}
                classes={['invert']}
                onClick={() => this.props.rewindStage()}
              />
              <FooterBrand />
            </div>
          </div>
        )}
      </Translation>
    )
  }
}
