import { round } from 'lodash'
import React from 'react'

import {
  IColumnMeta,
  ICsvData,
  IKeyNameMap
} from '@interfaces/general.interface'
import { GenericButton } from '@lib/elements'
import { convertToLetters } from '@lib/functions'
import { Translation } from 'react-i18next'
import { MatcherType } from './fieldOptions'

type ConfirmMapProps = {
  columnMeta: IColumnMeta
  index: number
  keyNames: IKeyNameMap
  matcher: MatcherType
  csvData: ICsvData
  columnFill: object
  allowCustom: boolean
  header?: boolean
}

const confirmMap: React.FC<ConfirmMapProps> = (props: ConfirmMapProps) => {
  const {
    oldName,
    newName,
    validators,
    matchState,
    duplicate,
    description,
    suggestedName
  } = props.columnMeta[props.index]

  const defaultName =
    oldName === convertToLetters(props.index + 1) ? newName : oldName
  const label = (props.keyNames && props.keyNames[newName]) || newName
  const priorMatchList = []
  let priorMatch = ''
  if (props.columnMeta) {
    for (let i = 0; i < props.columnMeta.length; i++) {
      if (
        props.columnMeta[i].newName === newName &&
        i !== props.index &&
        props.columnMeta[i].matchState !== 'ignored'
      ) {
        priorMatchList.push(convertToLetters(i + 1))
      }
    }
  }

  if (priorMatchList) {
    priorMatch =
      priorMatchList.length > 1
        ? 'Columns ' + priorMatchList.join(', ') + ' have'
        : 'Column ' + priorMatchList[0] + ' has'
  }
  let validationReport = null
  if (
    props.matcher &&
    props.matcher.columnsInvalid &&
    validators.length &&
    !duplicate
  ) {
    if (props.matcher.columnsInvalid[newName] === 0) {
      validationReport = (
        <Translation>
          {t => (
            <li>
              <i className='fa fa-check successColor' />
              {t('validationPass')}
            </li>
          )}
        </Translation>
      )
    } else {
      const invalidColumns = round(
        (props.matcher.columnsInvalid[newName] / props.csvData.data.length) *
          100,
        1
      )
      validationReport = (
        <Translation ns='translation'>
          {t => (
            <li>
              <i className='fa fa-warning' />
              {t('rowsFail', { failed: invalidColumns })}
            </li>
          )}
        </Translation>
      )
    }
  }
  if (matchState === 'matched') {
    const duplicateWarning = duplicate ? (
      <Translation ns='translation'>
        {t => (
          <li>
            <i className='fa fa-warning' />
            {t('alreadyMatched', { priorMatch, label })}
          </li>
        )}
      </Translation>
    ) : null
    return (
      <Translation ns='translation'>
        {t => (
          <aside className={`column-matched${duplicate ? ' duplicate' : ''}`}>
            <ul>
              <li>
                <i className='fa fa-check successColor' />
                {t('autoMatchedField1')}
                <span className='suggested-fieldname primaryTextColor'>
                  {label}
                </span>
                {t('autoMatchedField2')}
                {description ? (
                  <div className='field-description secondaryTextColor'>
                    {description}
                  </div>
                ) : null}
              </li>
              {duplicateWarning}
              <li>
                <i className='fa fa-info-circle' />
                {t('rowsHaveValue', { rows: props.columnFill[defaultName] })}
              </li>
              {validationReport}
            </ul>
            <div className='confirm-box'>
              <GenericButton
                id={'confirmed-' + props.index}
                classes={['success']}
                tabIndex={(props.index + 1) * 3 + 1}
                title={t('buttons.confirmMapping')}
                onClick={() => props.matcher.confirmMatch(props.index)}
              />
              <GenericButton
                id={'ignored-' + props.index}
                classes={['invert']}
                tabIndex={(props.index + 1) * 3 + 2}
                title={t('buttons.ignore')}
                onClick={() => props.matcher.ignoreColumn(props.index)}
              />
            </div>
          </aside>
        )}
      </Translation>
    )
  } else if (matchState === 'confirmed') {
    return (
      <Translation ns='translation'>
        {t => (
          <aside className='column-confirmed'>
            <ul>
              <li>
                <i className='fa fa-check successColor' />
                {t('confirmedMapping')}
              </li>
            </ul>
            <GenericButton
              id={'matched-' + props.index}
              classes={['gray']}
              tabIndex={(props.index + 1) * 3 + 1}
              title={t('buttons.edit')}
              onClick={() => props.matcher.undoConfirm(props.index)}
            />
          </aside>
        )}
      </Translation>
    )
  } else if (matchState === 'ignored') {
    return (
      <Translation ns='translation'>
        {t => (
          <aside className='column-ignored'>
            <ul>
              <li>
                <i className='fa fa-eye-slash' />
                {t('ignored')}
              </li>
            </ul>
            <GenericButton
              id={'unmatched-' + props.index}
              classes={['gray']}
              tabIndex={(props.index + 1) * 3 + 1}
              title={t('buttons.edit')}
              onClick={() => props.matcher.undoMatch(props.index)}
            />
          </aside>
        )}
      </Translation>
    )
  } else if (matchState === 'unmatched') {
    return (
      <Translation ns='translation'>
        {t => (
          <aside className='column-unmatched'>
            <ul>
              <li>
                <i className='fa fa-warning' />
                {suggestedName === 'none'
                  ? t('unableToMatch')
                  : t('noMatchSelected', {
                      suggestedMatch: props.keyNames[suggestedName]
                    })}
              </li>
              <li>
                <i className='fa fa-info-circle' />
                {t('rowsHaveValue', { rows: props.columnFill[defaultName] })}
              </li>
            </ul>
            <div className='confirm-box'>
              <GenericButton
                id={'ignored-' + props.index}
                classes={['invert']}
                tabIndex={(props.index + 1) * 3 + 1}
                title={t('buttons.ignore')}
                onClick={() => props.matcher.ignoreColumn(props.index)}
              />
              {props.allowCustom && (
                <GenericButton
                  id={'custom-' + props.index}
                  classes={['gray']}
                  tabIndex={(props.index + 1) * 3 + 2}
                  title={t('buttons.customField')}
                  onClick={() =>
                    props.matcher.changeColumnMatch(props.index, oldName)
                  }
                />
              )}
            </div>
          </aside>
        )}
      </Translation>
    )
  } else if (matchState === 'locked') {
    return (
      <Translation ns='translation'>
        {t => (
          <aside className={`column-locked${duplicate ? ' duplicate' : ''}`}>
            <ul>
              {description ? (
                <li>
                  <i className='fa fa-info-circle' />
                  {description}
                </li>
              ) : null}
              <li>
                <i className='fa fa-warning' />
                {t('alreadyMatched', { priorMatch: priorMatch, label: label })}
              </li>
              <li>
                <i className='fa fa-info-circle' />
                {t('rowsHaveValue', {
                  rows: props.columnFill[defaultName]
                })}
              </li>
              {validationReport}
            </ul>
            <div className='confirm-box'>
              <GenericButton
                id={'confirmed-' + props.index}
                tabIndex={(props.index + 1) * 3 + 1}
                classes={['gray']}
                title={t('buttons.confirm')}
              />
              <GenericButton
                id={'ignored-' + props.index}
                tabIndex={(props.index + 1) * 3 + 2}
                classes={['invert']}
                title={t('buttons.ignore')}
                onClick={() => props.matcher.ignoreColumn(props.index)}
              />
            </div>
          </aside>
        )}
      </Translation>
    )
  }
}

export default confirmMap
