import {
  IImportMeta,
  IServerEndUser,
  UUIDResolver
} from '@interfaces/general.interface'
import {
  IFeatures,
  ISettingsInternal
} from '@interfaces/internal.settings.interface'
import {
  IBatchConfig,
  IEndUser,
  ISettings
} from '@interfaces/settings.interface'
import { CloseButton } from '@lib/elements'
import { updateBatchLog } from '@lib/functions'
import i18next from 'i18next'
import React, { Component, RefObject } from 'react'
import { Style } from 'react-style-tag'
import 'whatwg-fetch'
import Settings from './lib/settings'
import UploadWrapper from './stages/upload/UploadWrapper'
import CustomStyles from './styles'

// this must be last due to CSS priority, do not sort above other imports
import 'theme/styles/index.scss'

export default class App extends Component<IAppProps, IAppState> {
  public readonly state: Readonly<IAppState> = {
    importMeta: {},
    settings: undefined,
    uuid: undefined,
    isOpen: false,
    modalLoaderIsOpen: false,
    parentHasValidator: false,
    modalErrorIsOpen: false,
    modalSuccessIsOpen: false,
    modalErrorContent: '',
    settingErrors: []
  }
  private readonly uploadWrapperRef: RefObject<UploadWrapper>
  private chunkCallback?: () => void
  // tslint:disable-next-line:no-any
  private readonly handshake: Promise<any>
  private readonly features: IFeatures

  constructor(props: IAppProps) {
    super(props)
    this.uploadWrapperRef = React.createRef()
    this.handshake = props.handshake
    this.features = props.features || {}
  }

  public componentDidMount() {
    this.handshake.then(parent => {
      parent.ready().then((settings: ISettings) => {
        this.loadSettings({ detail: settings })
      })
    })
  }

  public updateMeta = (newMeta: IImportMeta): IImportMeta => {
    const importMeta = { ...this.state.importMeta, ...newMeta }
    this.setState({ importMeta })
    return importMeta
  }

  /**
   * Horrible method that allows child elements to setState in parent
   * @param newState
   * @deprecated
   */
  // @ts-ignore
  public asyncSetState = newState => {
    this.setState(newState)
  }

  /**
   * Promise that waits for UUID from server
   * @param force
   */
  public returnUUID: UUIDResolver = async (force: boolean = false) => {
    if (!this.state.uuid && force) {
      return '00000000-0000-0000-0000-000000000000'
    }
    return new Promise(resolve => {
      const checkUUID = () => {
        if (this.state.uuid) {
          this.updateMeta({ batchID: this.state.uuid })
          return resolve(this.state.uuid)
        }
        setTimeout(checkUUID, 50)
      }
      checkUUID()
    })
  }

  /**
   * Initiated by adapter
   * @param srcUser
   */
  public setUser = async (srcUser: IEndUser) => {
    const { email, name, userId, companyId, companyName } = srcUser
    const newEndUser: IServerEndUser = {
      email,
      name,
      user_id: userId !== undefined ? userId.toString() : undefined,
      company_name: companyName,
      company_id: companyId !== undefined ? companyId.toString() : undefined
    }
    const { end_user_id: endUserId } = await updateBatchLog(this.returnUUID, {
      end_user: newEndUser
    })
    const endUser = { ...srcUser, id: endUserId }
    this.updateMeta({ endUser })
  }

  /**
   * Initiated by adapter
   */
  public open = (batchConfig: IBatchConfig = {}) => {
    this.setState({ isOpen: true, batchConfig })
  }

  /**
   * Initiated by adapter
   */
  public parentHasValidator = () => {
    this.setState({ parentHasValidator: true })
  }

  /**
   * Initiated by adapter
   */
  public close = () => {
    this.setState({
      importMeta: {},
      isOpen: false,
      modalLoaderIsOpen: false,
      modalErrorIsOpen: false,
      modalSuccessIsOpen: false,
      modalErrorContent: ''
    })
    if (
      this.uploadWrapperRef.current &&
      this.uploadWrapperRef.current.stagerRef.current
    ) {
      this.uploadWrapperRef.current.stagerRef.current.clearData()
    }
    this.handshake.then(parent => {
      parent.close()
    })
  }

  /**
   * Initiated by adapter
   * @param msg
   */
  public displayLoader = (msg: string) => {
    this.setState({ modalLoaderIsOpen: true, loadingMessage: msg })
  }

  /**
   * Initiated by adapter
   */
  public nextChunk = () => {
    if (this.chunkCallback) {
      return this.chunkCallback()
    }
  }

  /**
   * Initiated by adapter
   * @param msg
   */
  public displaySuccess = (msg = 'Success!') => {
    const handledAt = new Date().toISOString()
    updateBatchLog(this.returnUUID, { handled_at: handledAt })
    this.updateMeta({ handledAt })
    this.setState({
      modalSuccessIsOpen: true,
      successMessage: msg,
      modalLoaderIsOpen: false,
      loadingMessage: undefined
    })
  }

  public render() {
    if (this.state.settings && this.state.isOpen) {
      return (
        <div>
          <CustomStyles
            styles={
              this.features.styleOverride
                ? this.state.settings.styleOverrides || {}
                : {}
            }
          />
          {this.state.settings.integrations &&
          this.state.settings.integrations.adobeFontsWebProjectId ? (
            <link
              rel='stylesheet'
              type='text/css'
              href={`https://use.typekit.net/${this.state.settings.integrations.adobeFontsWebProjectId}.css`}
            />
          ) : null}
          {this.features.injectCss && (
            <Style>{this.state.settings.injectCss}</Style>
          )}
          <UploadWrapper
            ref={this.uploadWrapperRef}
            registerChunkCallback={this.registerChunkCallback}
            handshake={this.handshake}
            returnUUID={this.returnUUID}
            asyncSetState={this.asyncSetState}
            settings={this.state.settings}
            close={this.close}
            batchConfig={this.state.batchConfig as IBatchConfig}
            updateMeta={this.updateMeta}
            parentHasValidator={this.state.parentHasValidator}
            modalLoaderIsOpen={this.state.modalLoaderIsOpen}
            loadingMessage={this.state.loadingMessage || ''}
            modalSuccessIsOpen={this.state.modalSuccessIsOpen}
            successMessage={this.state.successMessage || ''}
            modalErrorIsOpen={this.state.modalErrorIsOpen}
            modalErrorContent={this.state.modalErrorContent}
            modalErrorDismiss={this.dismissError}
            plan={this.features}
          />
        </div>
      )
    } else if (this.state.settings) {
      return (
        <div className='presettings-default'>
          <span className='loading-indicator'>
            <i className='fa fa-spinner fa-pulse fa-3x fa-fw' />
            <h1 className='sr-only'>{'Loading...'}</h1>
          </span>
        </div>
      )
    } else if (this.state.settingErrors.length) {
      const errors = this.state.settingErrors.map(e => {
        return <li>{e}</li>
      })
      return (
        <div className='presettings-default'>
          <CloseButton onClick={this.close} />
          <div className='config-errors'>
            <h1 className='primary-header'>Your settings are invalid</h1>
            <ul>{errors}</ul>
          </div>
        </div>
      )
    } else {
      return (
        <div className='presettings-default'>
          <span className='loading-indicator'>
            <i className='fa fa-spinner fa-pulse fa-3x fa-fw' />
            <h1 className='sr-only'>{'Loading...'}</h1>
          </span>
        </div>
      )
    }
  }

  /**
   * Initiated by external event from adapter
   * @param error
   */
  public displayError = (error: string) => {
    const failedAt = new Date().toISOString()
    updateBatchLog(this.returnUUID, {
      failed_at: failedAt,
      failure_reason: error
    })
    this.updateMeta({ status: 'failure', failedAt })
    this.setState({
      modalErrorIsOpen: true,
      modalErrorContent: error,
      modalLoaderIsOpen: false,
      loadingMessage: undefined
    })
  }

  private registerChunkCallback = (cb: () => void) => {
    this.chunkCallback = cb
  }

  private dismissError = () => {
    this.setState({
      modalErrorIsOpen: false,
      modalErrorContent: '',
      modalLoaderIsOpen: false,
      loadingMessage: undefined
    })
  }

  private loadSettings(event: { detail: ISettings }) {
    const s = new Settings(event.detail)
    if (s.validate()) {
      const overrideLanguages = Object.keys(s.settings.i18nOverrides)
      overrideLanguages.forEach(lang =>
        i18next.addResourceBundle(
          lang,
          'translation',
          s.settings.i18nOverrides[lang]
        )
      )
      this.updateMeta({ config: s.settings })
      this.setState({ settings: { ...s.settings, features: this.features } })
    } else {
      this.setState({ settingErrors: s.errors })
    }
  }
}

interface IAppProps {
  // tslint:disable-next-line:no-any
  handshake: Promise<any>
  features: IFeatures
  license: { key?: string; error?: string }
}

interface IAppState {
  importMeta: IImportMeta
  settings?: ISettingsInternal
  uuid?: string
  isOpen: boolean
  modalLoaderIsOpen: boolean
  modalErrorIsOpen: boolean
  parentHasValidator: boolean
  modalSuccessIsOpen: boolean
  modalErrorContent: string
  settingErrors: string[]
  loadingMessage?: string
  successMessage?: string
  batchConfig?: IBatchConfig
}
