import IValidationResponse, {
  IDictionary,
  INumberDictionary,
  IPrimitive,
  IProcessedColumnMeta,
  IStringDictionary
} from '@interfaces/general.interface'
import { IValidatorInternal } from '@interfaces/internal.settings.interface'
import { IFieldOption } from '@interfaces/settings.interface'
import { asyncForEach } from '@utils/iterators'
import { uniq } from 'lodash'

export type IRowDictionary = IDictionary<string | IWhatTheFuckCell>

export default class Validate {
  /**
   * TODO: make this non public
   */
  public errorRows: boolean[]

  /**
   * TODO: make this non public
   */
  public rows: IRowDictionary[]

  /**
   * TODO: make this non public
   */
  public columns: IProcessedColumnMeta[]

  /**
   * TODO: make this non public
   */
  public columnsInvalid: INumberDictionary = {}

  public userOverrides: IValidatedRow[] = []

  private readonly handshake?: Promise<any> // tslint:disable-line:no-any

  private errorMessages: string[] = []

  private errors: number[][][] = []

  constructor(
    rows: IRowDictionary[],
    columns: IProcessedColumnMeta[],
    handshake?: Promise<any> // tslint:disable-line:no-any
  ) {
    this.errorRows = Array(rows.length).fill(true)
    this.rows = rows
    this.columns = columns
    this.handshake = handshake
  }

  /**
   * @todo this seems unnecessary as it's only used in tests
   * @param rows
   */
  public resetValidationState(rows: IRowDictionary[]) {
    this.errorRows = Array(rows.length).fill(true)
  }

  /**
   * Validates the entire set of data and counts the number of errors
   * that have occurred in each column.
   */
  public async validateTable(): Promise<number> {
    const t0 = window.performance.now()
    this.errorRows = Array(this.rows.length).fill(true)
    await this.validateState(this.rows)
    return window.performance.now() - t0
  }

  public getRowValidation(index: number): undefined | IValidatedRow {
    if (!this.rows[index]) {
      return undefined
    }
    return this.expandCachedValidationState(this.formatRow(index), index)
  }

  /**
   * TODO: report typescript bug, rowValid is treated as type: true below
   * @param row
   * @param i
   */
  public async validateRow(
    row: IValidatedRow,
    i: number
  ): Promise<IValidatedRow> {
    let rowValid: boolean = true
    let newRow: IValidatedRow = row.map(
      (cell: IValidatedCell, j: number): IValidatedCell => {
        cell.errors = this.validateCell(i, j, cell.value)
        if (cell.errors.length) {
          rowValid = false
        }
        return cell
      }
    )
    if (this.handshake) {
      const data = this.rows[i]
      const result = await this.parentValidatorCallback(
        data as IStringDictionary
      )
      const keys = Object.keys(data)
      newRow = newRow.map(cell => {
        const errors: string[] = result
          .filter(err => keys.indexOf(err.key) === cell.col)
          .map(err => err.message)
        if (errors.length) {
          rowValid = false
        }
        const newErrors = [...cell.errors, ...errors]
        return { ...cell, errors: newErrors }
      })
    }
    this.errorRows[i] =
      (typeof row[0].value === 'object' ? row[0].value.deleted : false) ||
      rowValid

    this.cacheValidationState(newRow, i)
    return newRow
  }

  public validateCell(
    row: number,
    col: number,
    value: IValidatedCell['value']
  ) {
    const column = this.columns[col]
    const validators: IValidatorInternal[] = column.validators
    if (
      !validators.some(v =>
        [
          'required',
          'required_without',
          'required_without_all',
          'required_with_all',
          'required_with'
        ].some(r => r === v.validate)
      ) &&
      value === ''
    ) {
      return []
    } else {
      return validators.reduce((errors, validator) => {
        let isValid = true
        switch (validator.validate) {
          case 'regex_matches':
            isValid =
              value && typeof value === 'string'
                ? value.match(validator.regex) !== null
                : true
            break
          case 'regex_excludes':
            isValid =
              value && typeof value === 'string'
                ? value.match(validator.regex) === null
                : true
            break
          case 'required':
            isValid = value !== ''
            break
          case 'required_without_all':
            isValid =
              value !== '' ||
              validator.fields.some(
                v =>
                  typeof this.rows[row][v] !== 'undefined' &&
                  this.rows[row][v] !== ''
              )
            break
          case 'required_without':
            isValid =
              value !== '' ||
              validator.fields.every(
                v =>
                  typeof this.rows[row][v] !== 'undefined' &&
                  this.rows[row][v] !== ''
              )
            break
          case 'required_with_all':
            isValid =
              value !== '' ||
              !validator.fields.every(
                v =>
                  typeof this.rows[row][v] !== 'undefined' &&
                  this.rows[row][v] !== ''
              )
            break
          case 'required_with':
            isValid =
              value !== '' ||
              validator.fields.every(
                v =>
                  typeof this.rows[row][v] === 'undefined' ||
                  this.rows[row][v] === ''
              )
            break
          case 'select':
            if (value) {
              isValid =
                column.type === 'select'
                  ? (column.options as IFieldOption[]).find(o =>
                      typeof o === 'string' ? o === value : o.label === value
                    ) !== undefined
                  : false
            } else {
              isValid = true
            }
            break
          default:
          // do nothing
        }
        if (isValid) {
          return errors
        } else {
          errors.push(
            validator.error ||
              this.defaultErrorMessage(validator) ||
              'Failed validation'
          )
          return errors
        }
      }, [] as string[])
    }
  }

  public async updateCell(
    rowIdx: number,
    colIdx: number,
    value: string | IWhatTheFuckCell
  ): Promise<void> {
    const originalRow = this.formatRow(rowIdx)
    originalRow[colIdx].value = value
    this.userOverrides[rowIdx] = originalRow
    await this.updateRow(rowIdx)
  }

  private async validateState(rows: IRowDictionary[]): Promise<void> {
    this.columnsInvalid = {}

    await asyncForEach(rows, async (row: IRowDictionary, idx: number) => {
      const validatedRow: IValidatedRow = await this.validateRow(
        this.formatRow(idx),
        idx
      )
      validatedRow.forEach((vCell: IValidatedCell) => {
        const key = this.columns[vCell.col].key
        const increment = vCell.errors.length ? 1 : 0
        this.columnsInvalid[key] = (this.columnsInvalid[key] || 0) + increment
      })
    })
  }

  private cacheValidationState(row: IValidatedRow, idx: number) {
    const errors = row.reduce((acc, cell) => {
      return [...acc, ...cell.errors]
    }, [] as string[])
    const map = this.getErrorMap(uniq(errors))
    this.errors[idx] = row.reduce((acc, cell, cellIdx) => {
      if (cell.errors.length) {
        return { ...acc, [cellIdx]: cell.errors.map(err => map[err]) }
      }
      return acc
    }, [] as number[][])
  }

  private expandCachedValidationState(
    row: IValidatedRow,
    idx: number
  ): IValidatedRow {
    const errs = this.errors[idx] || []
    return row.map((cell, cellIdx) => {
      const cellErrs = errs[cellIdx] || []
      const errors = cellErrs.map(
        errIdx => this.errorMessages[errIdx] || 'Invalid'
      )
      return { ...cell, errors }
    })
  }

  private getErrorMap(errors: string[]): INumberDictionary {
    return errors.reduce((acc, err) => {
      let idx = this.errorMessages.findIndex(msg => msg === err)
      if (idx === -1) {
        this.errorMessages.push(err)
        idx = this.errorMessages.length - 1
      }
      return { ...acc, [err]: idx }
    }, {} as INumberDictionary)
  }

  private formatRow(idx: number): IValidatedRow {
    if (this.userOverrides[idx]) {
      return this.userOverrides[idx]
    }

    const row = this.rows[idx]

    return Object.keys(row).map(
      (key, col): IValidatedCell => ({ value: row[key], errors: [], col })
    )
  }

  private defaultErrorMessage(validator: IValidatorInternal): string | void {
    switch (validator.validate) {
      case 'required':
        return `Required.`
      case 'required_without':
        return `Required when ${this.naturalJoin(
          validator.fields,
          true
        )} empty.`
      // if any of these values are empty, this is required.
      case 'required_without_all':
        return `Required when ${this.naturalJoin(
          validator.fields,
          false
        )} empty.`
      // if all of these are empty, then this is required.
      case 'required_with_all':
        return `Required when ${this.naturalJoin(
          validator.fields,
          false
        )} filled.`
      // if every of these values is not empty, this is required.
      case 'required_with':
        return `Required when ${this.naturalJoin(
          validator.fields,
          true
        )} filled.`
      // if any one of the fields is not empty, this required.
    }
  }

  private naturalJoin(fields: string[], any: boolean = false): string {
    const joinTerm = any ? 'or' : 'and'
    if (fields.length === 1) {
      return `${fields[0]} is`
    } else if (fields.length === 2) {
      return `${fields.join(` ${joinTerm} `)} are`
    } else if (fields.length > 2) {
      const last = fields.pop()
      return `${fields.join(', ')} ${joinTerm} ${last} are`
    }
    return ''
  }

  private async updateRow(index: number): Promise<void> {
    await this.validateRow(this.formatRow(index), index)
  }

  private parentValidatorCallback = async (
    row: IDictionary<IPrimitive>
  ): Promise<IValidationResponse[]> => {
    if (!this.handshake) {
      return []
    }
    const parent = await this.handshake
    const result = await parent.validatorCallback(row)
    return result || []
  }
}

interface IValidatedRow extends Array<IValidatedCell> {}

interface IValidatedCell {
  value: string | IWhatTheFuckCell
  col: number
  errors: string[]
}

/**
 * TODO: for some reason table.jsx:~208 changes values in the the first column to this
 */
interface IWhatTheFuckCell {
  index: number
  deleted: boolean
}
