export const HOT_LICENSE_KEY: string | null =
  process.env.HOT_LICENSE_KEY || null

export const ROOT_API_URL: string | null = process.env.ROOT_API_URL || null

export const SENTRY_CONFIG: {
  dsn?: string
  release?: string
  enabled: boolean
  debug: boolean
  environment: string
} = {
  dsn: process.env.SENTRY_DSN,
  release: process.env.SENTRY_RELEASE,
  enabled: process.env.SENTRY_ENABLED === 'true',
  debug: process.env.SENTRY_DEBUG === 'true',
  environment: process.env.SENTRY_ENVIRONMENT || 'local'
}

/**
 * Maximum amount of time to hold the progress bar before completing
 * This is to prevent broken loops from leaving the progress bar running
 */
export const ITERATOR_MAX_SILENCE = parseInt(
  process.env.ITERATOR_MAX_SILENCE || '3000',
  10
)

/**
 * Maximum amount of time to allow the event loop to run without a progress bar
 */
export const ITERATOR_MAX_BLOCK_TIME = parseInt(
  process.env.ITERATOR_MAX_BLOCK_TIME || '500',
  10
)

/**
 * Enforced FPS for iterator
 */
export const ITERATOR_FPS = parseInt(process.env.ITERATOR_FPS || '60', 10)

export const DEPLOY_URL: string =
  process.env.CONTEXT === 'production'
    ? process.env.URL
    : process.env.DEPLOY_URL || ''
