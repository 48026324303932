import React from 'react'

import {
  IColumnMeta,
  ICsvData,
  IKeyNameMap
} from '@interfaces/general.interface'
import { convertToLetters } from '@lib/functions'
import FieldOptions, { MatcherType } from './fieldOptions'

type TopRowProps = {
  columnMeta: IColumnMeta
  index: number
  matcher: MatcherType
  header: boolean
  columnOptions: string[]
  keyNames: IKeyNameMap
  allowCustom: boolean
  csvData?: ICsvData
}

export default function(props: TopRowProps) {
  const nameIndex = 'edit-column-name-' + props.index
  const columnName =
    convertToLetters(props.index + 1) === props.columnMeta[props.index].oldName
      ? ''
      : props.columnMeta[props.index].oldName
  return (
    <thead>
      <tr>
        <th>{convertToLetters(props.index + 1)}</th>
        <th>{columnName}</th>
        <th>
          <span
            className={'unmatch-toggle-wrapper'}
            onClick={() => props.matcher.undoMatch(props.index)}
          >
            <UnmatchToggle
              unmatched={props.columnMeta[props.index].newName === 'none'}
            />
          </span>
          <FieldOptions
            matcher={props.matcher}
            columnName={props.columnMeta[props.index].newName}
            originalName={props.columnMeta[props.index].oldName}
            defaultName={props.columnMeta[props.index].newName}
            header={props.header}
            index={props.index}
            columnOptions={props.columnOptions}
            keyNames={props.keyNames}
            allowCustom={props.allowCustom}
            nameIndex={nameIndex}
          />
        </th>
      </tr>
    </thead>
  )
}

const UnmatchToggle = ({
  onClick,
  unmatched
}: {
  onClick?: () => void
  unmatched: boolean
}) => {
  if (unmatched) {
    return <i className='fa fa-search' />
  }
  return (
    <button className='unmatch-toggle' onClick={onClick}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
        <path
          fill='#000'
          fillRule='nonzero'
          d='M94.3 0c-1.5 0-3 .6-4 1.7L50 42 9.8 1.6c-2.3-2.3-6-2.3-8 0-2.4 2.2-2.4 5.8 0 8l40 40.3L2 90.2c-2.4 2.3-2.4 6 0 8 1 1.2 2.5 1.8 4 1.8 1.4 0 3-.6 4-1.7L50 58l40.2 40.3c1 1 2.6 1.7 4 1.7 1.5 0 3-.6 4-1.7 2.4-2.2 2.4-5.8 0-8L58.3 50 98 9.8c2.4-2.3 2.4-6 0-8-1-1.2-2.5-1.8-4-1.8z'
        />
      </svg>
    </button>
  )
}
