import { saveAs } from 'file-saver'
import Papa from 'papaparse'
import prettyBytes from 'pretty-bytes'
import React, { Component, LegacyRef, ReactNode, useLayoutEffect } from 'react'

// tslint:disable-next-line:no-any
Papa.LocalChunkSize = 1e6 as any

const downloadIconStyle = {
  fontSize: '19px',
  verticalAlign: '-2px',
  marginLeft: '-4px',
  marginRight: '5px',
  color: '#60B700'
}

const LineItem = ({
  children,
  style,
  scrollIntoView
}: {
  children: ReactNode
  style: object
  scrollIntoView: boolean
}) => {
  const itemRef: LegacyRef<HTMLDivElement> = React.createRef()

  useLayoutEffect(() => {
    if (scrollIntoView) {
      const el = itemRef.current

      if (el && el.scrollIntoView) {
        el.scrollIntoView({
          block: 'end',
          inline: 'nearest',
          behavior: 'smooth'
        })
      }
    }
  })

  return (
    <div style={style} ref={itemRef}>
      {children}
    </div>
  )
}

const DownloadableFileStatusRow = ({ name, isDownloaded, isDownloading }) => (
  <LineItem
    scrollIntoView={isDownloading}
    style={{
      padding: '8px 10px',
      backgroundColor: isDownloaded ? '#EFF7E4' : 'none',
      fontSize: '95%'
    }}
  >
    {isDownloaded ? (
      <i style={downloadIconStyle} className='fa fa-check-circle-o fa-fw' />
    ) : isDownloading ? (
      <i style={downloadIconStyle} className='fa fa-spinner fa-pulse fa-fw' />
    ) : (
      <i
        style={{
          ...downloadIconStyle,
          color: '#C6D0DD'
        }}
        className='fa fa-circle-o fa-fw'
      />
    )}
    <span>{name}</span>
  </LineItem>
)
type ActionSequence = {
  register: (cb: () => void) => void
}

type DownloadManagerProps = {
  file: File
  partitionCount: number
  actionSequence: ActionSequence
}

type DownloadManagerState = {
  currentDownloadingIndex: number
}

export class DownloadManager extends Component<
  DownloadManagerProps,
  DownloadManagerState
> {
  public state: DownloadManagerState = {
    currentDownloadingIndex: 0
  }

  constructor(props: DownloadManagerProps) {
    super(props)

    const { actionSequence } = props

    actionSequence.register(() => this.downloadAll())
  }

  public async downloadAll() {
    const { file, partitionCount } = this.props

    const targetSizePerPartition = Math.ceil(file.size / partitionCount)

    let papaParser
    let headerRow
    let currentPapaChunkData = []
    let currentPapaChunkDataSizeEstimate = 0
    let papaParsePreviousCursorPosition = 0
    let setCurrentChunkResolved

    const isPapaParseReady = new Promise(setIsPapaParseReady => {
      Papa.parse(file, {
        chunk(parsedChunk, _papaParser) {
          if (typeof papaParser === 'undefined') {
            papaParser = _papaParser
            setIsPapaParseReady(true)
            window.pp = papaParser
          }

          papaParser.pause()

          const { data, errors, meta } = parsedChunk

          if (errors && errors.length) {
            throw new Error(JSON.stringify(errors))
          }

          if (typeof headerRow === 'undefined') {
            headerRow = data.splice(0, 1)[0]
          }

          currentPapaChunkData = currentPapaChunkData.concat(data)

          const newDataSize = meta.cursor - papaParsePreviousCursorPosition
          papaParsePreviousCursorPosition = meta.cursor

          currentPapaChunkDataSizeEstimate += newDataSize

          if (setCurrentChunkResolved) {
            setCurrentChunkResolved()
            setCurrentChunkResolved = undefined
          }
        },
        error(err) {
          // @todo add error handling
          alert(
            'An error occurred, please select a different file or try again'
          )
          throw err
        }
      })
    })

    const loadNextPapaParseChunk = () =>
      new Promise(resolve => {
        setCurrentChunkResolved = resolve
        papaParser.resume()
      })

    const consumeChunkDataWithTargetSize = async targetSize => {
      while (
        !papaParser.streamer._finished && // @todo - we are relying on an internal API here
        currentPapaChunkDataSizeEstimate < targetSize
      ) {
        await loadNextPapaParseChunk()
      }

      const estimatedSizePerRow =
        currentPapaChunkDataSizeEstimate / currentPapaChunkData.length

      const bestGuessNumberOfRowsForPartition = Math.ceil(
        targetSize / estimatedSizePerRow
      )
      const partitionDataRows = currentPapaChunkData.splice(
        0,
        bestGuessNumberOfRowsForPartition
      )
      currentPapaChunkDataSizeEstimate =
        estimatedSizePerRow * currentPapaChunkData.length

      return partitionDataRows
    }

    const getNextPartitionDataBlob = async isLast => {
      await isPapaParseReady
      const partitionData = await consumeChunkDataWithTargetSize(
        isLast ? Infinity : targetSizePerPartition // Last chunk must include the rest of the file
      )

      return new Blob([Papa.unparse([headerRow].concat(partitionData))])
    }

    const delay = () => new Promise(resolve => setTimeout(resolve, 500))

    const incrementIndex = () =>
      new Promise(resolve =>
        this.setState(
          {
            currentDownloadingIndex: this.state.currentDownloadingIndex + 1
          },
          resolve
        )
      )

    // download one file at a time
    while (this.state.currentDownloadingIndex < partitionCount) {
      // Advance to next partition
      await incrementIndex()

      await delay() // important to wait to be able to see anything happening

      const index = this.state.currentDownloadingIndex
      const filename = file.name.replace(
        /\.[^\.]+$/,
        ext => ` - Part ${index} of ${partitionCount}${ext}`
      )

      const isLast = index === partitionCount
      const partitionDataBlob = await getNextPartitionDataBlob(isLast)
      // @todo add error handling

      saveAs(partitionDataBlob, filename)

      await delay() // important to wait to be able to see anything happening
    }

    // Advance past last partition so all show as downloaded
    await incrementIndex()

    return true
  }

  public render() {
    const { currentDownloadingIndex } = this.state
    const { file, partitionCount } = this.props

    const partitions = new Array(partitionCount).fill(null).map((x, i) => i + 1)

    return (
      <div style={{ border: '1px solid #C6D0DD', borderRadius: '2px' }}>
        <div
          style={{
            background: '#F5F8FA',
            padding: '7px 10px 10px',
            borderBottom: '1px solid #C6D0DD'
          }}
        >
          <div style={{ fontWeight: 'bold' }}>{file.name}</div>
          <div style={{ fontSize: '80%', paddingTop: '3px' }}>
            {prettyBytes(file.size)} &bull;{' '}
            <strong className='partition-counter'>{partitionCount} part</strong>{' '}
            upload
          </div>
        </div>
        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
          {partitions.map(index => (
            <DownloadableFileStatusRow
              key={index}
              name={`Part ${index}`}
              isDownloading={index === currentDownloadingIndex}
              isDownloaded={index < currentDownloadingIndex}
            />
          ))}
        </div>
      </div>
    )
  }
}
