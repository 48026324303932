import React from 'react'
import { Creatable } from 'react-select'
import 'react-select/dist/react-select.css'
import VirtualizedSelect from 'react-virtualized-select'
import 'react-virtualized-select/styles.css'
import 'react-virtualized/styles.css'

import { IOptionMatches } from '@interfaces/general.interface'
import { MatcherType } from './fieldOptions'

type MatchOptionsTableProps = {
  options: any
  optionsMap: IOptionMatches
  matchValues: string[]
  index: number
  matcher: MatcherType
}

const matchOptionsTable: React.FC<MatchOptionsTableProps> = (
  props: MatchOptionsTableProps
) => {
  const { options, optionsMap, matchValues, index, matcher } = props

  return (
    <div className='column'>
      <div className='match-options-table-container'>
        <div className='header'>
          <div className='column'>Your values</div>
          <div className='column'>Our values</div>
        </div>
        <table className='match-options-table'>
          <tbody>
            {matchValues.map((v, i) => (
              <tr key={`match-options-table-row-${i}`}>
                <td>
                  {optionsMap && optionsMap[v] ? (
                    <i className='fa fa-check successColor' />
                  ) : (
                    <i className='fa fa-warning' />
                  )}
                  {v}
                </td>
                <td>
                  <VirtualizedSelect
                    options={options}
                    clearable={false}
                    selectComponent={Creatable}
                    placeholder='Choose one'
                    onChange={(selectValue: object) =>
                      matcher.changeOptionsMatch(index, v, selectValue)
                    }
                    value={optionsMap ? optionsMap[v] : ''}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default matchOptionsTable
