import { IStringDictionary } from '@interfaces/general.interface'
import {
  IFieldInternal,
  ISettingsInternal,
  IValidatorInternal
} from '@interfaces/internal.settings.interface'
import {
  IField,
  ISettings,
  IShorthandRegexExcludes,
  IValidator,
  IValidatorDictionary,
  IValidatorType
} from '@interfaces/settings.interface'
import difference from 'lodash/difference'
import { isObject, precisionRound } from './functions'
import { standardRegexes } from './validators'

export default class Settings {
  set errors(errors: string[]) {
    this._errors = errors
  }

  get errors(): string[] {
    return this._errors
  }

  get settings(): ISettingsInternal {
    return Object.assign(
      {
        allowCustom: false,
        fuzziness: 0.4,
        type: 'Record',
        allowInvalidSubmit: false,
        managed: false,
        styleOverrides: {},
        i18nOverrides: {},
        integrations: {
          adobeFontsWebProjectId: null
        }
      },
      this._settings
    )
  }
  private readonly colorPattern: string
  private readonly lengthPattern: string
  private readonly _settings: ISettingsInternal
  private colorRegex: RegExp
  private lengthRegex: RegExp
  private borderRadiusRegex: RegExp
  private _errors: string[] = []

  constructor(settings: ISettings) {
    this.colorPattern = [
      /#[0-9a-f]{3}/, // short hexadecimal
      /|#([0-9a-f]{2}){2,4}/, // long hexadecimal w/ rgba support
      /|rgb\((-?\d+\s*,\s*){2}(-?\d+\s*)\)/, // rgb
      /|rgb\((-?\d+%\s*,\s*){2}(-?\d+%\s*)\)/, // rgb percentile
      /|rgba\((-?\d+\s*,\s*){3}(-?\d+(\.\d+)?\s*)\)/, // rgba
      /|rgba\((-?\d+%\s*,\s*){3}(-?\d+(\.\d+)?\s*)\)/, // rgba percentile
      /|hsl\((-?\d+\s*,\s*)(-?\d+%\s*,\s*)(-?\d+%\s*)\)/, // hsl
      /|hsla\((-?\d+\s*,\s*)(-?\d+%\s*,\s*){2}(-?\d+(\.\d+)?\s*)\)/ // hsla
    ]
      .map(r => {
        return r.source
      })
      .join('')
    this.lengthPattern = /((auto|0)|[+-]?[0-9]+.?([0-9]+)?(px|em|ex|%|in|cm|mm|pt|pc))/.source
    this.colorRegex = new RegExp(`^${this.colorPattern}$`, 'i')
    this.lengthRegex = new RegExp(`^${this.lengthPattern}$`, 'i')
    this.borderRadiusRegex = new RegExp(
      `^inherit|initial|unset|${this.lengthPattern}(\\s+${this.lengthPattern}){0,3}(/${this.lengthPattern}(\\s+${this.lengthPattern}){0,3})?$`,
      'i'
    )
    this._settings = this.initSettings(settings)
  }

  public validate() {
    this.testSettings(this._settings)
    return !this._errors.length
  }

  public validateValidators(validators, field, fields): string[] {
    let errors: string[] = []
    if (Array.isArray(validators)) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < validators.length; i++) {
        const validator = validators[i]
        if (typeof validator.validate === 'string') {
          switch (validator.validate) {
            case 'regex_matches':
              errors = [
                ...errors,
                ...this.validateRegexMatches(validator, field)
              ]
              break
            case 'regex_excludes':
              errors = [
                ...errors,
                ...this.validateRegexExcludes(validator, field)
              ]
              break
            case 'required':
              errors = [...errors, ...this.validateRequired(validator, field)]
              break
            case 'boolean':
              errors = [...errors, ...this.validateBoolean(validator, field)]
              break
            case 'select':
              errors = [...errors, ...this.validateSelect(validator, field)]
              break
            case 'required_without':
            case 'required_without_all':
            case 'required_with_all':
            case 'required_with':
              errors = [
                ...errors,
                ...this.validateRequiredFields(validator, field, fields)
              ]
              break
            default:
              errors.push(
                `Unkown validator method ${
                  validator.validate
                } in field: ${JSON.stringify(field, null, 4)}`
              )
          }
        } else {
          errors.push(
            `'${field.key}' requires validate method in field: ${JSON.stringify(
              field,
              null,
              4
            )}`
          )
        }
      }
    } else {
      errors.push(
        `'${
          field.key
        }' requires validators to be in an array in field: ${JSON.stringify(
          field,
          null,
          4
        )}`
      )
    }
    return errors
  }

  public validateRegexMatches(validator, field): string[] {
    const errors: string[] = []
    if (!validator.regex) {
      errors.push(
        `'regex_matches' validator requires a 'regex' field in: ${JSON.stringify(
          field,
          null,
          4
        )}`
      )
    }
    if (!(validator.regex instanceof RegExp)) {
      errors.push(
        `'regex' field in 'regex_matches' validator must be a RegExp object in: ${JSON.stringify(
          field,
          null,
          4
        )}`
      )
    }
    const invalidFields = difference(Object.keys(validator), [
      'validate',
      'regex',
      'error'
    ])
    if (invalidFields.length) {
      errors.push(
        `'regex_matches' validator has unexpected field(s) "${invalidFields.join(
          ', '
        )}" in: ${JSON.stringify(field, null, 4)}`
      )
    }
    return [...errors, ...this.validateErrorField(validator, field)]
  }

  public validateRegexExcludes(validator, field): string[] {
    const errors: string[] = []
    if (!validator.regex) {
      errors.push(
        `'regex_excludes' validator requires a 'regex' field in: ${JSON.stringify(
          field,
          null,
          4
        )}`
      )
    }
    if (!(validator.regex instanceof RegExp)) {
      errors.push(
        `'regex' field in 'regex_excludes' validator must be a RegExp object in: ${JSON.stringify(
          field,
          null,
          4
        )}`
      )
    }
    const invalidFields = difference(Object.keys(validator), [
      'validate',
      'regex',
      'error'
    ])
    if (invalidFields.length) {
      errors.push(
        `'regex_excludes' validator has unexpected field(s) "${invalidFields.join(
          ', '
        )}" in: ${JSON.stringify(field, null, 4)}`
      )
    }
    return [...errors, ...this.validateErrorField(validator, field)]
  }

  public validateRequired(validator, field): string[] {
    const errors: string[] = []
    const invalidFields = difference(Object.keys(validator), [
      'validate',
      'error'
    ])
    if (invalidFields.length) {
      errors.push(
        `'required' validator has unexpected field(s) "${invalidFields.join(
          ', '
        )}" in: ${JSON.stringify(field, null, 4)}`
      )
    }
    return [...errors, ...this.validateErrorField(validator, field)]
  }

  public validateBoolean(validator, field): string[] {
    const errors: string[] = []
    const invalidFields = difference(Object.keys(validator), [
      'validate',
      'error',
      'regex'
    ])
    if (invalidFields.length) {
      errors.push(
        `'boolean' validator has unexpected field(s) "${invalidFields.join(
          ', '
        )}" in: ${JSON.stringify(field, null, 4)}`
      )
    }
    return [...errors, ...this.validateErrorField(validator, field)]
  }

  public validateSelect(validator, field): string[] {
    const errors: string[] = []
    const invalidFields = difference(Object.keys(validator), [
      'validate',
      'error'
    ])
    if (invalidFields.length) {
      errors.push(
        `'select' validator has unexpected field(s) "${invalidFields.join(
          ', '
        )}" in: ${JSON.stringify(field, null, 4)}`
      )
    }
    return [...errors, ...this.validateErrorField(validator, field)]
  }

  public validateRequiredFields(validator, field, fields): string[] {
    const errors: string[] = []
    const invalidFields = difference(Object.keys(validator), [
      'validate',
      'fields',
      'error'
    ])
    if (invalidFields.length) {
      errors.push(
        `${
          validator.validate
        } validator has unexpected field(s) "${invalidFields.join(
          ', '
        )}" in: ${JSON.stringify(field, null, 4)}`
      )
    }
    if (!validator.fields) {
      errors.push(
        `${
          validator.validate
        } validator requires a 'fields' field in: ${JSON.stringify(
          field,
          null,
          4
        )}`
      )
    }
    if (!Array.isArray(validator.fields)) {
      errors.push(
        `'fields' field in ${
          validator.validate
        } validator must be an array in: ${JSON.stringify(field, null, 4)}`
      )
    }
    if (
      !validator.fields.every(
        v => fields.findIndex(f => f.key === v) > -1 && v !== field.key
      )
    ) {
      errors.push(
        `'fields' field in ${
          validator.validate
        } validator must only contain keys of other fields in: ${JSON.stringify(
          field,
          null,
          4
        )}`
      )
    }
    return [...errors, ...this.validateErrorField(validator, field)]
  }

  private initSettings(settings: ISettings): ISettingsInternal {
    return (Object.keys(settings) as Array<keyof ISettings>).reduce(
      (acc: ISettingsInternal, val: keyof ISettings): ISettingsInternal => {
        if (val === 'fields' && Array.isArray(settings[val])) {
          acc[val] = settings[val].reduce((a, field) => {
            a.push(this.normalizeFieldValues(field))
            return a
          }, [] as IFieldInternal[])
        } else if (
          val === 'fuzziness' &&
          typeof settings.fuzziness === 'number'
        ) {
          // if NaN pass it along to be caught in validation
          acc.fuzziness = this.normalizeFuzziness(settings.fuzziness)
        } else {
          // @ts-ignore
          acc[val] = settings[val]
        }
        return acc
      },
      {} as ISettingsInternal
    )
  }

  private normalizeFuzziness(fuz: number): number {
    const round = Math.min(precisionRound(fuz, 1), 10)
    return Math.max(round > 1 ? Math.floor(round) / 10 : round, 0.1)
  }

  private normalizeFieldValues(field: Readonly<IField>): IFieldInternal {
    const output: IFieldInternal = {
      key: field.key,
      label: field.label || field.key,
      description: field.description || '',
      validators: [],
      type: field.type,
      options: field.options,
      sizeHint: field.sizeHint,
      alternates: field.alternates
    }
    const rawValidators: Array<IValidator | null> = this.bootstrapSingularValidators(
      field
    )

    if (field.type === 'select') {
      if (
        !rawValidators.find(
          v => isObject(v) && 'validate' in v && v.validate === 'select'
        )
      ) {
        rawValidators.push({
          validate: 'select',
          error: 'must be in options list'
        })
      }
    } else if (field.type === 'checkbox') {
      if (
        !rawValidators.find(
          v => isObject(v) && 'validate' in v && v.validate === 'boolean'
        )
      ) {
        rawValidators.push({
          validate: 'boolean',
          error: 'must be boolean (true/false, yes/no, etc.)'
        })
      }
    }
    const validators: IValidatorInternal[] = rawValidators
      .map((v: IValidator): IValidatorInternal | null => {
        if (isObject(v) && 'validate' in v) {
          if ('regex' in v) {
            return {
              ...v,
              regex:
                typeof v.regex === 'string' ? standardRegexes[v.regex] : v.regex
            }
          }
          if (v.validate === 'boolean') {
            return {
              ...v,
              validate: 'regex_matches',
              regex: standardRegexes.boolean
            }
          }
          return v
        } else {
          return this.expandSimpleValidator(v)
        }
      })
      .filter(
        (v: IValidatorInternal | null): v is IValidatorInternal => v !== null
      )

    return { ...output, validators }
  }

  private expandSimpleValidator(
    validator: Exclude<IValidator, IValidatorDictionary>
  ): IValidatorInternal | null {
    const validatorType = this.discernValidatorType(validator)
    switch (validatorType) {
      case 'regex':
        return { validate: 'regex_matches', regex: validator as RegExp }
      case 'standard_regex':
        return {
          validate: 'regex_matches',
          regex: standardRegexes[validator as string]
        }
      case 'regex_excludes':
        return {
          validate: 'regex_excludes',
          regex: (validator as IShorthandRegexExcludes).regex_excludes
        }
      case 'isRequired':
        return { validate: 'required' }
      case 'required_without':
      case 'required_without_all':
      case 'required_with_all':
      case 'required_with':
        return { validate: validatorType, fields: validator[validatorType] }
      case null:
      default:
        return null
    }
  }

  private discernValidatorType(
    validator: Exclude<IValidator, IValidatorDictionary>
  ): IValidatorType | 'standard_regex' | 'isRequired' | 'regex' | null {
    if (validator instanceof RegExp) {
      return 'regex'
    } else if (typeof validator === 'string' && standardRegexes[validator]) {
      return 'standard_regex'
    } else if (isObject(validator) && 'regex_excludes' in validator) {
      return 'regex_excludes'
    } else if (isObject(validator) && 'isRequired' in validator) {
      return 'isRequired'
    } else if (isObject(validator) && 'required_without' in validator) {
      return 'required_without'
    } else if (isObject(validator) && 'required_without_all' in validator) {
      return 'required_without_all'
    } else if (isObject(validator) && 'required_with_all' in validator) {
      return 'required_with_all'
    } else if (isObject(validator) && 'required_with' in validator) {
      return 'required_with'
    } else {
      return null
    }
  }

  /**
   * @param field
   */
  private bootstrapSingularValidators(
    field: Readonly<IField>
  ): Array<IValidator | null> {
    const validators: Array<IValidator | null> = field.validators || []
    if (field.validator) {
      if (isObject(field.validator) && 'validate' in field.validator) {
        validators.push(field.validator)
      } else {
        validators.push(this.expandSimpleValidator(field.validator))
      }
    }
    if (field.isRequired) {
      validators.push({ validate: 'required' })
    }
    return validators
  }

  private regexToString(_key: string, value: string | RegExp): string {
    if (value instanceof RegExp) {
      return value.toString()
    }
    return value
  }

  private testSettings(settings: ISettingsInternal): void {
    let errors: string[] = []
    if (!window.FF_LICENSE_KEY) {
      errors.push('The provided license is invalid')
    }
    errors = [...errors, ...this.validateOptions(settings)]
    errors = [...errors, ...this.validateFields(settings.fields)]
    this.errors = errors.filter(v => v)
  }

  private validateOptions(settings: ISettingsInternal): string[] {
    const errors: string[] = []
    const invalidOptions = difference(Object.keys(settings), [
      'fields',
      'type',
      'instructions',
      'title',
      'allowCustom',
      'fuzziness',
      'allowInvalidSubmit',
      'managed',
      'styleOverrides',
      'disableManualInput',
      'maxSize',
      'maxPartitionSize',
      'maxRecords',
      'defaultFileEncoding',
      'injectCss',
      'integrations',
      'i18nOverrides'
    ])
    if (invalidOptions.length) {
      errors.push(
        `Unrecognized extra setting(s) present: ${JSON.stringify(
          invalidOptions,
          this.regexToString,
          4
        )}`
      )
    }
    if (settings.type && typeof settings.type !== 'string') {
      errors.push(`'type' option must be a string`)
    }
    if (settings.title && typeof settings.title !== 'string') {
      errors.push(`'title' option must be a string`)
    }
    if (settings.instructions && typeof settings.instructions !== 'string') {
      errors.push(`'instructions' option must be a string`)
    }
    if (settings.injectCss && typeof settings.injectCss !== 'string') {
      errors.push(`'injectCss' option must be a string`)
    }
    if (
      typeof settings.allowCustom !== 'boolean' &&
      typeof settings.allowCustom !== 'undefined'
    ) {
      errors.push(`'allowCustom' option must be boolean`)
    }
    if (
      typeof settings.maxSize !== 'number' &&
      typeof settings.maxSize !== 'undefined'
    ) {
      errors.push(`'maxSize' option must be number`)
    }
    if (
      typeof settings.maxPartitionSize !== 'number' &&
      typeof settings.maxPartitionSize !== 'undefined'
    ) {
      errors.push(`'maxPartitionSize' option must be number`)
    }
    if (
      typeof settings.defaultFileEncoding !== 'string' &&
      typeof settings.defaultFileEncoding !== 'undefined'
    ) {
      errors.push(`'defaultFileEncoding' option must be string`)
    }
    if (
      typeof settings.maxRecords !== 'number' &&
      typeof settings.maxRecords !== 'undefined'
    ) {
      errors.push(`'maxRecords' option must be number`)
    }
    if (
      typeof settings.allowInvalidSubmit !== 'boolean' &&
      typeof settings.allowInvalidSubmit !== 'undefined'
    ) {
      errors.push(`'allowInvalidSubmit' option must be boolean`)
    }
    if (
      typeof settings.managed !== 'boolean' &&
      typeof settings.managed !== 'undefined'
    ) {
      errors.push(`'managed' option must be boolean`)
    }
    if (
      typeof settings.disableManualInput !== 'boolean' &&
      typeof settings.disableManualInput !== 'undefined'
    ) {
      errors.push(`'disableManualInput' option must be boolean`)
    }
    if (settings.fields && !Array.isArray(settings.fields)) {
      errors.push('Fields should be in an array')
    }
    if (
      settings.fields &&
      Array.isArray(settings.fields) &&
      !settings.fields.length
    ) {
      errors.push('Fields array should have at least one field')
    }
    if (!settings.fields) {
      errors.push('Array of fields must be present')
    }
    if (
      settings.styleOverrides &&
      typeof settings.styleOverrides !== 'object'
    ) {
      errors.push(`'styleOverrides' option must be object`)
    }
    if (settings.i18nOverrides && typeof settings.i18nOverrides !== 'object') {
      errors.push("'i18nOverrides' option must be object")
    }
    if (settings.styleOverrides && isObject(settings.styleOverrides)) {
      const invalidOptionsInStyleOverrides = difference(
        Object.keys(settings.styleOverrides),
        [
          'borderRadius',
          'buttonHeight',
          'primaryButtonColor',
          'secondaryButtonColor',
          'primaryButtonBorderColor',
          'invertedButtonColor',
          'linkColor',
          'linkAltColor',
          'primaryTextColor',
          'secondaryTextColor',
          'errorColor',
          'successColor',
          'warningColor',
          'borderColor',
          'fontFamily'
        ]
      )
      Object.keys(settings.styleOverrides).forEach(style => {
        switch (style) {
          case 'primaryButtonColor':
          case 'secondaryButtonColor':
          case 'primaryButtonBorderColor':
          case 'invertedButtonColor':
          case 'linkColor':
          case 'linkAltColor':
          case 'primaryTextColor':
          case 'secondaryTextColor':
          case 'errorColor':
          case 'successColor':
          case 'warningColor':
          case 'borderColor':
            if (!this.validateCSSColor(settings.styleOverrides[style])) {
              errors.push(`${style} css value is not a valid css color`)
            }
            break
          case 'borderRadius':
            if (!this.validateCSSBorderRadius(settings.styleOverrides[style])) {
              errors.push(
                `${style} css value is not a valid border-radius value`
              )
            }
            break
          default:
          // do nothing
        }
      })
      if (invalidOptionsInStyleOverrides.length) {
        errors.push(
          `Unrecognized extra styles(s) present in 'styleOverrides': ${JSON.stringify(
            invalidOptionsInStyleOverrides,
            this.regexToString,
            4
          )}`
        )
      }
    }
    if (settings.integrations && isObject(settings.integrations)) {
      const invalidOptionsInIntegrations = difference(
        Object.keys(settings.integrations),
        ['adobeFontsWebProjectId']
      )
      Object.keys(settings.integrations).forEach(key => {
        if (
          key === 'adobeFontsWebProjectId' &&
          settings.integrations[key] !== null
        ) {
          if (!/^[a-z0-9]{5,10}$/.test(settings.integrations[key])) {
            errors.push(
              `integrations.adobeFontsWebProjectId: "${settings.integrations[key]}" is not a valid Adobe Fonts Web Project Id (i.e. "sam8ucq")`
            )
          }
        }
      })
      if (invalidOptionsInIntegrations.length) {
        errors.push(
          `Unrecognized extra key(s) present in 'integrations': ${JSON.stringify(
            invalidOptionsInIntegrations,
            this.regexToString,
            4
          )}`
        )
      }
    }
    if (settings.fuzziness && Number.isNaN(settings.fuzziness)) {
      errors.push(`'fuzziness' settings must be a number`)
    }
    return errors
  }

  private validateCSSColor(color: string) {
    return typeof color === 'string' && color.match(this.colorRegex) !== null
  }

  private validateCSSBorderRadius(borderRadius: string) {
    return (
      typeof borderRadius === 'string' &&
      borderRadius.match(this.borderRadiusRegex) !== null
    )
  }

  private validateFields(fields: IFieldInternal[]): string[] {
    let errors: string[] = []
    if (fields && Array.isArray(fields)) {
      fields.forEach(field => {
        if (isObject<IStringDictionary>(field)) {
          errors = [...errors, ...this.validateKeys(Object.keys(field))]
          errors = [
            ...errors,
            ...this.validateStringKeys(
              field.label,
              field.key,
              field.description,
              field
            )
          ]
          if (field.alternates) {
            errors = [
              ...errors,
              ...this.validateAlternates(field.alternates, field)
            ]
          }
          if (field.validators) {
            errors = [
              ...errors,
              ...this.validateValidators(field.validators, field, fields)
            ]
          }
        } else {
          errors.push(
            `Fields should be objects: ${JSON.stringify(
              field,
              this.regexToString,
              4
            )}`
          )
        }
      })
      const keyCount = fields.reduce(
        (a, b) => Object.assign(a, { [b.key]: (a[b.key] || 0) + 1 }),
        {}
      )
      const duplicateKeys = Object.keys(keyCount).filter(v => keyCount[v] > 1)
      if (duplicateKeys.length) {
        errors.push(
          `Fields must have unique keys. Check fields with keys: ${duplicateKeys.join(
            ', '
          )}`
        )
      }
    }
    return errors
  }

  private validateKeys(keys: string[]): string[] {
    const errors: string[] = []
    const extraKeys = difference(keys, [
      'label',
      'key',
      'description',
      'shortDescription',
      'sizeHint',
      'validators',
      'alternates',
      'type',
      'options'
    ])
    if (extraKeys.length) {
      errors.push(`Extra keys found in field: ${extraKeys.join(', ')}`)
    }
    if (keys.indexOf('key') < 0) {
      errors.push('A key is required for all fields')
    }
    return errors
  }

  private validateStringKeys(
    // tslint:disable-next-line:no-any
    label: any,
    // tslint:disable-next-line:no-any
    key: any,
    // tslint:disable-next-line:no-any
    description: any,
    // tslint:disable-next-line:no-any
    field: any
  ): string[] {
    const errors: string[] = []
    if (typeof label !== 'string') {
      errors.push(
        `Labels must be strings in field: ${JSON.stringify(field, null, 4)}`
      )
    }
    if (typeof key !== 'string') {
      errors.push(
        `Keys must be strings in field: ${JSON.stringify(field, null, 4)}`
      )
    }
    if (typeof description !== 'string') {
      errors.push(
        `Descriptions must be strings in field: ${JSON.stringify(
          field,
          null,
          4
        )}`
      )
    }
    if (field.shortDescription && typeof field.shortDescription !== 'string') {
      errors.push(
        `Short Descriptions must be strings in field: ${JSON.stringify(
          field,
          null,
          4
        )}`
      )
    }
    if (field.type && field.type !== 'checkbox' && field.type !== 'select') {
      errors.push(
        `Type must be checkbox or select in field: ${JSON.stringify(
          field,
          null,
          4
        )}`
      )
    }
    return errors
  }

  private validateAlternates(alternates, field): string[] {
    const errors: string[] = []
    if (!Array.isArray(alternates)) {
      errors.push(
        `Alternates must be in arrays in field: ${JSON.stringify(
          field,
          null,
          4
        )}`
      )
      return errors
    }
    if (!alternates.every(v => typeof v === 'string')) {
      errors.push(
        `Alternates must all be strings in field: ${JSON.stringify(
          field,
          null,
          4
        )}`
      )
    }
    return errors
  }

  private validateErrorField(validator, field): string[] {
    if (validator.error && typeof validator.error !== 'string') {
      return [
        `'error' field must be a string in ${
          validator.validate
        } in field: ${JSON.stringify(field, null, 4)}`
      ]
    }
    return []
  }
}
