import { SENTRY_CONFIG } from '@config'
import { IFeatures } from '@interfaces/internal.settings.interface'
import { IBatchConfig, IEndUser } from '@interfaces/settings.interface'
import * as Sentry from '@sentry/browser'
import { parse } from 'query-string'
import { LoadingLine } from '@components/Loading'

import 'console-polyfill'
import Penpal from 'penpal'
import React, { RefObject, Suspense, useEffect, useState } from 'react'
import { render } from 'react-dom'
import App from 'scenes/app'
import './index.html'
import i18n from './i18n'
import { I18nextProvider } from 'react-i18next'

if (SENTRY_CONFIG.enabled) {
  Sentry.init({
    dsn: SENTRY_CONFIG.dsn,
    debug: SENTRY_CONFIG.debug,
    environment: process.env.CONTEXT || SENTRY_CONFIG.environment,
    release: process.env.COMMIT_REF || SENTRY_CONFIG.release
  })
}

const urlParams = parse(location.search)

if (!window.FF_LICENSE_DOMAIN) {
  window.FF_LICENSE_DOMAIN =
    'domain' in urlParams
      ? (urlParams.domain as string)
      : 'https://api.flatfile.io'
}

const licenseKeyRegExp = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/

const [licenseKeyInPath] = location.pathname.match(licenseKeyRegExp) || [null]

if (!('key' in urlParams) && licenseKeyInPath) {
  urlParams.key = licenseKeyInPath
}

const AppRef: RefObject<App> = React.createRef()

window.addEventListener('load', () => {
  render(
    <div className='flatfile-root'>
      <I18nextProvider i18n={i18n}>
        <LoadingLine />
        <Suspense fallback='Loading...'>
          <LicenseProvider>
            {(license, features) => (
              <App
                handshake={handshake.promise}
                ref={AppRef}
                features={features}
                license={license}
              />
            )}
          </LicenseProvider>
        </Suspense>
      </I18nextProvider>
    </div>,
    document.getElementById('kiosk-lite')
  )
})

Penpal.debug = false

const handshake = Penpal.connectToParent({
  methods: {
    open(batchConfig: IBatchConfig) {
      return AppRef.current && AppRef.current.open(batchConfig)
    },
    close() {
      return AppRef.current && AppRef.current.close()
    },
    displayLoader(msg: string) {
      return AppRef.current && AppRef.current.displayLoader(msg)
    },
    displaySuccess(msg: string) {
      return AppRef.current && AppRef.current.displaySuccess(msg)
    },
    displayError(error: string) {
      return AppRef.current && AppRef.current.displayError(error)
    },
    getMeta() {
      return AppRef.current && AppRef.current.state.importMeta
    },
    nextChunk() {
      return AppRef.current && AppRef.current.nextChunk()
    },
    parentHasValidator() {
      return AppRef.current && AppRef.current.parentHasValidator()
    },
    setUser(endUser: IEndUser) {
      return AppRef.current && AppRef.current.setUser(endUser)
    }
  }
})

const LicenseProvider = props => {
  const { license, features } = useLicensing()
  if (!license) {
    return <div>Loading...</div>
  } else {
    if (!license.key) {
      Sentry.captureException(
        new Error('Could not initialize importer due to invalid license key.')
      )
    } else {
      Sentry.configureScope(scope => {
        scope.setTag('license_key', license.key || 'no-license')
      })
    }

    window.FF_FEATURES = features
    window.FF_LICENSE_KEY = license.key
    return props.children(license, features)
  }
}

const useLicensing = (): LicensingResponse => {
  const [features, setFeatures] = useState<IFeatures>({
    xls: true,
    styleOverride: true,
    injectCss: false,
    whiteLabel: false,
    developmentMode: false
  })
  const [license, setLicense] = useState<{ key?: string; error?: string }>(null)

  useEffect(() => {
    // allow specifying no domain for local testing
    if (
      window.FF_LICENSE_DOMAIN === 'test-domain' ||
      urlParams.key === '8ac43570-78fc-11e8-9e98-43c1e2d23f75' // hardcode for clickup
    ) {
      window.FF_LICENSE_KEY = urlParams.key as string
      window.FF_FEATURES = {
        developmentMode: window.FF_LICENSE_DOMAIN === 'test-domain'
      }
      setFeatures({ ...window.FF_FEATURES, styleOverride: true })
      setLicense({
        key: window.FF_LICENSE_KEY
      })
    } else {
      const path = `${window.FF_LICENSE_DOMAIN}/license-data?key=${urlParams.key}`
      fetch(path)
        .then(res => {
          if (res.status < 200 || res.status >= 300) {
            throw res
          } else {
            return res.json()
          }
        })
        .then(
          data => {
            if (data.features) {
              setFeatures(data.features)
            }
            console.log(data.license)
            setLicense(
              data.license || { key: null, error: 'No license in response' }
            )
          },
          err => {
            Sentry.captureException(err)
            setLicense({ key: null, error: 'Error contacting server.' })
          }
        )
    }
  }, [])
  return { license, features }
}

type LicensingResponse = {
  license?: { key?: string; error?: string }
  features: IFeatures
}
