/**
 * ActionSequence:
 *
 * const seq = new ActionSequence()
 *
 * seq.register(... handler ...)
 * await seq.start() // returns true or false
 */
export const ActionSequence = () => {
  let handler

  return {
    register(_handler) {
      handler = _handler
    },

    async start() {
      if (typeof handler !== 'function') {
        throw new TypeError(
          'ActionSequence: invalid sequence - register() must be called before start()'
        )
      }

      try {
        await handler()
        return true
      } catch (e) {
        return false
      }
    }
  }
}
