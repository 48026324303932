import React from 'react'

import {
  IColumnMeta,
  ICsvData,
  IKeyNameMap
} from '@interfaces/general.interface'
import { IFieldOption } from '@interfaces/settings.interface'
import { convertToLetters } from '@lib/functions'
import Spinner from '../../upload/Spinner'
import ConfirmMap from './confirmMap'
import { MatcherType } from './fieldOptions'
import MatchOptionsTable from './matchOptionsTable'
import TopRow from './topRow'

type ColBodyProps = {
  csvData: ICsvData
  index: number
  header: boolean
  column: string
  matcher: MatcherType
  columnMeta: IColumnMeta
  columnOptions: string[]
  allowCustom: boolean
  keyNames: IKeyNameMap
  columnFill: object
}

type ContentRowProps = {
  index: number
  data: string
}

const ColBody: React.FC<ColBodyProps> = (props: ColBodyProps) => {
  if (props.columnMeta) {
    if (
      /(matched)|(unmached)|(locked)/i.test(
        props.columnMeta[props.index].matchState
      )
    ) {
      const contentrows = []
      for (let i = 0; i < 3; i++) {
        if (props.csvData.data[i + 1]) {
          const offset = props.header ? 2 : 1
          contentrows.push(
            <ContentRow
              index={i + offset}
              data={props.csvData.data[i][props.column]}
              key={i}
            />
          )
        }
      }
      let matchOptionsTable = null
      if (props.columnMeta[props.index].type === 'select') {
        let options: IFieldOption[] = [...props.columnMeta[props.index].options]
        if (typeof options[0] === 'string') {
          options = options.map((opt: string) => ({
            label: opt,
            value: opt
          }))
        }
        const matchValues = props.csvData.data.map(d => d[props.column])
        const uniqueMatchValues = Array.from(new Set(matchValues))
        if (uniqueMatchValues.length > options.length * 3) {
          matchOptionsTable = (
            <div className='column' style={{ maxWidth: '20%' }}>
              <div className='match-options-table-container'>
                <i className='fa fa-warning' /> Too many values in the source
                data to map to these options
              </div>
            </div>
          )
        } else {
          matchOptionsTable = (
            <MatchOptionsTable
              matcher={props.matcher}
              matchValues={uniqueMatchValues}
              options={options}
              optionsMap={props.columnMeta[props.index].optionsMap}
              index={props.index}
            />
          )
        }
      }

      return (
        <div className='col-body primaryTextColor'>
          <div className='column'>
            <table>
              <TopRow
                matcher={props.matcher}
                csvData={props.csvData}
                columnMeta={props.columnMeta}
                header={props.header}
                columnOptions={props.columnOptions}
                allowCustom={props.allowCustom}
                keyNames={props.keyNames}
                index={props.index}
              />
              <tbody>{contentrows}</tbody>
            </table>
          </div>
          {matchOptionsTable}
          <div className='column'>
            <ConfirmMap
              columnMeta={props.columnMeta}
              columnFill={props.columnFill}
              csvData={props.csvData}
              header={props.header}
              matcher={props.matcher}
              keyNames={props.keyNames}
              index={props.index}
              allowCustom={props.allowCustom}
            />
          </div>
        </div>
      )
    } else if (props.columnMeta[props.index].matchState === 'ignored') {
      return (
        <div className='col-body column-collapsed column-ignored'>
          <div className='column'>
            <div className='ignored-table'>
              <span>{convertToLetters(props.index + 1)}</span>
              <span>{props.columnMeta[props.index].oldName}</span>
            </div>
          </div>
          <div className='column'>
            <ConfirmMap
              columnMeta={props.columnMeta}
              columnFill={props.columnFill}
              csvData={props.csvData}
              header={props.header}
              matcher={props.matcher}
              keyNames={props.keyNames}
              index={props.index}
              allowCustom={props.allowCustom}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div className='col-body column-collapsed'>
          <div className='column'>
            <table>
              <TopRow
                matcher={props.matcher}
                columnMeta={props.columnMeta}
                header={props.header}
                columnOptions={props.columnOptions}
                allowCustom={props.allowCustom}
                keyNames={props.keyNames}
                index={props.index}
              />
            </table>
          </div>
          <div className='column'>
            <ConfirmMap
              columnMeta={props.columnMeta}
              columnFill={props.columnFill}
              csvData={props.csvData}
              matcher={props.matcher}
              keyNames={props.keyNames}
              index={props.index}
              allowCustom={props.allowCustom}
            />
          </div>
        </div>
      )
    }
  }

  return <Spinner />
}

const ContentRow = ({ index, data }: ContentRowProps) => (
  <tr>
    <th>{index}</th>
    <td colSpan={2}>{data}</td>
  </tr>
)

export default ColBody
