import Handsontable from 'handsontable-pro'
import { range } from 'lodash'
import React, { Component } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { render } from 'react-dom'
import HotTable from 'react-handsontable'
import { Translation } from 'react-i18next'
import tippy from 'tippy.js'

import { HOT_LICENSE_KEY } from '@config'
import {
  IColumnMeta,
  IDictionary,
  IProcessedColumnMeta,
  IProcessedRow
} from '@interfaces/general.interface'
import { IHoTInstance } from '@interfaces/hot.interface'
import { IFieldInternal } from '@interfaces/internal.settings.interface'
import {
  IFieldOption,
  IFieldOptionDictionary
} from '@interfaces/settings.interface'
import {
  getRealElementHeight,
  getRealStringLength,
  getWindowHeight,
  getWindowWidth,
  removeElementsByClass
} from '@lib/functions'
import Validate from '@lib/validate'
import 'handsontable/dist/handsontable.full.css'
import Review from './index'

type HoTProps = {
  t0: DOMHighResTimeStamp
  validate: Validate
  onEdit: Review['onEdit']
  isReloading: Review['isReloading']
  columns: IProcessedColumnMeta[]
  rows: IProcessedRow[]
  filteredRows: IProcessedRow[]
  columnMeta: IColumnMeta
  defaultTypes: IDictionary<IFieldInternal['type']>
  defaultShortDescriptions: IDictionary<string | undefined>
  defaultOptions: IDictionary<IFieldOption[]>
  header: boolean
}

type HoTState = {
  rows: IProcessedRow[]
  filteredRows: IProcessedRow[]
  columnMeta: IColumnMeta
  columns: IProcessedColumnMeta[]
  tableHeight: number
}

export default class HoT extends Component<HoTProps, HoTState> {
  public tableRef: React.MutableRefObject<IHoTInstance>
  public t1: number
  public t0: DOMHighResTimeStamp
  public columnWidths: number[]

  constructor(props: HoTProps) {
    super(props)
    this.tableRef = React.createRef()
    this.t0 = props.t0
    this.t1 = 0
    this.customRenderer = this.customRenderer.bind(this)
    this.excludeRow = this.excludeRow.bind(this)
    this.columnWidths = []
    this.state = {
      columns: props.columns,
      rows: props.rows,
      filteredRows: props.filteredRows,
      columnMeta: props.columnMeta,
      tableHeight: 500
    }
  }

  public clearAllRefs() {
    this.tableRef.current = null
  }

  public componentWillMount() {
    this.setColumnWidths()
    Handsontable.renderers.registerRenderer('ff.custom', this.customRenderer)
    const windowHeight = getWindowHeight()
    const tableHeight = windowHeight - 385 > 200 ? windowHeight - 385 : 200
    this.setState({ tableHeight })
  }

  public componentWillReceiveProps(nextProps) {
    const { filteredRows } = this.state
    if (nextProps.filteredRows !== filteredRows) {
      this.setState({ filteredRows: nextProps.filteredRows })
    }
    if (nextProps.t0 !== this.t0) {
      this.t0 = nextProps.t0
    }
  }

  public shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.filteredRows !== this.state.filteredRows ||
      nextProps.t0 !== this.t0
    )
  }

  public componentWillUpdate(nextProps, nextState) {
    if (nextState.rows !== this.props.validate.rows) {
      this.props.validate.rows = nextState.rows
    }
    if (nextState.columns !== this.props.validate.columns) {
      this.props.validate.columns = nextState.columns
    }
    if (nextProps.rows !== this.props.validate.rows) {
      this.props.validate.rows = nextProps.rows
    }
    if (nextProps.columns !== this.props.validate.columns) {
      this.props.validate.columns = nextProps.columns
    }
    this.props.isReloading(true)
  }

  public componentWillUnmount() {
    this.clearAllRefs()
  }

  public setColumnWidths() {
    this.columnWidths = this.calculateColumnWidths()
  }

  public getSampleRows() {
    const rowCount = this.state.rows.length
    let sampleRows = []
    sampleRows.push(
      this.state.columns.map(
        v =>
          v.name +
          (v.validators.findIndex(
            columnVal => columnVal.validate === 'required'
          ) > -1
            ? 'XrequiredX'
            : '')
      )
    )
    const sampleNumber = rowCount > 1000 ? rowCount * 0.01 : rowCount
    if (rowCount > 1000) {
      for (let i = 0; i < sampleNumber; i++) {
        sampleRows.push(this.state.rows[Math.floor(Math.random() * rowCount)])
      }
    } else {
      sampleRows = [...sampleRows, ...this.state.rows]
    }
    return sampleRows
  }

  public calculateColumnWidths() {
    const basicWidths = this.calculateBasicColumnWidths(this.getSampleRows())
    const totalWidth = basicWidths.reduce((a, v) => a + v)
    const tableWidth = getWindowWidth() - 160
    if (totalWidth < tableWidth) {
      return this.expandWidths(basicWidths, totalWidth, tableWidth)
    } else {
      return basicWidths
    }
  }

  public expandWidths(basicWidths, totalWidth, tableWidth) {
    const columnWidths = basicWidths.map((v, i) => {
      if (i === 0) {
        return v
      } else {
        return v + (tableWidth - totalWidth) / basicWidths.length
      }
    })
    return columnWidths
  }

  public calculateBasicColumnWidths(sampleRows) {
    return sampleRows
      .reduce((acc, row, i) => {
        acc = Object.keys(row).map((key, j) => {
          const value = key === '$meta' ? row[key].index.toString() : row[key]
          if (i > 0 && acc[j] && value) {
            return acc[j].length > value.length ? acc[j] : value
          } else {
            return value
          }
        })
        return acc
      }, [])
      .map(c => {
        return Math.min(getRealStringLength(c), 300)
      })
  }

  public customRenderer(
    hotInstance,
    td,
    row,
    column,
    prop,
    value,
    cellProperties
  ) {
    Handsontable.renderers.BaseRenderer.apply(this, arguments)
    const meta = this.state.filteredRows[row].$meta
    const vState = this.props.validate.getRowValidation(meta.index)
    const type = this.props.defaultTypes[prop]
    if (prop === '$meta' && typeof meta.index === 'number') {
      td.innerHTML = meta.index + (this.props.header ? 2 : 1)
    } else if (prop === '$meta' && typeof meta.index !== 'number') {
      td.innerHTML = 'All of your rows pass validation!'
      td.classList.add('valid-data-message')
      td.classList.add('successColor')
    } else if (vState && vState[column].errors.length) {
      hotInstance.setCellMeta(row, column, 'valid', false)
      td.classList.add('htInvalid')
      if (type === 'select') {
        Handsontable.renderers.DropdownRenderer.apply(this, arguments)
      }
      td.innerHTML = `<span class="htInvalid-value">${value}</span>`
      const error = vState[column].errors.join(', ')
      const tipContainer = document.createElement('div')
      tipContainer.innerText = error
      tippy('.htInvalid-value', {
        content: tipContainer,
        placement: 'top',
        distance: -3
      })
    } else {
      hotInstance.setCellMeta(row, column, 'valid', true)
      td.classList.remove('htInvalid')
      if (type === 'checkbox') {
        if (/^(1|yes|true|on|enabled)$/i.test(value)) {
          value = true
          arguments[5] = value
          Handsontable.renderers.CheckboxRenderer.apply(this, arguments)
        } else if (/^(0|no|false|off|disabled)$/i.test(value)) {
          value = false
          arguments[5] = value
          Handsontable.renderers.CheckboxRenderer.apply(this, arguments)
        } else if (value && value.length > 0) {
          td.innerHTML = `<span class="htInvalid-value">${value}</span>`
          vState[column].errors.push(
            'must be boolean (true/false, yes/no, etc.)'
          )
          const error = vState[column].errors.join(', ')
          const tipContainer = document.createElement('div')
          tipContainer.innerText = error
          tippy('.htInvalid-value', {
            content: tipContainer,
            placement: 'top',
            distance: -3
          })
        } else {
          td.innerHTML = ''
        }
      } else if (type === 'select') {
        Handsontable.renderers.DropdownRenderer.apply(this, arguments)
      } else {
        td.innerHTML = value
      }
    }
    if (meta.deleted) {
      td.classList.add('excluded')
      td.classList.remove('htInvalid')
    } else {
      td.classList.remove('excluded')
    }
    return td
  }

  public excludeRow(i, deleted) {
    const index = this.state.filteredRows[i].$meta.index
    const { rows } = this.state
    rows[index].$meta.deleted = deleted
    this.t0 = window.performance.now()
    this.setState({ rows })
    this.props.validate.updateCell(index, 0, { index, deleted })
    this.tableRef.current.hotInstance.render()
  }

  public render() {
    const HoTInstance = this

    return (
      <div className='handsontable-container'>
        <HotTable
          root='hot'
          ref={HoTInstance.tableRef}
          settings={{
            licenseKey: HOT_LICENSE_KEY,
            data: HoTInstance.state.filteredRows,
            colHeaders: index => {
              if (index < HoTInstance.state.columns.length) {
                return '<div></div>'
              }
            },
            afterGetColHeader: (
              index: number,
              TH: HTMLTableHeaderCellElement
            ) => {
              if (index < HoTInstance.state.columns.length) {
                const actualCol = HoTInstance.state.columns[index]
                const sourceColumnMeta = HoTInstance.state.columnMeta.find(
                  meta => meta.newName === actualCol.key
                )
                const description =
                  HoTInstance.props.defaultShortDescriptions[
                    sourceColumnMeta.newName
                  ] || sourceColumnMeta.description
                const tooltip = HoTInstance.props.defaultShortDescriptions[
                  sourceColumnMeta.newName
                ]
                  ? sourceColumnMeta.description
                  : ''
                const isRequired =
                  actualCol.validators.findIndex(
                    v => v.validate === 'required'
                  ) > -1
                const colHeight = description
                  ? `${getRealElementHeight(
                      description,
                      HoTInstance.columnWidths[index]
                    )}px`
                  : '15px'
                const headerContent = description ? (
                  <Translation ns='translation'>
                    {t => (
                      <span className='col-desc-wrapper'>
                        <div
                          className={isRequired ? 'col-required' : ''}
                          data-i18n={t('required')}
                        >
                          {actualCol.name}
                        </div>
                        <div
                          className='col-desc secondaryTextColor'
                          title={description}
                        >
                          <span className={tooltip ? 'bottom-dotted' : ''}>
                            {description}
                          </span>
                        </div>
                      </span>
                    )}
                  </Translation>
                ) : (
                  <Translation ns='translation'>
                    {t => (
                      <span
                        className={isRequired ? 'col-required' : ''}
                        data-i18n={t('required')}
                      >
                        {actualCol.name}
                      </span>
                    )}
                  </Translation>
                )

                const headerNode = tooltip ? (
                  <div className='rendered'>
                    <HeaderTooltip tooltip={tooltip}>
                      {headerContent}
                    </HeaderTooltip>
                  </div>
                ) : (
                  <div className='rendered'>
                    <span className='htTooltip'>{headerContent}</span>
                  </div>
                )

                const newHeaderCell = document.createElement('div')

                while (TH.firstChild.firstChild.firstChild) {
                  TH.firstChild.firstChild.removeChild(
                    TH.firstChild.firstChild.firstChild
                  )
                }

                TH.firstChild.firstChild.appendChild(newHeaderCell)

                render(headerNode, newHeaderCell)
              }
            },
            columns: index => {
              if (index < HoTInstance.state.columns.length) {
                const actualCol = HoTInstance.state.columns[index]
                if (actualCol.key === '$meta') {
                  return {
                    data: '$meta',
                    readOnly: true,
                    copyable: false,
                    renderer: 'ff.custom',
                    disableVisualSelection: true
                  }
                } else if (actualCol.type === 'select') {
                  let options = actualCol.options
                  if (typeof options[0] === 'object') {
                    options = options.map(
                      (o: IFieldOptionDictionary) => o.label
                    )
                  }
                  return {
                    data: actualCol.key,
                    selectOptions: options,
                    editor: 'select',
                    readOnly: false,
                    renderer: 'ff.custom',
                    allowEmpty: !HoTInstance.state.columns[
                      index
                    ].validators.findIndex(v => v.validate === 'required')
                  }
                }
                return {
                  data: actualCol.key,
                  readOnly: false,
                  renderer: 'ff.custom',
                  allowEmpty: !HoTInstance.state.columns[
                    index
                  ].validators.findIndex(v => v.validate === 'required')
                }
              }
            },
            afterChange: async (changes, source) => {
              if (
                HoTInstance.tableRef.current &&
                changes &&
                source !== 'populateFromArray'
              ) {
                const hotInstance = HoTInstance.tableRef.current.hotInstance
                HoTInstance.props.onEdit()
                for (const change of changes) {
                  const i = change[0]
                  const trueRow = HoTInstance.state.filteredRows[i].$meta.index
                  const col = hotInstance.propToCol(change[1])
                  await HoTInstance.props.validate.updateCell(
                    trueRow,
                    col,
                    change[3]
                  )
                  // tslint:disable-next-line:no-console
                  console.log('updateCell complete')
                }
                HoTInstance.t0 = window.performance.now()
                removeElementsByClass('tooltip')
                // tslint:disable-next-line:no-console
                console.log('re-render')
                hotInstance.render()
              }
            },
            beforePaste: (data, coords) => {
              const tableLength = HoTInstance.tableRef.current.hotInstance.countRows()
              const overflowLength = tableLength - coords[0].endRow
              if (overflowLength > 0) {
                data.splice(overflowLength, data.length - overflowLength)
              }
            },
            afterRemoveRow: (index, amount) => {
              HoTInstance.props.onEdit()
            },
            afterScrollVertically: () => {
              const tooltips = document.getElementsByClassName('tooltip')
              for (let i = 0; i < tooltips.length; i++) {
                const element = tooltips.item(i)
                element.parentNode.removeChild(element)
              }
              removeElementsByClass('tooltip')
              HoTInstance.t0 = window.performance.now()
            },
            afterScrollHorizontally: () => {
              HoTInstance.t0 = window.performance.now()
            },
            afterRender: () => {
              HoTInstance.props.isReloading(false)
            },
            modifyColWidth: (width, col) => {
              return width < 40 ? 40 : width
            },
            contextMenu: {
              callback: (key, options) => {
                const hotInstance = HoTInstance.tableRef.current.hotInstance
                if (key === 'exclude_row') {
                  setTimeout(() => {
                    const x = hotInstance.getSelected()[0][0]
                    const x2 = hotInstance.getSelected()[0][2]
                    range(x, x2 + 1).forEach(i => {
                      HoTInstance.excludeRow(i, true)
                    })
                  }, 100)
                } else if (key === 'include_row') {
                  setTimeout(() => {
                    const x = hotInstance.getSelected()[0][0]
                    const x2 = hotInstance.getSelected()[0][2]
                    range(x, x2 + 1).forEach(i => {
                      HoTInstance.excludeRow(i, false)
                    })
                  }, 100)
                }
              },
              items: {
                exclude_row: {
                  name: 'Exclude this row',
                  disabled: () => {
                    return HoTInstance.state.rows[
                      HoTInstance.tableRef.current.hotInstance.getSelected()[0][0]
                    ].$meta.deleted
                  }
                },
                include_row: {
                  name: 'Include this row',
                  disabled: () => {
                    return !HoTInstance.state.rows[
                      HoTInstance.tableRef.current.hotInstance.getSelected()[0][0]
                    ].$meta.deleted
                  }
                }
              }
            },
            hiddenColumn: 0,
            mergeCells: HoTInstance.state.filteredRows.length
              ? true
              : [
                  {
                    row: 0,
                    col: 0,
                    rowspan: 1,
                    colspan: HoTInstance.state.columns.length
                  }
                ],
            minRows: 1,
            scrollV: 'auto',
            copyPaste: true,
            height: HoTInstance.state.tableHeight,
            viewportRowRenderingOffset: 3,
            colWidths: HoTInstance.columnWidths,
            autoColumnSize: false,
            manualColumnResize: true,
            manualRowResize: true,
            allowRemoveRow: true,
            rowHeights: '15px',
            wordWrap: false,
            undo: true,
            stretchH: 'all'
          }}
        />
      </div>
    )
  }
}

const HeaderTooltip = props => (
  <OverlayTrigger
    placement='top'
    overlay={<Tooltip id={`tooltip`}>{props.tooltip}</Tooltip>}
  >
    <span className='htTooltip'>{props.children}</span>
  </OverlayTrigger>
)
