import {
  IColumnMeta,
  ICsvData,
  IDefaultColumnMeta,
  IDictionary,
  IProcessedData
} from '@interfaces/general.interface'
import {
  IFieldInternal,
  ISettingsInternal,
  IValidatorInternal
} from '@interfaces/internal.settings.interface'
import {
  IBatchConfig,
  IFieldOptionDictionary
} from '@interfaces/settings.interface'
import 'font-awesome/css/font-awesome.css'
import { ParseResult } from 'papaparse'
import React, { Component, ReactNode, RefObject } from 'react'
import { DropzoneOptions } from 'react-dropzone'
import App from '../index'
import ColumnMatch from './match'
import HeaderMatch from './parse'
import Review from './review'
import Uploader from './upload'
import UploadWrapper, { UploadLog } from './upload/UploadWrapper'

interface IStagingManagerProps {
  settings: ISettingsInternal
  files: File[]
  accept: string
  uploadLog: UploadLog[]
  csvUnload: UploadWrapper['csvUnload']
  csvLoad: UploadWrapper['csvLoad']
  returnUUID: App['returnUUID']
  handshake: Promise<any> // tslint:disable-line:no-any
  parentHasValidator: boolean
  batchConfig: IBatchConfig
  uuid?: boolean
  uploadLogUpdate: UploadWrapper['uploadLogUpdate']
  openModalRewindVerify: UploadWrapper['openModalRewindVerify']
  openModal: UploadWrapper['openModal']
  asyncSetState: App['asyncSetState']
  updateMeta: App['updateMeta']
  onDrop: DropzoneOptions['onDrop']
  registerChunkCallback: App['registerChunkCallback']
  hasDevBanner?: boolean
}

interface IStagingManagerState {
  files: File[]
  csvData: ICsvData
  newData: Partial<IProcessedData['newData']>
  columnMeta: IColumnMeta
  header: boolean
  results?: never
  rawCSV: string
  previewData: ParseResult | {}
  activeStage: number
  usedCustomColumns: string[]
  defaultRows: Array<IDictionary<string | boolean>>
  defaultColumns: IDefaultColumnMeta[]
  defaultValidators: IDictionary<IValidatorInternal[]>
  defaultTypes: IDictionary<IFieldInternal['type']>
  defaultOptions: IDictionary<IFieldOptionDictionary[]>
  defaultDescriptions: IDictionary
  defaultShortDescriptions: IDictionary<string | undefined>
  keyNames: IDictionary
  shouldRememberHeader: boolean
  progressOverlay: string
}
export default class StagingManager extends Component<
  IStagingManagerProps,
  IStagingManagerState
> {
  public loaderRef: RefObject<Uploader>

  constructor(props: IStagingManagerProps) {
    super(props)

    this.loaderRef = React.createRef()
    const defaultColumns: IDefaultColumnMeta[] = []
    const defaultRows: Array<IDictionary<string | boolean>> = []
    const defaultValidators: IDictionary<IValidatorInternal[]> = {}
    const defaultDescriptions: IDictionary = {}
    const defaultShortDescriptions: IDictionary<string | undefined> = {}
    const defaultTypes: IDictionary<IFieldInternal['type']> = {}
    const defaultOptions: IDictionary<IFieldOptionDictionary[]> = {}
    const keyNames = {}
    for (const field of props.settings.fields) {
      const {
        key,
        label,
        validators,
        description,
        shortDescription,
        type,
        options
      } = field

      defaultColumns.push({
        ...field,
        name: label
      })

      defaultValidators[key] = validators
      defaultDescriptions[key] = description
      defaultShortDescriptions[key] = shortDescription
      defaultTypes[key] = type
      defaultOptions[key] = options as IFieldOptionDictionary[]
      keyNames[key] = label
    }

    for (let i = 0, row: IDictionary<string | boolean> = {}; i < 9; i++) {
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < props.settings.fields.length; j++) {
        if (props.settings.fields[j].type === 'checkbox') {
          row[props.settings.fields[j].key] = false
        } else {
          row[props.settings.fields[j].key] = ''
        }
      }
      row = {}
      defaultRows.push(row)
    }

    this.state = {
      csvData: { meta: null, data: null, errors: null },
      newData: { columns: null },
      files: [],
      columnMeta: [],
      header: false,
      rawCSV: '',
      previewData: {},
      activeStage: 1,
      usedCustomColumns: [],
      defaultRows,
      defaultColumns,
      defaultValidators,
      defaultTypes,
      defaultOptions,
      defaultDescriptions,
      defaultShortDescriptions,
      keyNames,
      shouldRememberHeader: true,
      progressOverlay: ''
    }

    if (!props.onDrop) {
      throw new Error('<StagingManager> onDrop prop must be set')
    }
  }

  public clearData = () => {
    this.props.asyncSetState({ uuid: false })
    this.setState({
      files: [],
      csvData: { meta: null, data: null, errors: null },
      newData: { columns: null },
      columnMeta: [],
      header: false,
      rawCSV: '',
      previewData: {},
      activeStage: 1,
      usedCustomColumns: [],
      shouldRememberHeader: true
    })
  }

  public rewindStage = (verify = false) => {
    if (verify) {
      this.toggleProgressOverlay(true)
      switch (this.state.activeStage) {
        case 1:
          this.loaderRef.current.initialTableRef.current.hotInstance.clear()
          this.loaderRef.current.initialTableRef.current.hotInstance.deselectCell()
          this.toggleProgressOverlay(false)
          break
        case 2:
          this.props.asyncSetState({ uuid: false })
          this.setState({
            csvData: { meta: null, data: null, errors: null },
            newData: { columns: null },
            columnMeta: [],
            previewData: {},
            rawCSV: '',
            files: [],
            header: false,
            shouldRememberHeader: true,
            activeStage: 1
          })
          this.props.csvUnload()
          break
        case 3:
          this.setState({
            shouldRememberHeader: false,
            activeStage: 2
          })
          break
        case 4:
          const isPastedData = !this.state.csvData.meta
          if (isPastedData) {
            this.props.asyncSetState({ uuid: false })
          }
          this.setState({
            newData: null,
            usedCustomColumns: [],
            activeStage: isPastedData ? 1 : 3
          })
          break
        default:
        // do nothing
      }
    } else {
      this.props.openModalRewindVerify()
    }
  }

  public nextStage = <K extends keyof IStagingManagerState>(
    newState: Pick<IStagingManagerState, K>
  ) => {
    this.toggleProgressOverlay(true)
    window.setTimeout(() => {
      this.setState(newState)
    }, 10)
  }

  public csvDataUpdate = csvData => {
    this.setState({ csvData })
  }

  public toggleProgressOverlay = toggle => {
    if (toggle) {
      this.setState({ progressOverlay: ' stage-loading' })
    } else {
      this.setState({ progressOverlay: '' })
    }
  }

  public render() {
    const { registerChunkCallback, hasDevBanner } = this.props
    const mainDisplay = this.getMainDisplay(registerChunkCallback)

    return (
      <div
        className={`active-stage borderColor ${
          hasDevBanner ? 'has-dev-banner' : ''
        }`}
      >
        <div className={'stage-progress-overlay' + this.state.progressOverlay}>
          <span className='loading-indicator'>
            <i className='fa fa-spinner fa-pulse fa-3x fa-fw' />
            <h1 className='sr-only'>{'Loading...'}</h1>
          </span>
        </div>
        {mainDisplay}
      </div>
    )
  }

  public getMainDisplay(registerChunkCallback: App['registerChunkCallback']) {
    let mainDisplay = {}

    switch (this.state.activeStage) {
      case 1: // FIRST STAGE: UPLOADER
        mainDisplay = this.getUploader()
        break
      case 2: // SECOND STAGE: HEADER MATCHING
        mainDisplay = this.getHeaderMatch()
        break
      case 3: // THIRD STAGE: COLUMN MATCHING
        mainDisplay = this.getColumnMatch()
        break
      case 4: // FINAL STAGE: VALIDATION
        mainDisplay = this.getReview(registerChunkCallback)
        break
    }
    return mainDisplay
  }

  public getReview(
    registerChunkCallback: App['registerChunkCallback']
  ): ReactNode {
    return (
      <Review
        header={this.state.header}
        registerChunkCallback={registerChunkCallback}
        returnUUID={this.props.returnUUID}
        settings={this.props.settings}
        batchConfig={this.props.batchConfig}
        toggleProgressOverlay={this.toggleProgressOverlay}
        updateMeta={this.props.updateMeta}
        newData={this.state.newData as IProcessedData['newData']}
        columns={this.state.newData.columns}
        columnMeta={this.state.columnMeta}
        activeStage={this.state.activeStage}
        parentHasValidator={this.props.parentHasValidator}
        rewindStage={this.rewindStage}
        openModal={this.props.openModal}
        nextStage={this.nextStage}
        usedCustomColumns={this.state.usedCustomColumns}
        defaultTypes={this.state.defaultTypes}
        defaultOptions={this.state.defaultOptions}
        defaultShortDescriptions={this.state.defaultShortDescriptions}
        dataType={this.props.settings.type}
        handshake={this.props.handshake}
      />
    )
  }

  public getColumnMatch() {
    return (
      <ColumnMatch
        header={this.state.header}
        toggleProgressOverlay={this.toggleProgressOverlay}
        updateMeta={this.props.updateMeta}
        columnMeta={this.state.columnMeta}
        csvData={this.state.csvData}
        activeStage={this.state.activeStage}
        nextStage={this.nextStage}
        openModal={this.props.openModal}
        parentHasValidator={this.props.parentHasValidator}
        handshake={this.props.handshake}
        rewindStage={() => this.rewindStage(true)}
        settings={this.props.settings}
        returnUUID={this.props.returnUUID}
        allowCustom={this.props.settings.allowCustom}
        defaultValidators={this.state.defaultValidators}
        defaultDescriptions={this.state.defaultDescriptions}
        defaultColumns={this.state.defaultColumns}
        defaultTypes={this.state.defaultTypes}
        defaultOptions={this.state.defaultOptions}
        csvDataUpdate={this.csvDataUpdate}
        dataType={this.props.settings.type}
        keyNames={this.state.keyNames}
      />
    )
  }

  public getHeaderMatch() {
    return (
      <HeaderMatch
        toggleProgressOverlay={this.toggleProgressOverlay}
        updateMeta={this.props.updateMeta}
        nextStage={this.nextStage}
        rewindStage={() => this.rewindStage(true)}
        rawCSV={this.state.rawCSV}
        previewData={this.state.previewData as ParseResult}
        defaultColumns={this.state.defaultColumns}
        settings={this.props.settings}
        returnUUID={this.props.returnUUID}
        defaultValidators={this.state.defaultValidators}
        defaultDescriptions={this.state.defaultDescriptions}
        defaultTypes={this.state.defaultTypes}
        defaultOptions={this.state.defaultOptions}
        dataType={this.props.settings.type}
        shouldRememberHeader={this.state.shouldRememberHeader}
      />
    )
  }

  public getUploader() {
    let files
    if (this.state.files.length >= this.props.files.length) {
      files = this.state.files
    } else {
      files = this.props.files
    }

    return (
      <Uploader
        ref={this.loaderRef}
        csvLoad={this.props.csvLoad}
        updateMeta={this.props.updateMeta}
        asyncSetState={this.props.asyncSetState}
        uploadLogUpdate={this.props.uploadLogUpdate}
        returnUUID={this.props.returnUUID}
        nextStage={this.nextStage}
        openModal={this.props.openModal}
        onDrop={this.props.onDrop}
        rewindStage={() => this.rewindStage(true)}
        accept={this.props.accept}
        files={files}
        settings={this.props.settings}
        batchConfig={this.props.batchConfig}
        defaultRows={this.state.defaultRows}
        defaultColumns={this.state.defaultColumns}
        defaultDescriptions={this.state.defaultDescriptions}
        defaultShortDescriptions={this.state.defaultShortDescriptions}
        defaultTypes={this.state.defaultTypes}
        defaultOptions={this.state.defaultOptions}
        defaultValidators={this.state.defaultValidators}
        clientID={this.props.settings.clientID}
        dataType={this.props.settings.type}
        toggleProgressOverlay={this.toggleProgressOverlay}
      />
    )
  }
}
