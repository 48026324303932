import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  ReactElement
} from 'react'
import { Translation } from 'react-i18next'

interface IProgressHeaderProps {
  stage: number
}
export const ProgressHeader: React.FC<IProgressHeaderProps> = ({
  stage
}: IProgressHeaderProps): ReactElement | null => {
  if (stage === 3) {
    return (
      <Translation ns='translation'>
        {t => (
          <ul className='main-progress'>
            <li className='progress-done successColor'>
              <i className='fa fa-check-circle' />
              {t('breadcrumbs.upload')}
              <span className='big-screen'>{t('breadcrumbs.spreadsheet')}</span>
            </li>
            <li className='progress-current'>
              {t('breadcrumbs.match')}
              <span className='big-screen'>{t('breadcrumbs.columns')}</span>
            </li>
            <li className='progress-incomplete'>
              {t('breadcrumbs.repair')}
              <span className='big-screen'>{t('breadcrumbs.problems')}</span>
            </li>
            <li className='progress-incomplete'>{t('breadcrumbs.complete')}</li>
          </ul>
        )}
      </Translation>
    )
  } else if (stage === 4) {
    return (
      <Translation ns='translation'>
        {t => (
          <ul className='main-progress'>
            <li className='progress-done successColor'>
              <i className='fa fa-check-circle' />
              {t('breadcrumbs.upload')}
              <span className='big-screen'>{t('breadcrumbs.spreadsheet')}</span>
            </li>
            <li className='progress-done successColor'>
              <i className='fa fa-check-circle' />
              {t('breadcrumbs.match')}
              <span className='big-screen'>{t('breadcrumbs.columns')}</span>
            </li>
            <li className='progress-current'>
              {t('breadcrumbs.repair')}
              <span className='big-screen'>{t('breadcrumbs.problems')}</span>
            </li>
            <li className='progress-incomplete'>{t('breadcrumbs.complete')}</li>
          </ul>
        )}
      </Translation>
    )
  }
  return null
}

type IHTMLButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

type IGenericButtonProps = IHTMLButtonProps & {
  classes: string[]
}
export const GenericButton: React.FC<IGenericButtonProps> = ({
  classes,
  title,
  ...props
}: IGenericButtonProps) => (
  <span>
    <button className={'button ' + classes.join(' ')} {...props}>
      {title}
    </button>
  </span>
)

interface ILogoProps {
  fill?: string
}
export const Logo: React.FC<ILogoProps> = ({ fill }: ILogoProps) => (
  <svg viewBox='0 0 480 486' xmlns='http://www.w3.org/2000/svg'>
    <g fill={fill || '#000'} fillRule='nonzero'>
      <path d='M240 312.74L48 227.4v-88.8l192-85.34L396.9 123 240 192.74l-98.25-43.67a24 24 0 1 0-19.5 43.86L240 245.26l223.7-99.41a25 25 0 0 0 0-45.7L240 .74l-224.56 99.8A26 26 0 0 0 0 124.3v118.05a25 25 0 0 0 14.85 22.85L240 365.26l225.75-100.33a24 24 0 1 0-19.5-43.86L240 312.74z' />
      <path d='M240 432.74L33.75 341.07a24 24 0 1 0-19.5 43.86L240 485.26l225.75-100.33a24 24 0 1 0-19.5-43.86L240 432.74z' />
    </g>
  </svg>
)
export const WordMark: React.FC<ILogoProps> = ({ fill }: ILogoProps) => (
  <svg viewBox='0 0 1469 380' xmlns='http://www.w3.org/2000/svg'>
    <g fill={fill || '#000'} fillRule='evenodd'>
      <path
        d='M188.5 248.14L353.1 175a16.66 16.66 0 0 1 13.53 30.45L188.5 284.6 10.84 205.65A17.45 17.45 0 0 1 .48 189.7V96.04c0-7.2 4.24-13.74 10.83-16.67L188.5.62 365 79.06a17.45 17.45 0 0 1 0 31.9L188.5 189.4l-92.45-41.08a16.66 16.66 0 0 1 13.53-30.45l78.92 35.07 130.34-57.93L188.5 37.08 33.8 105.84v73.55l154.7 68.75zM23.9 270.2l164.6 73.16 164.6-73.16a16.66 16.66 0 0 1 13.53 30.45L188.5 379.81 10.37 300.64a16.66 16.66 0 1 1 13.53-30.45z'
        fillRule='nonzero'
      />
      <path d='M540.8 138.27v3.47h40.9v36.05h-40.9v137.3h-37.45v-137.3H477v-36.05h26.35v-3.47c0-46.8 26.34-73.85 78.35-70.38v36.06c-26.35-2.43-40.9 7.63-40.9 34.32M611.35 315.09h37.44V62h-37.44zM819.77 228.41c0-32.24-23.57-55.47-54.77-55.47-31.2 0-54.78 23.23-54.78 55.47 0 32.24 23.58 55.47 54.78 55.47 31.2 0 54.77-23.23 54.77-55.47zm37.45-86.67v173.35h-37.45v-24.97c-13.17 18.38-33.62 29.47-60.67 29.47-47.15 0-86.32-39.87-86.32-91.18 0-51.66 39.17-91.18 86.32-91.18 27.05 0 47.5 11.1 60.67 29.12v-24.61h37.45zM954.62 177.8V261c0 22.2 14.56 21.84 42.99 20.46v33.63c-57.55 6.93-80.43-9.01-80.43-54.1v-83.2h-31.9v-36.05h31.9V104.3l37.44-11.09v48.54h42.99v36.05h-43zM1086.93 138.27v3.47h40.9v36.05h-40.9v137.3h-37.45v-137.3h-26.34v-36.05h26.34v-3.47c0-46.8 26.35-73.85 78.36-70.38v36.06c-26.35-2.43-40.91 7.63-40.91 34.32M1159.02 314.94h37.44V141.59h-37.44v173.35zm-5.2-223.28a23.97 23.97 0 0 1 23.92-23.92 23.97 23.97 0 0 1 23.92 23.92c0 12.83-10.75 23.92-23.92 23.92-13.18 0-23.92-11.09-23.92-23.92zM1228.48 315.09h37.45V62h-37.45zM1430.67 213.85c-5.2-29.81-27.04-42.64-49.58-42.64-28.08 0-47.15 16.64-52.35 42.64h101.93zm-46.11 71.42c20.8 0 36.4-9.36 44.73-21.5l30.85 18.03c-15.94 23.58-41.95 37.8-75.93 37.8-57.2 0-94.3-39.19-94.3-91.2 0-51.3 37.1-91.17 91.53-91.17 51.66 0 87.02 41.6 87.02 91.53 0 5.2-.7 10.75-1.38 15.6h-138c5.9 27.04 27.74 40.91 55.48 40.91z' />
    </g>
  </svg>
)

export const FooterBrand: React.FC = () =>
  window.FF_FEATURES.whiteLabel ? (
    <span className='footer-brand' />
  ) : (
    <Translation ns='translation'>
      {t => (
        <a
          className='footer-brand'
          href={
            'https://flatfile.io/?utm_source=user_app&utm_medium=footer&utm_name=' +
            window.FF_LICENSE_KEY
          }
          target='_blank'
          title={t('poweredTitle')}
        >
          <span>
            {t('poweredBy')}
            <WordMark />
          </span>
        </a>
      )}
    </Translation>
  )

export const CloseButton: React.FC<IHTMLButtonProps> = (
  props: IHTMLButtonProps
) => (
  <button className='flatfile-close-button' {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
      <path
        fill='#000'
        fillRule='nonzero'
        d='M94.3 0c-1.5 0-3 .6-4 1.7L50 42 9.8 1.6c-2.3-2.3-6-2.3-8 0-2.4 2.2-2.4 5.8 0 8l40 40.3L2 90.2c-2.4 2.3-2.4 6 0 8 1 1.2 2.5 1.8 4 1.8 1.4 0 3-.6 4-1.7L50 58l40.2 40.3c1 1 2.6 1.7 4 1.7 1.5 0 3-.6 4-1.7 2.4-2.2 2.4-5.8 0-8L58.3 50 98 9.8c2.4-2.3 2.4-6 0-8-1-1.2-2.5-1.8-4-1.8z'
      />
    </svg>
  </button>
)
