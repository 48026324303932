import { HOT_LICENSE_KEY } from '@config'
import {
  IColumnMeta,
  ICsvData,
  IProcessedColumnMeta
} from '@interfaces/general.interface'
import { ICellProperties, IHoTInstance } from '@interfaces/hot.interface'
import { ISettingsInternal } from '@interfaces/internal.settings.interface'
import { ISettings } from '@interfaces/settings.interface'
import { FooterBrand, GenericButton } from '@lib/elements'
import { getWindowHeight } from '@lib/functions'
import 'font-awesome/css/font-awesome.css'
import Handsontable from 'handsontable-pro'
import 'handsontable/dist/handsontable.full.css'
import { ParseError } from 'papaparse'
import pluralize from 'pluralize'
import React, { Component } from 'react'
import HotTable from 'react-handsontable'
import { Translation } from 'react-i18next'
import UploadWrapper from '../upload/UploadWrapper'
import ErrorRowsParser, { IParsingErrorAnalysis } from './errorRowsParser'

type ParseErrorsProps = {
  rewindStage: () => void
  csvDataUpdate: (csvData: ICsvData) => void
  columnMeta: IColumnMeta
  header: boolean
  csvData: ICsvData
  dataType: ISettingsInternal['type']
  settings: ISettings
  openModal?: UploadWrapper['openModal']
}

type ParseErrorsState = {
  rows: IParsingErrorAnalysis[]
  csvData: ICsvData
  tableHeight: number
  errors: ParseError[]
  dataType: ISettingsInternal['type']
  columns: IProcessedColumnMeta[]
}

interface IChangeActionOptions {
  start: {
    row: number
  }
}

export default class ParseErrors extends Component<
  ParseErrorsProps,
  ParseErrorsState
> {
  public errorTableRef: React.MutableRefObject<HTMLElement>
  public rewindStage: ParseErrorsProps['rewindStage']
  public csvDataUpdate: ParseErrorsProps['csvDataUpdate']

  constructor(props: ParseErrorsProps) {
    super(props)
    this.errorTableRef = React.createRef()
    this.rewindStage = props.rewindStage.bind(this)
    this.csvDataUpdate = props.csvDataUpdate.bind(this)
    this.submitChanges = this.submitChanges.bind(this)
    this.customCellRenderer = this.customCellRenderer.bind(this)

    const columns = []
    columns.push({ key: 'action', name: '' })
    columns.push({ key: 'csvRow', name: 'Row' })
    columns.push({ key: 'error', name: 'Error Message' })

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < props.columnMeta.length; i++) {
      const key = props.header
        ? props.columnMeta[i].oldName
        : props.columnMeta[i].suggestedName
      columns.push({
        key,
        name: key
      })
    }
    if (
      Math.max.apply(
        Math,
        props.csvData.data.map(a => {
          return Object.keys(a).length
        })
      ) +
        3 >
      columns.length
    ) {
      columns.push({
        key: '__parsed_extra',
        name: 'Extra Column Data'
      })
    }
    const errors = new ErrorRowsParser(
      props.csvData.errors,
      columns,
      props.csvData
    )
    const rows = errors.getRows()
    if (!rows.length) {
      // if the only errors were non-row-specific, rows will be empty, so return to the match stage
      props.csvData.errors = []
      props.csvDataUpdate(props.csvData)
    }

    this.state = {
      csvData: props.csvData,
      errors: props.csvData.errors,
      dataType: props.dataType,
      rows,
      columns,
      tableHeight: null
    }
  }

  public componentWillMount() {
    const windowHeight = getWindowHeight()
    const tableHeight = windowHeight - 565 > 200 ? windowHeight - 565 : 200
    this.setState({ tableHeight })
  }

  public clearAllRefs() {
    this.errorTableRef.current = null
  }

  public componentWillUnmount() {
    this.clearAllRefs()
  }

  public changeAction(row: number, action: IParsingErrorAction) {
    const rows = this.state.rows.slice()
    rows[row].action = action
    this.setState({ rows })
  }

  /**
   * @todo: refactor this
   */
  public submitChanges() {
    const csvData = { ...this.state.csvData }
    for (let i = this.state.rows.length - 1; i >= 0; i--) {
      if (this.state.rows[i].action === 'IGNORE') {
        csvData.data.splice(this.state.errors[i].row, 1)
      } else if (this.state.rows[i].action === 'INCLUDE') {
        csvData.data[this.state.errors[i].row] = this.state.rows[i]
        delete csvData.data[this.state.errors[i].row].action
        delete csvData.data[this.state.errors[i].row].error
        delete csvData.data[this.state.errors[i].row].csvRow
        delete csvData.data[this.state.errors[i].row].__parsed_extra
      }
    }
    csvData.errors = []
    this.csvDataUpdate(csvData)
  }

  /**
   * Handsontable custom cell renderer
   * @todo this shouldn't be a class methpod
   */
  public customCellRenderer(
    instance: IHoTInstance,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value = '',
    cellProperties: ICellProperties
  ) {
    if (col === 0) {
      const action = value === 'INCLUDE' ? 'IGNORE' : 'INCLUDE'
      const title =
        action === 'INCLUDE' ? 'Include this row?' : 'Ignore this row?'
      Handsontable.dom.empty(td)
      td.innerHTML = ''

      const icon: HTMLElement = document.createElement('i')
      icon.addEventListener('click', () => this.changeAction(row, action))
      icon.title = title
      icon.style.cssText =
        'min-height: 4px; min-width: 4px; display: inline-block;'
      icon.className =
        value === 'INCLUDE' ? 'fa fa-trash' : 'fa fa-check-square'
      td.appendChild(icon)
    } else {
      td.innerHTML = value
    }
    if (this.state.rows[row].action === 'IGNORE') {
      td.classList.add('row-ignored')
    }
    return td
  }

  public render() {
    return (
      <Translation ns='translation'>
        {t => (
          <div className='parse-error-stage'>
            <div className='scroll-block'>
              <div className='controlbar top'>
                <h1 className='primary-header'>
                  {this.props.settings.title ||
                    t('header2', {
                      thing: pluralize.plural(this.state.dataType)
                    })}
                </h1>
              </div>
              <div className='notice secondaryTextColor'>
                <h2 className='secondary-header'>{t('errors.parsing')}</h2>
                <p className='paragraph secondaryTextColor'>
                  {t('errors.anomolies')}
                </p>
              </div>
              <div className='parse-error-tables'>
                <HotTable
                  root='hot'
                  ref={this.errorTableRef}
                  settings={{
                    licenseKey: HOT_LICENSE_KEY,
                    colHeaders: (index: number) => {
                      if (index < this.state.columns.length) {
                        return this.state.columns[index].name
                      }
                    },
                    columns: (index: number) => {
                      if (index < this.state.columns.length) {
                        return {
                          data: this.state.columns[index].key,
                          renderer: this.customCellRenderer,
                          readOnly: index < 3,
                          disableVisualSelection: index < 3
                        }
                      }
                      return
                    },
                    modifyColWidth: (width: number, col: number) => {
                      return width < 40 ? 40 : width
                    },
                    data: this.state.rows,
                    stretchH: 'all',
                    undo: true,
                    height: this.state.tableHeight,
                    manualColumnResize: true,
                    rowHeights: '15px',
                    wordWrap: false,
                    manualRowResize: true,
                    contextMenu: {
                      callback: (
                        key: string,
                        options: IChangeActionOptions
                      ) => {
                        if (key === 'ignore') {
                          this.changeAction(options.start.row, 'IGNORE')
                        } else if (key === 'include') {
                          this.changeAction(options.start.row, 'INCLUDE')
                        }
                      },
                      items: {
                        ignore: { name: 'Ignore this row' },
                        include: { name: 'Include with edits' }
                      }
                    }
                  }}
                />
              </div>
            </div>
            <div className='controlbar bottom'>
              <GenericButton
                id='cancel'
                title={t('buttons.back')}
                classes={['invert']}
                onClick={this.rewindStage}
              />
              <FooterBrand />
              <GenericButton
                id='continue'
                title={t('buttons.continue')}
                classes={['primary']}
                onClick={this.submitChanges}
              />
            </div>
          </div>
        )}
      </Translation>
    )
  }
}

export type IParsingErrorAction = 'IGNORE' | 'INCLUDE'
